<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :cny-25-navigation="true"
      :cny25-p2-navigation="wordpressData?.unlock_sidebar"
    />
    <secondary-navigation :menu-items-data="menuItems" />
    <!-- Header -->
    <cny-25-header-banner
      :title="'cny_visiting_101'"
      :description="'cny_conversation_starters'"
    />
    <div
      id="cny-conversations-about"
      class="tw-max-w-[600px] tw-m-auto tw-pt-10 tw-px-4 tw-pb-5 sgTablet:tw-pb-10 sgTablet:tw-px-0"
    >
      <div
        class="tw-text-sg-cny25-body tw-flex tw-flex-col tw-gap-5"
        v-html="props.wordpressData.description"
      />
    </div>
    <!-- Page Break -->
    <CNY25FloatingDivider :type="2" />
    <cny-25-conversation-window :conversation-starters="props.wordpressData.conversation_starters" />
    <ap-footer />
  </div>
</template>

<script setup lang="ts">
import { checkLang } from '../../support/helper';
import CNY25FloatingDivider from '../../components/cny25/CNY25FloatingDivider.vue';

const props = withDefaults(
  defineProps<{
    wordpressData?: any | null;
  }>(),
  {
    wordpressData: null,
  }
);

const menuItems = [
  {
    label: 'cny_conversations_about',
    id: 'cny-conversations-about',
  },
  {
    label: 'cny_conversations',
    id: 'cny-conversations',
  },
];
</script>
