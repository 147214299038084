<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :cny-25-navigation="true"
      :cny25-p2-navigation="wordpressData?.unlock_sidebar"
    />
    <secondary-navigation :menu-items-data="menuItems" />
    <!-- Header -->
    <cny-25-header-banner
      :title="'cny25_visiting_101'"
      :description="'cny25_good_luck_guides'"
    />
    <div
      class="tw-max-w-[600px] tw-m-auto tw-py-5 sgTablet:tw-py-10 tw-px-4 sgTablet:tw-px-0 tw-text-sg-tablet-3 tw-w-full tw-break-words tw-flex tw-flex-col tw-gap-5"
      v-html="wordpressData.description"
    />

    <CNY25FloatingDivider>
      <cny-25-good-luck-guide-swiper
        swiper-type="do-these"
        custom-id="do-these"
      />
    </CNY25FloatingDivider>
    <cny-25-good-luck-guide-swiper
      swiper-type="taboos"
      custom-id="taboos"
    />
    <cny-25-good-luck-guide-swiper
      swiper-type="do-and-dont"
      custom-id="do-and-dont"
    />
    <!-- Image Source -->
    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
      :title-style="`${
        checkLang() == 'zh-hans'?
          'tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop tw-font-notosansbold':
          'tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop tw-font-akkuratbold'
      }`"
    />
    <ap-footer />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { checkLang } from '../../support/helper';
import CNY25FloatingDivider from '../../components/cny25/CNY25FloatingDivider.vue';

const props = defineProps<{
  wordpressData: any;
}>();

const imageSourceOpen = ref<boolean>(false);
const imageSourcesData = ref(props.wordpressData.sources);

const menuItems = [
  {
    label: 'cny_good_luck_menu_do_these',
    id: 'do-these',
  },
  {
    label: 'cny_good_luck_menu_taboos',
    id: 'taboos',
  },
  {
    label: 'cny_good_luck_menu_do_donts',
    id: 'do-and-dont',
  },
];
</script>
