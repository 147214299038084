<template>
  <section
    :class="[
      'tw-relative tw-grid',
      'tw-grid-cols-1 sgTablet2:tw-grid-cols-[1fr_auto]',
      'tw-min-h-full sgTablet2:tw-min-h-[800px]',
      { 'tw-grid-cols-1 sgTablet2:tw-grid-cols-[auto_1fr]' : data.rtl },
      'tw-overflow-hidden',
    ]"
  >

    <picture
      v-if="!data.rtl || isMobile"
      :class="[
        'tw-w-full sgTablet2:tw-w-[50vw]',
        'tw-relative tw-h-[372px] sgTablet2:tw-h-[800px] tw-object-cover sgTablet2:tw-col-span-1'
      ]"
    >
      <img
        :src="$cdn(isMobile ? data.imgMobile : data.img)"
        alt="Family Fun"
        class="tw-w-full tw-h-full tw-object-cover"
      >
    </picture>

    <!-- Text Section -->
    <div
      :class="[
        'tw-w-full sgTablet2:tw-w-[50vw]',
        'tw-flex tw-flex-col tw-justify-center tw-items-center sgTablet2:tw-items-start',
        'tw-py-10',
        'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
      ]"
      class="tw-col-span-1"
    >
      <div
        :class="[
          'tw-text-[#C52600]',
          'tw-text-sg-ws-h3-mobile sgTablet:tw-text-sg-ws-h3-tablet sgDesktop:tw-text-sg-ws-h3-desktop',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
      >
        {{ $t(data.title) }}
      </div>
      <div
        :class="[
          'tw-pt-5',
          'tw-text-sg-ws-body tw-text-[#651300]',
          'tw-text-center sgTablet2:tw-text-left',
        ]"
        v-html="$t(data.description)"
      />
      <div
        :class="[
          'tw-flex tw-justify-center sgTablet2:tw-justify-start',
          'tw-pt-8 sgDesktop:tw-pt-9',
        ]"
      >
        <a
          :href="$langRedirect(data.buttonLink)"
          target="_blank"
          rel="noopener"
          :class="[
            'tw-text-white tw-bg-[#C52600]',
            'tw-px-6 tw-py-4',
            'tw-text-sg-ma-bt1',
            'tw-rounded-[26px] !tw-leading-[20px] tw-h-[52px] tw-uppercase',
            'hover:tw-bg-[#D15133] active:tw-bg-[#9E1E00]',
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
        >
          {{ $t(data.buttonTitle) }}
        </a>
      </div>
    </div>

    <picture
      v-if="data.rtl && !isMobile"
      :class="[
        'tw-w-[50vw]',
        'tw-relative tw-h-[372px] sgTablet2:tw-h-[800px] tw-object-cover tw-col-span-1'
      ]"
    >
      <img
        :src="$cdn(data.img)"
        alt="Family Fun"
        class="tw-w-full tw-h-full tw-object-cover"
      >
    </picture>
  </section>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { checkLang } from '../../../support/helper';

interface ImageBlock {
  id: string
  isMobile: boolean
  img: string
  imgMobile: string
  title: string
  description: string
  buttonTitle: string
  buttonLink: string
  rtl: boolean
}

defineProps<{
  data: ImageBlock
}>();


const isMobile = ref(window.innerWidth < 800);

function handleOnResize() {
  isMobile.value = window.innerWidth < 800;
}

onMounted(() => {
  handleOnResize();
  window.addEventListener('resize', handleOnResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleOnResize);
});

</script>

<style lang="scss" scoped>

.text-section {

}

</style>
