<template>
  <div class="competition-details tw-flex tw-flex-col tw-px-8 tw-gap-5">
    <!-- Competition Period -->
    <section>
      <div :class="dynamicFontClass">
        {{ $t('cny_family_fun_competition_period') }}
      </div>
      <p>{{ $t('cny_family_fun_competition_date') }}</p>
    </section>

    <!-- Closing Date for Submission -->
    <section class="tw-hidden">
      <div :class="dynamicFontClass">
        {{ $t('cny_family_fun_closing_date_for_submission') }}
      </div>
      <p>{{ $t('cny_family_fun_closing_date') }}</p>
    </section>

    <!-- How to Participate -->
    <section>
      <div :class="dynamicFontClass">
        {{ $t('cny_family_fun_how_to_participate') }}
      </div>
      <p>{{ $t('cny_family_fun_how_to_participate_1') }}</p>
      <ul class="tw-list-disc tw-list-ouside tw-pl-5">
        <li>{{ $t('cny_family_fun_how_to_participate_1_1') }}</li>
        <li>{{ $t('cny_family_fun_how_to_participate_1_2') }}</li>
        <li>{{ $t('cny_family_fun_how_to_participate_1_3') }}</li>
      </ul>
      <ol class="tw-list-[lower-alpha] tw-list-outside tw-pl-5">
        <li>{{ $t('cny_family_fun_how_to_participate_1_4') }}</li>
        <li>{{ $t('cny_family_fun_how_to_participate_1_5') }}</li>
      </ol>

      <p>{{ $t('cny_family_fun_how_to_participate_2') }}</p>
      <ul class="tw-list-disc tw-list-outside tw-pl-5">
        <li><div v-html="$t('cny_family_fun_how_to_participate_2_1')" /></li>
      </ul>
    </section>


    <!-- Judging Criteria -->
    <section>
      <div :class="dynamicFontClass">
        {{ $t('cny_family_fun_judging_criteria') }}
      </div>
      <ul class="tw-list-decimal tw-list-outside">
        <li>{{ $t('cny_family_fun_judging_criteria_1') }}</li>
        <li>{{ $t('cny_family_fun_judging_criteria_2') }}</li>
        <li>{{ $t('cny_family_fun_judging_criteria_3') }}</li>
      </ul>
    </section>


    <!-- Prizes -->
    <section>
      <div :class="dynamicFontClass">
        {{ $t('cny_family_fun_prizes') }}
      </div>
      <p>{{ $t('cny_family_fun_prizes_1') }}</p>
    </section>

    <!-- Competition Rules -->
    <section>
      <div :class="dynamicFontClass">
        {{ $t('cny_family_fun_competition_rules') }}
      </div>
      <ol class="tw-list-decimal tw-list-outside">
        <li>{{ $t('cny_family_fun_competition_rules_1') }}</li>
        <li>{{ $t('cny_family_fun_competition_rules_2') }}</li>
      </ol>
    </section>

    <!-- Terms and Conditions -->
    <section>
      <div :class="dynamicFontClass">
        {{ $t('cny_family_fun_term_conditions') }}
      </div>
      <ol class="tw-list-decimal tw-list-outside">
        <li>{{ $t('cny_family_fun_term_conditions_1') }}</li>
        <li>{{ $t('cny_family_fun_term_conditions_2') }}</li>
        <li>{{ $t('cny_family_fun_term_conditions_3') }}</li>
        <li>{{ $t('cny_family_fun_term_conditions_4') }}</li>
        <li>{{ $t('cny_family_fun_term_conditions_5') }}</li>
        <li>{{ $t('cny_family_fun_term_conditions_6') }}</li>
        <li>{{ $t('cny_family_fun_term_conditions_7') }}</li>
        <li>{{ $t('cny_family_fun_term_conditions_8') }}</li>
        <li>{{ $t('cny_family_fun_term_conditions_9') }}</li>
        <li>{{ $t('cny_family_fun_term_conditions_10') }}</li>
        <li>{{ $t('cny_family_fun_term_conditions_11') }}</li>
      </ol>
    </section>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { checkLang } from '../../../support/helper';

const dynamicFontClass = computed(() => {
  return checkLang() === 'en' ?
    'tw-text-sg-cny25-div tw-font-akkuratbold' :
    'tw-text-sg-cny25-div tw-font-notosansbold';
});
</script>

<style scoped>

.competition-details p,
.competition-details li {
  @apply tw-text-sg-cny25-body;
}

.competition-details li {
  @apply tw-ml-5;
}

</style>
