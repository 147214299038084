<template>
  <div
    class="tw-w-full tw-flex tw-overflow-hidden tw-px-0 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px] tw-gap-5"
  >
    <div
      v-if="!isMobile"
      :class="[
        'tw-flex tw-items-center tw-justify-center',
      ]"
    >
      <img
        class="tw-min-w-[56px] tw-h-[56px] tw-object-cover"
        :class="{
          'tw-cursor-pointer': !leftBtnDisable,
          'tw-cursor-not-allowed': leftBtnDisable
        }"
        :src="$cdn(leftBtnDisable ? 'icons/cny25/previous_round_button_disable.svg' : 'icons/cny25/previous_round_button_'+leftBtnState+'.svg')"
        @mouseenter="leftBtnState = 'hover'"
        @mouseleave="leftBtnState = 'idle'"
        @mousedown="leftBtnState = 'active'"
        @mouseup="leftBtnState = 'hover'"
        @touchstart="leftBtnState = 'active'"
        @touchend="leftBtnState = 'idle'"
        @click="onClickPrev"
      >
    </div>
    <swiper
      id="ugc-section-swiper"
      :slides-per-view="'auto'"
      :lazy-preload-prev-next="3"
      :load-prev-next="true"

      :breakpoints="{
        0: {
          spaceBetween: 12,
          slidesOffsetBefore: 16,
          slidesOffsetAfter: 16
        },
        600: {
          spaceBetween: 16,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0
        },
        1024: {
          spaceBetween: 20,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0
        }
      }"
      @swiper="onSwiper"
    >
      <swiper-slide
        v-for="(i, index) in data"
        :key="index"
        class="tw-flex tw-flex-col tw-w-[216px] tw-my-8"
        style="width: 216px;"
      >
        <img
          :key="index+'i'"
          :src="i.image"
          class="tw-h-[216px] tw-w-[216px] tw-object-cover"
          loading="lazy"
        >
        <a
          v-if="!!i?.username && i?.username_link"
          :key="index+'a'"
          class="tw-mt-2 tablet:tw-max-w-[260px]  tw-w-fit tw-line-clamp-1 tw-max-w-[168px] tw-text-sg-body-mobile tablet:tw-text-sg-body-tablet desktop:tw-text-sg-body"
          :class="{'tw-cursor-pointer hover:tw-underline': !!i?.username_link}"
          :href="i?.username_link"
          rel="noopener"
          target="_blank"
        >
          {{ `@${i?.username}` }}
        </a>
        <p
          v-else-if="!!i?.username"
          class="tw-mt-2 tablet:tw-max-w-[260px]  tw-w-fit tw-line-clamp-1 tw-max-w-[168px] tw-text-sg-body-mobile tablet:tw-text-sg-body-tablet desktop:tw-text-sg-body"
        >
          {{ `@${i?.username}` }}
        </p>
        <div class="swiper-lazy-preloader" />
      </swiper-slide>
    </swiper>
    <div
      v-if="!isMobile"
      :class="[
        'tw-flex tw-items-center tw-justify-center',
      ]"
    >
      <img
        class="tw-min-w-[56px] tw-h-[56px] tw-object-cover"
        :class="{
          'tw-cursor-pointer': !rightBtnDisable,
          'tw-cursor-not-allowed': rightBtnDisable
        }"
        :src="$cdn(rightBtnDisable ? 'icons/cny25/next_round_button_disable.svg' : 'icons/cny25/next_round_button_'+rightBtnState+'.svg')"
        @mouseenter="rightBtnState = 'hover'"
        @mouseleave="rightBtnState = 'idle'"
        @mousedown="rightBtnState = 'active'"
        @mouseup="rightBtnState = 'hover'"
        @touchstart="rightBtnState = 'active'"
        @touchend="rightBtnState = 'idle'"
        @click="onClickNext"
      >
    </div>
  </div>
</template>
<script setup lang="ts">
// Import Swiper Vue.js components
import type {Swiper as SwiperInstance} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useI18n } from 'vue-i18n';
import { computed, onBeforeUnmount, watch } from 'vue';
import { onMounted, ref } from 'vue';
const {t} = useI18n();

const leftBtnState = ref('disable');
const leftBtnDisable = ref(true);
const rightBtnState = ref('idle');
const rightBtnDisable = ref(false);

const props = withDefaults(defineProps<{
  social_media_images: any| null
}>(), {
  social_media_images: null
});

onMounted(() => {
  watch(swiperInstance.value, (value: SwiperInstance) => {
    if (!value) return;
    const isEnd = value.isEnd;
    const isBeginning = value.isBeginning;
    if (isBeginning !== leftBtnDisable.value) {
      leftBtnDisable.value = isBeginning;
      if (!isBeginning) {
        leftBtnState.value = 'idle';
      } else {
        leftBtnState.value = 'disable';
      }
    }
    if (isEnd !== rightBtnDisable.value) {
      rightBtnDisable.value = isEnd;
      if (!isEnd) {
        rightBtnState.value = 'idle';
      } else {
        rightBtnState.value = 'disable';
      }
    }
  });
});

const swiperInstance = ref<SwiperInstance>();
const onSwiper = (swiper:any) => {
  swiperInstance.value = swiper;
};

function onClickPrev(){
  swiperInstance.value?.slidePrev();
}
function onClickNext(){
  swiperInstance.value?.slideNext();
}


const data = computed(() => {
  // Loop to 12 slides
  if(!props.social_media_images?.length > 0) return [];
  const neededCount = props.social_media_images.length > 12 ?  props.social_media_images.length : 12;
  const copyCount = Math.floor(neededCount / props.social_media_images.length);
  return Array.from({length:copyCount}).flatMap(()=> props.social_media_images);
});

const isMobile = ref(window.innerWidth <= 600);
function handleOnResize() {
  isMobile.value = window.innerWidth <= 600;
}

onMounted(() => {
  handleOnResize();
  window.addEventListener('resize', handleOnResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleOnResize);
});

</script>
<style lang="scss">

</style>
