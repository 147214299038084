<template>
  <div
    class="tw-relative"
    :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
    style="background: linear-gradient(0deg, #FFF 70%, #FFD68C 100%);"
  >
    <!-- loading animation -->
    <span :class="['tw-flex tw-transition-opacity tw-duration-[1500ms]', loadingAnimation]">
      <div class="tw-w-full tw-relative tw-flex tw-flex-col tw-justify-center">
        <div class="tw-relative tw-flex tw-justify-center">
          <img
            class="tw-w-[258px] tw-h-[258px]"
            :src="$cdn('images/loading-animation.gif')"
          >
          <div class="tw-absolute tw-bottom-[60px]">
            <div class="loadingBarContainer">
              <div :class="['loadingBar', loadingBar]" />
            </div>
          </div>
        </div>
        <p class="tw-text-sg-cny25-body tw-text-sg-cny24-red tw-text-center tw-relative tw-bottom-[20px]">Loading...</p>
      </div>
    </span>

    <span :class="['tw-block  tw-transition-opacity tw-duration-[1000ms]', mainStorySection]">
      <div class="tw-bg-[#FFD68C] tw-relative">
        <div class="tw-absolute tw-top-[500px] tw-left-0 tw-hidden sgTablet:tw-flex">
          <img
            :src="$cdn('images/cny2024/floatingMountain/floatingMountain4.png')"
            class="tw-w-[100px]"
          >
        </div>
        <div class="tw-absolute tw-bottom-[500px] tw-right-0 tw-hidden sgTablet:tw-flex">
          <img
            :src="$cdn('images/cny2024/floatingMountain/floatingMountain4.png')"
            class="tw-w-[100px]"
          >
        </div>
        <div class="tw-flex tw-flex-col tw-gap-6 tw-flex-wrap tw-max-w-[600px] tw-m-auto tw-pt-5 tw-pb-10 tablet:tw-pt-10 tablet:tw-pb-20 tw-px-4 tablet:tw-px-0">

          <div class="tw-flex tw-flex-col tw-gap-4 tablet:tw-gap-5 tw-mx-auto">
            <div
              class="tw-text-center tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop tw-h-fit tw-w-fit tw-m-auto tw-text-[#C52600]"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold tw-text-nowrap' : 'tw-font-akkuratbold'"
            >
              {{ story.title }}
            </div>
            <div
              v-if="story.subtitle"
              class="tw-text-center tw-text-sg-label-2 tw-h-fit tw-w-fit tw-m-auto tw-text-[#C52600]"
              :class="[
                checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold',
              ]"
              v-html="story.subtitle"
            />
            <div
              :class="[
                'tw-text-sg-cny25-b3 tw-w-fit tw-m-auto tw-text-center',
                checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat',
                checkLang() == 'zh-hans' ? '' : ' tw-italic',
              ]"
              v-html="story.desc"
            />
          </div>

          <!-- the legend of nian -->
          <div
            v-show="story.id == 1"
            id="sup-handler-container"
            class="tw-flex tw-flex-col tw-gap-6 tw-flex-wrap tw-text-sg-cny25-body"
          >
            <!-- Paragraph1 -->
            <div
              v-html="$t('cny25_origin_stories_nian_paragraph1')"
            />
            <!-- Paragraph2 -->
            <div class="tw-flex tw-flex-wrap tw-gap-6 tablet:tw-gap-0">
              <div
                class="tw-w-[100%] tablet:tw-w-[50%] tw-pr-0 tablet:tw-pr-6 tw-flex tw-justify-center tw-max-h-[300px] tablet:tw-max-h-fit"
              >
                <img
                  :src="$cdn('images/cny2025/' + story.imagePath1)"
                  class="tw-object-contain tw-max-h-[300px] "
                  :class="[
                    { 'tablet:tw-max-h-[192px]': checkLang() != 'en' },
                    { 'tablet:tw-max-h-[312px]': checkLang() == 'en' },
                  ]"
                >
              </div>
              <div
                class="tw-w-[100%] tablet:tw-w-[50%]"
                v-html="$t('cny25_origin_stories_nian_paragraph2')"
              />
            </div>
            <!-- Paragraph3 -->
            <div
              v-html="$t('cny25_origin_stories_nian_paragraph3')"
            />
            <!-- Paragraph4 -->
            <div class="tw-flex tw-flex-wrap tw-items-center">
              <div class="tw-w-[100%] tablet:tw-w-[50%] tw-pr-0 tablet:tw-pr-6">
                <p v-html=" $t('cny25_origin_stories_nian_paragraph4')" />
              </div>
              <div class="tw-w-[100%] tablet:tw-w-[50%] tw-flex tw-justify-center tw-max-h-[300px] tablet:tw-max-h-fit">
                <img
                  :src="$cdn('images/cny2025/' + story.imagePath2)"
                  class="tw-object-contain tw-max-h-[300px]"
                  :class="[
                    { 'tablet:tw-max-h-[192px]': checkLang() != 'en' },
                    { 'tablet:tw-max-h-[264px]': checkLang() == 'en' },
                  ]"
                >
              </div>
            </div>
            <!-- Paragraph5 -->
            <p
              v-html="$t('cny25_origin_stories_nian_paragraph5')"
            />
          </div>

          <!-- the story of zodiac race -->
          <div
            v-show="story.id == 2"
            class="tw-flex tw-flex-col tw-gap-6 tw-flex-wrap tw-text-sg-cny25-body"
          >
            <!-- Paragraph1 -->
            <div v-html="$t('cny25_origin_stories_zodiac_race_paragraph1')" />
            <!-- Paragraph2 -->
            <div class="tw-flex tw-flex-wrap">
              <div
                class="tw-w-[100%] tablet:tw-w-[50%] tw-pr-0 tablet:tw-pr-6 tw-flex tw-justify-center tw-max-h-[300px] tablet:tw-max-h-fit tw-py-6 tablet:tw-py-0"
              >
                <img :src="$cdn('images/cny2024/' + story.imagePath1)">
              </div>
              <div class="tw-w-[100%] tablet:tw-w-[50%] tw-flex tw-justify-center">
                <p>
                  {{ $t('cny25_origin_stories_zodiac_race_paragraph2') }}
                </p>
              </div>
            </div>
            <!-- Paragraph3 -->
            <div v-html="$t('cny25_origin_stories_zodiac_race_paragraph3')" />
            <!-- Paragraph4 -->
            <div class="tw-flex tw-flex-wrap">
              <div class="tw-w-[100%] tablet:tw-w-[50%] tw-pr-0 tablet:tw-pr-6">
                <div v-html="$t('cny25_origin_stories_zodiac_race_paragraph4')" />
              </div>
              <div class="tw-w-[100%] tablet:tw-w-[50%] tw-flex tw-justify-center tw-max-h-[300px] tablet:tw-max-h-fit tw-py-6 tablet:tw-py-0">
                <img
                  :src="$cdn('images/cny2024/' + story.imagePath2)"
                  class="tw-object-contain tw-max-h-[300px] tablet:tw-max-h-[192px]"
                >
              </div>
            </div>
            <!-- Paragraph5 -->
            <p>
              {{ $t('cny25_origin_stories_zodiac_race_paragraph5') }}
            </p>
            <!-- Paragraph6 -->
            <p>
              {{ $t('cny25_origin_stories_zodiac_race_paragraph6') }}
            </p>
            <!-- Paragraph7 -->
            <p>
              {{ $t('cny25_origin_stories_zodiac_race_paragraph7') }}
            </p>
            <!-- Paragraph8 -->
            <div class="tw-flex tw-flex-wrap">
              <div
                class="tw-w-[100%] tablet:tw-w-[50%] tw-pr-0 tablet:tw-pr-6 tw-flex tw-justify-center tw-max-h-[300px] tablet:tw-max-h-fit tw-py-6 tablet:tw-py-0"
              >
                <img
                  :src="$cdn('images/cny2024/' + story.imagePath3)"
                  class="tw-object-contain"
                >
              </div>
              <div class="tw-w-[100%] tablet:tw-w-[50%]">
                <p>
                  {{ $t('cny25_origin_stories_zodiac_race_paragraph8') }}
                </p>
              </div>
            </div>
            <!-- Paragraph9 -->
            <p>
              {{ $t('cny25_origin_stories_zodiac_race_paragraph9') }}
            </p>
          </div>

          <!-- bai tian going custom -->
          <div
            v-show="story.id == 3"
            class="tw-flex tw-flex-wrap tw-flex-col tw-gap-6 tw-text-sg-cny25-body"
          >
            <!-- Paragraph0 -->
            <div v-html="$t('cny25_origin_stories_bai_tian_gong_desc')" />
            <!-- Paragraph1 -->
            <div class="tw-flex tw-flex-col">
              <div class="tw-text-sg-sh2">{{ $t('cny25_origin_stories_bai_tian_gong_version_1') }}</div>
              <div v-html="$t('cny25_origin_stories_bai_tian_gong_paragraph1')" />
            </div>
            <!-- Paragraph2 -->
            <div class="tw-flex tw-flex-wrap">
              <div
                class="tw-w-[100%] tablet:tw-w-[50%] tw-pr-0 tablet:tw-pr-6 tw-flex tw-justify-center tw-max-h-[300px] tablet:tw-max-h-fit tw-py-6 tablet:tw-py-0"
              >
                <img
                  :src="$cdn('images/cny2024/' + story.imagePath1)"
                  class="tw-object-contain"
                >
              </div>
              <div class="tw-w-[100%] tablet:tw-w-[50%] tw-flex tw-justify-center tw-items-center">
                <div
                  id="sup-handler-container"
                  v-html="$t('cny25_origin_stories_bai_tian_gong_paragraph2')"
                />
              </div>
            </div>
            <!-- Paragraph3 -->
            <div class="tw-flex tw-flex-col">
              <div class="tw-text-sg-sh2">{{ $t('cny25_origin_stories_bai_tian_gong_version_2') }}</div>
              <p
                id="sup-handler-container"
                v-html="$t('cny25_origin_stories_bai_tian_gong_paragraph3')"
              />

            </div>
            <div class="tw-flex tw-flex-wrap tw-pb-6 tw-w-full tw-m-auto tw-justify-center tw-max-h-[300px] tablet:tw-max-h-fit tw-py-6 tablet:tw-py-0">
              <img
                :src="$cdn('images/cny2024/' + story.imagePath2)"
                class="tw-object-contain"
              >
            </div>
          </div>

          <!-- the origin of ren ri -->
          <div
            v-show="story.id == 4"
            class="tw-flex tw-flex-wrap tw-text-sg-cny25-body"
          >
            <p class="tw-pb-6">
              {{ $t('cny25_origin_stories_ren_ri_paragraph1') }}
            </p>

            <div
              id="sup-handler-container"
              class="tw-flex tw-flex-wrap tw-pb-6"
            >
              <div
                class="tw-w-[100%] tablet:tw-w-[50%] tw-pr-0 tablet:tw-pr-6 tw-flex tw-justify-center tw-max-h-[300px] tablet:tw-max-h-fit tw-py-6 tablet:tw-py-0"
              >
                <img
                  :src="$cdn('images/cny2024/' + story.imagePath1)"
                  class="tw-object-contain"
                >
              </div>
              <div class="tw-w-[100%] tablet:tw-w-[50%] tw-flex tw-justify-center tw-items-center">
                <div v-html="$t('cny25_origin_stories_ren_ri_paragraph2')" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- why didn't the cat participate in the race -->
      <div
        v-show="story.id == 2"
        class="tw-relative"
      >
        <div class="tw-absolute tw-top-[-50px] tw-right-0 tw-hidden sgTablet:tw-flex">
          <img
            :src="$cdn('images/cny2024/floatingMountain/floatingMountain2.png')"
            class="tw-w-[160px]"
          >
        </div>
        <div class="tw-absolute tw-top-[400px] tw-left-0 tw-hidden sgTablet:tw-flex">
          <img
            :src="$cdn('images/cny2024/floatingMountain/floatingMountain3.png')"
            class="tw-w-[100px]"
          >
        </div>
        <div class="tw-absolute tw-top-[50px] tw-right-0 tw-hidden sgTablet:tw-flex">
          <img
            :src="$cdn('images/cny2024/floatingMountain/floatingMountain4.png')"
            class="tw-w-[100px]"
          >
        </div>
        <div class="tw-max-w-[600px] tw-m-auto tw-pt-5 tw-pb-10 tablet:tw-pt-[90px] tablet:tw-pb-20 tw-px-4 sgTablet:tw-px-0">
          <div
            id="sup-handler-container"
            class="tw-flex tw-flex-col tw-gap-6 tw-mx-auto"
          >
            <div
              class="tw-font-normal tw-text-center tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop tw-h-fit tw-text-[#C52600]"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('cny25_origin_stories_zodiac_race_paragraph10') }}
            </div>
            <div class="tw-flex tw-flex-wrap tw-w-full tw-m-auto tw-justify-center">
              <img
                :src="$cdn('images/cny2024/zodiacRace/zodiacRace4.png')"
                class="tw-object-contain tw-min-h-[200px]"
              >
            </div>
            <div v-html="$t('cny25_origin_stories_zodiac_race_paragraph11')" />
          </div>
        </div>
      </div>
    </span>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../support/helper';

const { t } = useI18n();
let loadingTimeout = ref();
const loadingAnimation = ref('tw-opacity-1 tw-h-auto');
const loadingBar = ref('');
const mainStorySection = ref('tw-opacity-0 tw-h-0');

let story = ref({
  id: 1,
  title: '',
  subtitle: '',
  desc: '',
  imagePath1: '',
  imagePath2: '',
  imagePath3: '',
});

defineExpose({ getStoryData });

const storyData = computed(() => [
  {
    id: 1,
    title: t('cny25_the_legend_nian'),
    subtitle: '', // Hidden
    description: t('cny25_origin_stories_nian_desc'),
    imagePath1: 'origin_stories/legend_nian/1.png',
    imagePath2: 'origin_stories/legend_nian/2.png',
    imagePath3: '',
  },
  {
    id: 2,
    title: t('cny25_origin_stories_zodiac_race'),
    subtitle: '',
    description: t('cny25_origin_stories_zodiac_race_subtitle'),
    imagePath1: 'zodiacRace/zodiacRace.png',
    imagePath2: 'zodiacRace/zodiacRace2.png',
    imagePath3: 'zodiacRace/zodiacRace3.png',
  },
  {
    id: 3,
    title: t('cny25_bai_tian_gong_custom'),
    subtitle: '',
    description: t('cny25_bai_tian_gong_custom_subtitle'),
    imagePath1: 'baitianGong/baitianGong.png',
    imagePath2: 'baitianGong/baitianGong2.png',
    imagePath3: '',
  },
  {
    id: 4,
    title: t('cny25_the_origins_of_ren_ri'),
    subtitle: '',
    description: t('cny25_origin_stories_ren_ri_desc'),
    imagePath1: 'originRenri/originRenri.png',
    imagePath2: 'originRenri/originRenri2.png',
    imagePath3: '',
  },
]);

onMounted(() => {
  getStoryData(1);
});

function getStoryData(id: number) {
  loadingAnimation.value = 'tw-opacity-1 tw-visible tw-min-h-screen';
  mainStorySection.value = 'tw-opacity-0 tw-invisible tw-h-0';

  for (var i = 0; i < storyData.value.length; i++) {
    if (id == storyData.value[i].id) {
      story.value.id = storyData.value[i].id;
      story.value.title = storyData.value[i].title;
      story.value.subtitle = storyData.value[i].subtitle;
      story.value.desc = storyData.value[i].description;
      story.value.imagePath1 = storyData.value[i].imagePath1;
      story.value.imagePath2 = storyData.value[i].imagePath2;
      story.value.imagePath3 = storyData.value[i].imagePath3;
    }
  }

  loadingBar.value = 'active';

  clearTimeout(loadingTimeout.value);
  loadingTimeout.value = setTimeout(() => {
    loadingAnimation.value = 'tw-opacity-0 tw-invisible tw-h-0';
    mainStorySection.value = 'tw-opacity-1 tw-visible tw-h-full';
    loadingBar.value = '';
  }, 500);
}
</script>
<style scoped>

sup {
  cursor: pointer;
  color: #0366D8;
}

.loadingBarContainer {
  width: 98px;
  height: 4px;
  /* background-color: #8c12d4;
  opacity: 0.6; */
  overflow: hidden;
}

.loadingBar {
  background-color: #8c12d4;
  height: 100%;
  opacity: 1;
  width: 0;
}

.loadingBar.active {
  animation: loadBar 1.5s ease-in-out forwards;
}

@keyframes loadBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

#sup-handler-container:deep(sup) {
  cursor: pointer;
  color: #0366D8;
}
</style>
