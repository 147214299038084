<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :cny-25-navigation="true"
      :cny25-p2-navigation="wordpressData?.unlock_sidebar"
    />

    <secondary-navigation
      :menu-items-data="menuItems"
      :offset-y="70"
    />
    <!-- Header -->
    <cny-25-header-banner
      :title="'cny_what_to_do'"
      :description="'cny25_red_packet_subtitle'"
    />
    <!-- Content -->
    <div
      id="about"
      :class="[
        'tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center',
        'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
        'tw-pt-10 tw-pb-10 sgTablet:tw-pb-[80px]'
      ]"
    >
      <section class="tw-flex tw-flex-col tw-justify-center tw-content-center tw-items-center tw-max-w-[600px] tw-gap-5 tw-text-sg-cny25-body">
        <!-- Flex your angbao -->
        <div
          class="tw-text-[#C52600] tw-text-sg-cny25-mobile-h1 tablet:tw-text-sg-cny25-tablet-h1 desktop:tw-text-sg-cny25-desktop-h1 !tw-font-bold"
          v-html="$t('cny25_red_packet_header_title')"
        />
        <div
          class="tw-flex tw-flex-col tw-text-center"
        >
          <div
            class="tw-flex tw-flex-col"
            v-html="wordpressData.description"
          />
        </div>
        <img
          class="tw-h-[160px] sgTablet:tw-h-[200px] tw-object-contain"
          :src="$cdn('images/cny2025/red_packet/cny25-red-packet-2025.png')"
        >
        <div
          class="tw-flex tw-flex-col tw-gap-y-4 tw-text-center"
          v-html="wordpressData.be_longs_description"
        />
      </section>
    </div>

    <!-- Page Break -->
    <CNY25FloatingDivider />
    <!-- Timeline Section -->
    <section class="tw-flex tw-flex-col tw-content-center tw-items-center tw-bg-[#FFD68C] tw-relative">
      <!-- Timeline -->
      <div class="tw-flex tw-flex-row tw-gap-3">
        <img
          class="tw-h-[60px] tablet:tw-h-[100px]"
          :src="$cdn('images/cny2024/redPacket/cny_lantern_left.png')"
        >
        <h2
          class="tw-pt-10 sgDesktop:tw-max-w-[600px] sgTablet:tw-max-w-[800px] tw-text-sg-cny24-red tw-text-sg-h2-mobile tablet:tw-text-sg-h2-tablet desktop:tw-text-sg-h2 tw-text-center"
        >
          {{ $t('cny_red_packet_check_past') }}
        </h2>
        <img
          class="tw-h-[60px] tablet:tw-h-[100px]"
          :src="$cdn('images/cny2024/redPacket/cny_lantern_right.png')"
        >
      </div>
      <!-- 2024 -->
      <div class="tw-w-full tw-flex tw-flex-col tw-items-center tw-pt-[60px] tw-px-4 sgTablet:tw-px-20 sgDesktop:tw-px-0">
        <div class="tw-flex tw-flex-col tw-max-w-[780px] tw-pb-[60px] tw-gap-10 tw-pl-5 sgTablet:tw-pl-10 timeline-section">
          <div
            id="2024"
            class="tw-text-sg-cny24-red tw-flex tw-flex-col tw-gap-5 tw-relative -tw-top-5"
          >
            <!-- Year and Title -->
            <div class="tw-flex tw-flex-col tw-gap-2">
              <h2
                class="tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop tw-text-[#C52600]"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                2024
              </h2>
              <p
                class="tw-text-sg-cny25-sh2"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                {{ $t('cny25_red_packet_2024_title') }}
              </p>
            </div>
            <!-- Description -->
            <p class="tw-text-sg-cny25-body tw-text-[#1A1A1A]">
              {{ $t('cny25_red_packet_2024_desc') }}
            </p>
          </div>
          <!-- Image /& Video -->
          <div class="tw-flex tw-flex-row tw-justify-start tablet:tw-justify-center tw-w-full">
            <img
              class="tw-h-[300px] tw-object-contain tw-w-full"
              :src="$cdn('images/cny2025/red_packet/cny25-red-packet-2024.png')"
            >
          </div>
          <div
            v-if="false"
            class="tw-flex tw-flex-col tw-justify-start tw-items-start tw-pt-5"
          >
            <div class="tw-border-4 tw-rounded-lg tw-border-sg-cny24-white-b40">
              <iframe
                class="tw-mx-auto tw-w-[75vw] tw- tablet:tw-w-[70vw] tablet:tw-aspect-video desktop:tw-w-[534px] desktop:tw-h-[300px] tw-rounded-sm"
                src="https://www.youtube.com/embed/5bTfkUhzLVg?si=aDw15QqJczNOgLkj"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 2023 -->
      <div class="tw-w-full tw-flex tw-flex-col tw-items-center tw-gap-[60px] tw-px-4 sgTablet:tw-px-20 sgDesktop:tw-px-0 tw-bg-[#FFF9EE]">
        <div class="tw-flex tw-flex-col tw-max-w-[780px] tw-pb-[60px] tw-gap-10 tw-pl-5 sgTablet:tw-pl-10 tw-pt-[80px] timeline-section">
          <div
            id="2023"
            class="tw-text-sg-cny24-red tw-flex tw-flex-col tw-gap-5 tw-relative -tw-top-5"
          >
            <!-- Year and Title -->
            <div class="tw-flex tw-flex-col tw-gap-2">
              <h2
                class="tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop tw-text-[#C52600]"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                2023
              </h2>
              <p
                class="tw-text-sg-cny25-sh2"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                {{ $t('cny25_red_packet_2023_title') }}
              </p>
            </div>
            <!-- Description -->
            <p class="tw-text-sg-cny25-body tw-text-[#1A1A1A]">
              {{ $t('cny25_red_packet_2023_desc') }}
            </p>
          </div>
          <!-- Image /& Video -->
          <div class="tw-flex tw-flex-row tw-justify-start tablet:tw-justify-center tw-w-full">
            <img
              class="tw-h-[300px] tw-object-contain tw-w-full"
              :src="$cdn('images/cny2025/red_packet/cny25-red-packet-2023.png')"
            >
          </div>
          <div
            v-if="false"
            class="tw-flex tw-flex-col tw-justify-start tw-items-start tw-pt-5"
          >
            <div class="tw-border-4 tw-rounded-lg tw-border-sg-cny24-white-b40">
              <iframe
                class="tw-mx-auto tw-w-[75vw] tw- tablet:tw-w-[70vw] tablet:tw-aspect-video desktop:tw-w-[534px] desktop:tw-h-[300px] tw-rounded-sm"
                src="https://www.youtube.com/embed/5bTfkUhzLVg?si=aDw15QqJczNOgLkj"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 2022 -->
      <div class="tw-w-full tw-flex tw-flex-col tw-items-center tw-gap-[60px] tw-px-4 sgTablet:tw-px-20 sgDesktop:tw-px-0">
        <div class="tw-flex tw-flex-col tw-max-w-[780px] tw-pb-[60px] tw-gap-10 tw-pl-5 sgTablet:tw-pl-10 tw-pt-[80px] timeline-section">
          <div
            id="2022"
            class="tw-text-sg-cny24-red tw-flex tw-flex-col tw-gap-5 tw-relative -tw-top-5"
          >
            <!-- Year and Title -->
            <div class="tw-flex tw-flex-col tw-gap-2">
              <h2
                class="tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop tw-text-[#C52600]"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                2022
              </h2>
              <p
                class="tw-text-sg-cny25-sh2"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                {{ $t('cny25_red_packet_2022_title') }}
              </p>
            </div>
            <!-- Description -->
            <div class="tw-flex tw-flex-col tw-gap-4">
              <div
                class="tw-text-sg-cny25-body tw-text-[#1A1A1A]"
                v-html="$t('cny25_red_packet_2022_desc')"
              />
            </div>
          </div>
          <!-- Image /& Video -->
          <div class="tw-flex tw-flex-row tw-justify-start tablet:tw-justify-center">
            <img
              class="tw-h-[300px] tw-w-full tw-object-cover"
              :src="$cdn('images/cny2025/red_packet/cny25-red-packet-2022.jpg')"
            >
          </div>
          <div class="tw-flex tw-flex-col tw-justify-start tw-items-start tw-pt-5">
            <div class="tw-border-4 tw-rounded-lg tw-border-sg-cny24-white-b40">
              <iframe
                class="tw-mx-auto tw-w-[75vw] tw- tablet:tw-w-[70vw] tablet:tw-aspect-video desktop:tw-w-[534px] desktop:tw-h-[300px] tw-rounded-sm"
                src="https://www.youtube.com/embed/5bTfkUhzLVg?si=aDw15QqJczNOgLkj"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 2021 -->
      <div class="tw-w-full tw-flex tw-flex-col tw-items-center tw-gap-[60px] tw-px-4 sgTablet:tw-px-20 sgDesktop:tw-px-0 tw-bg-[#FFF9EE]">
        <div class="tw-flex tw-flex-col tw-max-w-[780px] tw-pb-[60px] tw-gap-10 tw-pl-5 sgTablet:tw-pl-10 tw-pt-[80px] timeline-section">
          <div
            id="2021"
            class="tw-text-sg-cny24-red tw-flex tw-flex-col tw-gap-5 tw-relative -tw-top-5"
          >
            <!-- Title and Year -->
            <div class="tw-flex tw-flex-col tw-gap-2">
              <h2
                class="tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop tw-text-[#C52600]"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                2021
              </h2>
              <p
                class="tw-text-sg-cny25-sh2"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                {{ $t('cny25_red_packet_2021_title') }}
              </p>
            </div>
            <!-- Description -->
            <div class="tw-flex tw-flex-col tw-gap-4">
              <p
                class="tw-text-sg-cny25-body tw-text-[#1A1A1A]"
                v-html="$t('cny25_red_packet_2021_desc')"
              />
            </div>
          </div>
          <!-- Image /& Video -->
          <div class="tw-flex tw-flex-row tw-justify-start tablet:tw-justify-center">
            <img
              class="tw-h-[300px] tw-w-full tw-object-cover"
              :src="$cdn('images/cny2025/red_packet/cny25-red-packet-2021.jpg')"
            >
          </div>
          <div class="tw-flex tw-flex-col tw-justify-start tw-items-start tw-pt-5">
            <div class="tw-border-4 tw-rounded-lg tw-border-sg-cny24-white-b40">
              <iframe
                class="tw-mx-auto tw-w-[75vw] tw-aspect-video tablet:tw-w-[70vw] tablet:tw-aspect-video desktop:tw-w-[534px] desktop:tw-h-[300px] tw-rounded-sm"
                src="https://www.youtube.com/embed/wMx_kkh1nik?si=wlgmAbcg_w51ss7J"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 2020 -->
      <div class="tw-w-full tw-flex tw-flex-col tw-items-center tw-gap-[60px] tw-px-4 sgTablet:tw-px-20 sgDesktop:tw-px-0">
        <div class="tw-flex tw-flex-col tw-max-w-[780px] tw-pb-[60px] tw-gap-10 tw-pl-5 sgTablet:tw-pl-10 tw-pt-[80px] timeline-section">
          <div
            id="2020"
            class="tw-text-sg-cny24-red tw-flex tw-flex-col tw-gap-5 tw-relative -tw-top-5"
          >
            <!-- Title and Year -->
            <div class="tw-flex tw-flex-col tw-gap-2">
              <h2
                class="tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop tw-text-[#C52600]"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                2020
              </h2>
              <p
                class="tw-text-sg-cny25-sh2"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                {{ $t('cny25_red_packet_2020_title') }}
              </p>
            </div>
            <!-- Description -->
            <div
              class="tw-text-sg-cny25-body tw-text-[#1A1A1A]"
              v-html="$t('cny25_red_packet_2020_desc')"
            />
          </div>
          <!-- Image /& Video -->
          <div class="tw-flex tw-flex-row tw-justify-start tablet:tw-justify-center">
            <img
              class="tw-h-[300px] tw-w-full tw-object-cover"
              :src="$cdn('images/cny2025/red_packet/cny25-red-packet-2020.jpg')"
            >
          </div>
          <div class="tw-flex tw-flex-col tw-justify-start tw-items-start tw-pt-5">
            <div class="tw-border-4 tw-rounded-lg tw-border-sg-cny24-white-b40">
              <iframe
                class="tw-mx-auto tw-w-[75vw] tw- tablet:tw-w-[70vw] tablet:tw-aspect-video desktop:tw-w-[534px] desktop:tw-h-[300px] tw-rounded-sm"
                src="https://www.youtube.com/embed/yg2xfP8ncng?si=RsKJfLAnkl_xkQxO"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 2019 -->
      <div class="tw-flex tw-justify-center tw-px-4 sgTablet:tw-px-20 sgDesktop:tw-px-0 tw-w-full tw-bg-[#FFF9EE]">
        <div class="tw-w-full tw-h-[80px] tw-max-w-[780px] timeline-section-2019-border" />
      </div>
      <div class="tw-w-full tw-flex tw-flex-col tw-items-center tw-gap-[60px] tw-px-4 sgTablet:tw-px-20 sgDesktop:tw-px-0 tw-bg-[#FFF9EE]">
        <div class="tw-flex tw-flex-col tw-max-w-[780px] tw-pb-[60px] tw-gap-10 tw-pl-5 sgTablet:tw-pl-10 timeline-section-2019">
          <div
            id="2019"
            class="tw-text-sg-cny24-red tw-flex tw-flex-col tw-gap-5 tw-relative -tw-top-5"
          >
            <!-- Title and Year -->
            <div class="tw-flex tw-flex-col tw-gap-2">
              <h2
                class="tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop tw-text-[#C52600]"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                2019
              </h2>
              <p
                class="tw-text-sg-cny25-sh2"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                {{ $t('cny25_red_packet_2019_title') }}
              </p>
            </div>
            <!-- Description -->
            <div
              class="tw-text-sg-cny25-body tw-text-[#1A1A1A]"
              v-html="$t('cny25_red_packet_2019_desc')"
            />
          </div>
          <!-- Image /& Video -->
          <div class="tw-flex tw-flex-row tw-justify-start tablet:tw-justify-center">
            <img
              class="tw-h-[300px] tw-w-full tw-object-cover"
              :src="$cdn('images/cny2025/red_packet/cny25-red-packet-2019.jpg')"
            >
          </div>
          <div class="tw-flex tw-flex-col tw-justify-start tw-items-start tw-pt-5">
            <div class="tw-border-4 tw-rounded-lg tw-border-sg-cny24-white-b40">
              <iframe
                class="tw-flex tablet:tw-hidden"
                src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fofficialcatch.sg%2Fvideos%2F2254866687877446%2F&width=260&show_text=false&height=208&appId"
                width="260"
                height="208"
                style="border: none; overflow: hidden"
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              />
              <iframe
                class="tw-hidden tablet:tw-flex desktop:tw-hidden"
                src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fofficialcatch.sg%2Fvideos%2F2254866687877446%2F&width=360&show_text=false&height=288&appId"
                width="360"
                height="288"
                style="border: none; overflow: hidden"
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              />
              <iframe
                class="tw-hidden desktop:tw-flex"
                src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fofficialcatch.sg%2Fvideos%2F2254866687877446%2F&width=534&show_text=false&height=427&appId"
                width="534"
                height="427"
                style="border: none; overflow: hidden"
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <ap-footer />
  </div>
</template>

<script setup lang="ts">
import { checkLang } from '../../support/helper';
import CNY25FloatingDivider from '../../components/cny25/CNY25FloatingDivider.vue';

defineProps<{
  wordpressData: any;
}>();

const menuItems = [
  {
    label: 'cny_about',
    id: 'about',
  },
  {
    label: '2024',
    id: '2024',
  },
  {
    label: '2023',
    id: '2023',
  },
  {
    label: '2022',
    id: '2022',
  },
  {
    label: '2021',
    id: '2021',
  },
  {
    label: '2020',
    id: '2020',
  },
  {
    label: '2019',
    id: '2019',
  },
];
</script>

<style scoped>
.timeline-section {
  position: relative;
  border-left: 4px solid #c52600;
}

.timeline-section-2019-border {
  position: relative;
  border-left: 4px solid #c52600;
}

.timeline-section-2019 {
  position: relative;
}

.timeline-section-2019::before {
  content: '';
  position: absolute;
  left: -8px;
  width: 20px;
  height: 20px;
  background-color: #c52600;
  border-radius: 50%;
}

.timeline-section::before {
  content: '';
  position: absolute;
  left: -12px;
  width: 20px;
  height: 20px;
  background-color: #c52600;
  border-radius: 50%;
}
</style>
