<template>
  <div class="tw-flex tw-w-full tw-h-full tw-justify-center tw-px-4 sgTablet:tw-px-10 sgDesktop:tw-px-0">
    <div class="tw-max-w-[800px] tw-flex tw-flex-col tw-w-full tw-items-center">
      <div
        class="tw-flex tw-flex-col tw-items-center tw-max-w-[800px] sgTablet:tw-px-0 sgTablet:tw-items-start"
        :class="[
          isBuildLeft ? 'sgTablet:tw-flex-row' : 'sgTablet:tw-flex-row-reverse',
          isFirst
            ? 'tw-pt-10 tw-pb-5 sgTablet:tw-pb-10 sgDesktop:tw-pb-[60px]'
            : isLast
              ? 'tw-pt-5 tw-pb-10 sgTablet:tw-pt-10 sgDesktop:tw-pt-[60px] sgTablet:tw-pb-10 sgDesktop:tw-pb-[40px]'
              : 'tw-pt-5 tw-pb-5 sgTablet:tw-pt-10 sgDesktop:tw-pt-[60px] sgTablet:tw-pb-10 sgDesktop:tw-pb-[60px]',
        ]"
      >
        <img
          :src="$cdn(snack.image)"
          class="tw-w-[140px] tw-h-[140px] sgTablet:tw-w-[200px] sgTablet:tw-h-[200px]"
        >
        <div
          class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-gap-6
          sgTablet:tw-justify-start sgTablet:tw-items-start sgTablet:tw-gap-10"
          :class="[isBuildLeft ? 'sgTablet:tw-pl-10' : 'sgTablet:tw-pr-10']"
        >
          <div class="tw-flex tw-flex-col tw-items-center tw-gap-3 sgTablet:tw-items-start sgTablet:tw-gap-4">
            <p
              class="tw-text-[#C52600] tw-text-sg-cny25-h4-mobile tw-pt-8 sgTablet:tw-text-sg-cny25-h4-tablet sgTablet:tw-pt-0 sgDesktop:tw-text-sg-cny25-h4-desktop"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ snack.title }}
            </p>
            <p
              class="tw-text-sg-cny25-sh2 tw-text-[#651300]"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ snack.subtitle }}
            </p>
          </div>
          <div
            class="tw-text-sg-cny25-body tw-text-[#1A1A1A]"
            v-html="snack.description"
          />
        </div>
      </div>
      <hr
        v-if="!isLast"
        class="horizontal-line"
      >
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { checkLang } from '../../../support/helper';
interface Snack {
  title: string;
  description: string;
  subtitle: string;
  image: string;
}

export default {
  props: {
    snack: {
      type: Object as PropType<Snack>,
      required: true,
    },
    isBuildLeft: {
      type: Boolean,
      required: true,
    },
    isLast: {
      type: Boolean,
      required: true,
    },
    isFirst: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    return {
      checkLang,
    };
  },
};
</script>

<style scoped>
.horizontal-line {
  width: 100%;
  max-width: 800px;
  border-top: 2px solid #BF374C;
}
</style>
