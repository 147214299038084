<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :cny-25-navigation="true"
      :cny25-p2-navigation="wordpressData?.unlock_sidebar"
    />
    <secondary-navigation
      :menu-items-data="menuItems"
      :offset-y="90"
    />
    <!-- Header -->
    <cny-25-header-banner
      :title="'cny25_visiting_101'"
      :description="'cny_greetings_nav'"
    />
    <!-- Content -->
    <section
      :class="[
        'tw-flex tw-flex-col tw-justify-center tw-items-center',
        'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
        'tw-pt-10 tw-pb-8 sgTablet:tw-pb-10'
      ]"
    >
      <div class="tw-max-w-[600px] tw-text-[#1A1A1A] tw-flex tw-flex-col">
        <div class="tw-text-center">
          <div
            class="tw-text-sg-cny25-body tw-flex tw-flex-col tw-gap-5"
            v-html="props.wordpressData.description"
          />
        </div>
      </div>
    </section>

    <!-- Greetings Swipe-->
    <CNY25FloatingDivider :type="2" />
    <section>
      <cny-25-greetings-swiper
        swiper-type="basic"
        custom-id="greetings_basic"
        :greetings-datas="basicDatas"
        :max-item-size="4"
      />
      <cny-25-greetings-swiper
        swiper-type="intermediate"
        custom-id="greetings_intermediate"
        :greetings-datas="intermediateDatas"
      />
      <cny-25-greetings-swiper
        swiper-type="advanced"
        custom-id="greetings_advanced"
        :greetings-datas="advancedDatas"
        :max-item-size="5"
      />
      <cny-25-greetings-swiper
        swiper-type="zodiac"
        custom-id="greetings_zodiac"
        :greetings-datas="zodiacDatas"
      />
    </section>

    <!-- Dialect Swipe-->
    <section class="tw-bg-[#FFC6CE]">
      <cny-25-greetings-dialect />
    </section>

    <ap-footer />
  </div>
</template>

<script setup lang="ts">
import CNY25FloatingDivider from '../../components/cny25/CNY25FloatingDivider.vue';
import { computed } from 'vue';
import { checkLang } from '../../support/helper';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const props = defineProps<{
  wordpressData: any;
}>();

const menuItems = computed(() => [
  {
    label: 'cny_greetings_basic',
    id: 'greetings_basic',
  },
  {
    label: 'cny_greetings_intermediate',
    id: 'greetings_intermediate',
  },
  {
    label: 'cny_greetings_advanced',
    id: 'greetings_advanced',
  },
  {
    label: 'cny25_greetings_zodiac',
    id: 'greetings_zodiac',
  },
  {
    label: 'cny_greetings_dialects',
    id: 'greetings_dialect',
  },
]);

const basicDatas = computed(() => [
  {
    id: 1,
    title: '年年<br/>有余',
    pinyin: 'nián nián yǒu yú',
    description: t('cny25_greetings_basic_1'),
    sound_url: 'sound/cny/greetings/audioBasic2.mp3',
  },
  {
    id: 2,
    title: '大吉<br/>大利',
    pinyin: 'dà jí dà lì',
    description: t('cny25_greetings_basic_2'),
    sound_url: 'sound/cny/greetings/audioBasic4.mp3',
  },
  {
    id: 3,
    title: '心想<br/>事成',
    pinyin: 'xīn xiǎng shì chéng',
    description: t('cny25_greetings_basic_3'),
    sound_url: 'sound/cny/greetings/audioBasic3.mp3',
  },
  {
    id: 4,
    title: '身体<br/>健康',
    pinyin: 'shēn tǐ jiàn kāng',
    description: t('cny25_greetings_basic_4'),
    sound_url: 'sound/cny/greetings/audioBasic1.mp3',
  },
  {
    id: 5,
    title: '年年<br/>有余',
    pinyin: 'nián nián yǒu yú',
    description: t('cny25_greetings_basic_1'),
    sound_url: 'sound/cny/greetings/audioBasic2.mp3',
  },
  {
    id: 6,
    title: '大吉<br/>大利',
    pinyin: 'dà jí dà lì',
    description: t('cny25_greetings_basic_2'),
    sound_url: 'sound/cny/greetings/audioBasic4.mp3',
  },
  {
    id: 7,
    title: '心想<br/>事成',
    pinyin: 'xīn xiǎng shì chéng',
    description: t('cny25_greetings_basic_3'),
    sound_url: 'sound/cny/greetings/audioBasic3.mp3',
  },
  {
    id: 8,
    title: '身体<br/>健康',
    pinyin: 'shēn tǐ jiàn kāng',
    description: t('cny25_greetings_basic_4'),
    sound_url: 'sound/cny/greetings/audioBasic1.mp3',
  },
]);

const intermediateDatas = computed(() => [
  {
    id: 1,
    title: '万事<br/>如意',
    pinyin: 'wàn shì rú yì',
    description: t('cny25_greetings_intermediate_1'),
    sound_url: 'sound/cny/greetings/audioIntermediate1.mp3',
  },
  {
    id: 2,
    title: '步步<br/>高升',
    pinyin: 'bù bù gāo shēng',
    description: t('cny25_greetings_intermediate_2'),
    sound_url: 'sound/cny/greetings/audioIntermediate6.mp3',
  },
  {
    id: 3,
    title: '一帆<br/>风顺',
    pinyin: 'yī fān fēng shùn',
    sound_url: 'sound/cny/greetings/audioIntermediate2.mp3',
    description: t('cny25_greetings_intermediate_3'),
  },
  {
    id: 4,
    title: '阖家<br/>幸福',
    pinyin: 'hé jiā xìng fú',
    description: t('cny25_greetings_intermediate_4'),
    sound_url: 'sound/cny/greetings/audioIntermediate7.mp3',
  },
  {
    id: 5,
    title: '马到<br/>成功',
    pinyin: 'mǎ dào chéng gōng',
    description: t('cny25_greetings_intermediate_5'),
    sound_url: 'sound/cny/greetings/audioIntermediate8.mp3',
  },
  {
    id: 6,
    title: '招财<br/>进宝',
    pinyin: 'zhāo cái jìn bǎo',
    description: t('cny25_greetings_intermediate_6'),
    sound_url: 'sound/cny/greetings/audioIntermediate4.mp3',
  },
  {
    id: 7,
    title: '财源<br/>广进',
    pinyin: 'cái yuán guǎng jìn',
    description: t('cny25_greetings_intermediate_7'),
    sound_url: 'sound/cny/greetings/audioIntermediate9.mp3',
  },
  {
    id: 8,
    title: '吉祥<br/>如意',
    pinyin: 'jí xiáng rú yì',
    description: t('cny25_greetings_intermediate_8'),
    sound_url: 'sound/cny/greetings/audioIntermediate5.mp3',
  },
]);

const advancedDatas = computed(() => [
  {
    id: 1,
    title: '吉星<br/>高照',
    pinyin: 'jí xīng gāo zhào',
    description: t('cny25_greetings_advanced_1'),
    sound_url: 'sound/cny/greetings/audioAdvanced5.mp3',
  },
  {
    id: 2,
    title: '飞黄<br/>腾达',
    pinyin: 'fēi huáng téng dá',
    description: t('cny25_greetings_advanced_2'),
    sound_url: 'sound/cny/greetings/audioAdvanced2.mp3',
  },
  {
    id: 3,
    title: '大展<br/>宏图',
    pinyin: 'dà zhǎn hóng tú',
    description: t('cny25_greetings_advanced_3'),
    sound_url: 'sound/cny25/greetings/audioAdvanced6.mp3',
  },
  {
    id: 4,
    title: '升官<br/>发财',
    pinyin: 'shēng guān fā cái',
    description: t('cny25_greetings_advanced_4'),
    sound_url: 'sound/cny/greetings/audioAdvanced3.mp3',
  },
  {
    id: 5,
    title: '财源<br/>滚滚',
    pinyin: 'cái yuán gǔn gǔn',
    description: t('cny25_greetings_advanced_5'),
    sound_url: 'sound/cny/greetings/audioAdvanced7.mp3',
  },
  {
    id: 6,
    title: '吉星<br/>高照',
    pinyin: 'jí xīng gāo zhào',
    description: t('cny25_greetings_advanced_1'),
    sound_url: 'sound/cny/greetings/audioAdvanced5.mp3',
  },
  {
    id: 7,
    title: '飞黄<br/>腾达',
    pinyin: 'fēi huáng téng dá',
    description: t('cny25_greetings_advanced_2'),
    sound_url: 'sound/cny/greetings/audioAdvanced2.mp3',
  },
  {
    id: 8,
    title: '大展<br/>宏图',
    pinyin: 'dà zhǎn hóng tú',
    description: t('cny25_greetings_advanced_3'),
    sound_url: 'sound/cny25/greetings/audioAdvanced6.mp3',
  },
  {
    id: 9,
    title: '升官<br/>发财',
    pinyin: 'shēng guān fā cái',
    description: t('cny25_greetings_advanced_4'),
    sound_url: 'sound/cny/greetings/audioAdvanced3.mp3',
  },
  {
    id: 10,
    title: '财源<br/>滚滚',
    pinyin: 'cái yuán gǔn gǔn',
    description: t('cny25_greetings_advanced_5'),
    sound_url: 'sound/cny/greetings/audioAdvanced7.mp3',
  },
]);

const zodiacDatas = computed(() => [
  {
    id: 1,
    title: '灵蛇<br/>献瑞',
    pinyin: 'líng shé xiàn ruì',
    description: t('cny25_greetings_zodiac_1'),
    sound_url: 'sound/cny25/greetings/audioZodiac1.mp3',
  },
  {
    id: 2,
    title: '龙蛇<br/>呈祥',
    pinyin: 'lóng shé chéng xiáng',
    description: t('cny25_greetings_zodiac_2'),
    sound_url: 'sound/cny25/greetings/audioZodiac2.mp3',
  },
]);
</script>
