<template>
  <section
    id="cny_family_competition_menu"
    :class="[
      'tw-relative tw-hidden',
      'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
      'tw-py-10',
      'tw-flex tw-flex-col tw-justify-center tw-items-center',
    ]"
  >
    <!-- title -->
    <div
      :class="[
        'tw-text-[#C52600] tw-text-center tw-w-full tw-max-w-[500px] sgTablet:tw-max-w-[1028px]',
        'tw-text-sg-ws-h3-mobile sgTablet:tw-text-sg-ws-h3-tablet sgDesktop:tw-text-sg-ws-h3-desktop',
        { 'tw-font-akkuratbold' : checkLang() == 'en' },
        { 'tw-font-notosansbold' : checkLang() != 'en' },
      ]"
    >
      {{ $t('cny_family_fun_red_packet_craft_competition') }}
    </div>
    <!-- description -->
    <div
      :class="[
        'tw-pt-2 tw-pb-10',
        'tw-text-sg-ws-sh2',
        { 'tw-font-akkuratbold' : checkLang() == 'en' },
        { 'tw-font-notosansbold' : checkLang() != 'en' },
      ]"
    >
      {{ $t('cny_family_fun_entrees_close') }}
    </div>
    <section
      :class="[
        'tw-flex tw-relative',
        'tw-flex-col sgDesktop:tw-flex-row',
      ]"
    >
      <!-- left -->
      <div
        :class="[
          'tw-flex tw-flex-col tw-justify-center tw-items-center',
          'tw-relative tw-w-[min(90vw,500px)] tw-h-[300px] sgDesktop:tw-h-[1000px]',
          'tw-text-[#FFF9EE]',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
        :style="{
          background: `url(${isMobile? $cdn('images/cny2025/family_fun/submission_form/cny25-submission-form-banner-mobile-v3.jpg') : $cdn('images/cny2025/family_fun/submission_form/cny25-submission-form-banner-desktop-v3.jpg')}) lightgray 50% / cover no-repeat`
        }"
      >
        <img
          :class="[
            'tw-absolute tw-top-0 tw-left-0 tw-ml-[-1px] tw-mt-[-1px]',
          ]"
          :src="$cdn('images/cny2025/family_fun/submission_form/left-corner-desktop.svg')"
        >
        <img
          :class="[
            'tw-absolute',
            { 'tw-bottom-0 tw-left-0 tw-rotate-[-90deg] tw-mb-[-1px] tw-ml-[-1px]' : !isMobile },
            { 'tw-top-0 tw-right-0 tw-rotate-[90deg] tw-mt-[-1px] tw-mr-[-1px]' : isMobile },
          ]"
          :src="$cdn('images/cny2025/family_fun/submission_form/left-corner-desktop.svg')"
        >
        <div
          :class="[
            'tw-text-center',
            'tw-text-sg-cny25-craft-mobile sgDesktop:tw-text-sg-cny25-craft',
          ]"
          style="text-shadow: -4px 4px 10px rgba(0, 0, 0, 0.3); transform: rotate(-6.819deg);"
          v-html="$t('cny_family_fun_huat_and_crafts')"
        />
        <div
          :class="[
            'tw-text-sg-cny25-sh1',
          ]"
          style="text-shadow: -2px 2px 5px rgba(0, 0, 0, 0.3); transform: rotate(-6.819deg);"
        >
          {{ $t('cny_family_fun_decoration_competition') }}
        </div>
      </div>

      <!-- right -->
      <div
        :class="[
          'tw-relative tw-flex tw-flex-col',
          'tw-bg-[#FFF3DE]',
          'tw-w-[min(90vw,500px)] tw-h-[1000px]'
        ]"
      >
        <img
          :class="[
            'tw-absolute',
            { 'tw-top-0 tw-right-0 tw-mt-[-1px] tw-mr-[-1px]' : !isMobile },
            { 'tw-bottom-0 tw-left-0 tw-rotate-[180deg] tw-mb-[-1px] tw-ml-[-1px]' : isMobile },
          ]"
          :src="$cdn('images/cny2025/family_fun/submission_form/right-corner-desktop.svg')"
        >
        <img
          :class="[
            'tw-absolute tw-bottom-0 tw-right-0 tw-rotate-[90deg] tw-mb-[-1px] tw-mr-[-1px]',
          ]"
          :src="$cdn('images/cny2025/family_fun/submission_form/right-corner-desktop.svg')"
        >
        <div
          :class="[
            'tw-flex tw-flex-col tw-justify-center tw-items-center',
            'tw-h-[100px] tw-text-[#C52600]',
            'tw-py-7'
          ]"
          style="box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, 0.05);"
        >
          <div
            v-if="currentPage == 1"
            :class="[
              'tw-text-sg-cny25-h4-mobile sgTablet:tw-text-sg-cny25-h4-tablet sgDesktop:tw-text-sg-cny25-h4-desktop',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('cny_family_fun_competition_details') }}
          </div>
          <div
            v-else
            :class="[
              'tw-flex tw-flex-row tw-cursor-pointer',
              'tw-w-full tw-px-8'
            ]"
            @click="onClickPrev"
          >
            <img :src="$cdn('icons/arrow_chevron_left_maroon.svg')">
            <div :class="[dynamicFontClass, 'tw-ml-2']">
              {{ $t('cny_family_fun_back') }}
            </div>
          </div>
        </div>
        <div
          :class="[
            'tw-h-full tw-w-full tw-flex-grow tw-overflow-y-auto',
            'tw-py-5'
          ]"
        >
          <cny-25-competition-details v-if="currentPage == 1" />
          <cny-25-competition-form
            v-else-if="currentPage == 2"
            ref="formRef"
            @validate="validateApplication"
            @sending="onSendingSubmission"
            @error="onErrorSubmission"
            @success="onSuccessSubmission"
          />
          <div
            v-else
            :class="[
              'tw-flex tw-flex-col tw-justify-center tw-items-center',
              'tw-h-full tw-w-full tw-gap-3',
              'tw-text-sg-cny25-sh2 tw-text-[#36A93F]',
              dynamicFontClass
            ]"
          >
            <img
              :src="$cdn('icons/check_circle_green.svg')"
              class="tw-w-[100px]"
            >
            <p class="tw-max-w-[120px] tw-text-center">
              {{ $t('cny_family_fun_submission_sent') }}
            </p>
          </div>
        </div>
        <div
          :class="[
            'tw-flex tw-flex-col tw-justify-center tw-items-center',
            'tw-h-[100px] tw-text-[#C52600]',
            'tw-text-sg-cny25-h4-mobile sgTablet:tw-text-sg-cny25-h4-tablet sgDesktop:tw-text-sg-cny25-h4-desktop',
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
          style="box-shadow: 0px -8px 16px -8px rgba(0, 0, 0, 0.05);"
        >
          <div
            :class="[
              'tw-flex tw-justify-center',
              'tw-py-7 tw-px-10 tw-w-full'
            ]"
          >
            <div
              :class="[
                'tw-flex tw-items-center tw-justify-center',
                'tw-gap-2',
                'tw-px-6 tw-py-2 tw-min-w-[200px] tw-text-center',
                'tw-text-sg-cny25-bt1',
                'tw-rounded-[26px] !tw-leading-[20px] tw-uppercase',
                dynamicFontClass,
                { 'tw-flex-row tw-bg-[#36A93F] tw-text-white hover:tw-bg-[#8CDA93] active:tw-bg-[#26762C] tw-cursor-pointer' : currentPage == 1 },
                { 'tw-flex-row tw-bg-[#36A93F] tw-text-white hover:tw-bg-[#8CDA93] active:tw-bg-[#26762C] tw-cursor-pointer' : currentPage == 2 },
                { 'tw-flex-row-reverse tw-text-white tw-bg-[#0366D8] hover:tw-bg-[#66ACFD] active:tw-bg-[#024695] tw-cursor-pointer': currentPage == 3 },
                { 'tw-bg-[#D1D1D1] hover:tw-bg-[#D1D1D1] active:tw-bg-[#D1D1D1] tw-cursor-wait' : isLoading },
                { 'tw-bg-[#D1D1D1] hover:tw-bg-[#D1D1D1] active:tw-bg-[#D1D1D1] tw-cursor-not-allowed' : !allowSubmit },
              ]"
              @click="onClickNext"
              @mouseenter="isButtonHover = true"
              @mouseleave="isButtonHover = false"
            >
              <p>{{ buttonTitle }}</p>
              <img
                class="tw-w-5 tw-h-5"
                :src="$cdn(buttonArrow)"
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { checkLang } from '../../../support/helper';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const currentPage = ref(1);
const formRef = ref<any>(null);
const isMobile = ref(window.innerWidth < 1025);
const isLoading = ref(false);
const allowSubmit = ref(true);
const isButtonHover = ref(false);

const buttonTitle = computed(() => {
  if (currentPage.value == 1) {
    return t('cny_family_fun_continue');
  } else if (currentPage.value == 2) {
    return t('cny_family_fun_submit');
  } else {
    return t('cny_family_fun_submit_another');
  }
});

const buttonArrow = computed(() => {
  if (currentPage.value == 1) {
    return 'icons/arrow_chevron_right_white.svg';
  } else if (currentPage.value == 2) {
    return 'icons/arrow_chevron_right_white.svg';
  } else {
    return 'icons/arrow_chevron_left_white.svg';
  }
});

const dynamicFontClass = computed(() => {
  return checkLang() === 'en' ?
    'tw-text-sg-cny25-sh3 tw-font-akkuratbold' :
    'tw-text-sg-cny25-sh3 tw-font-notosansbold';
});

function onResize() {
  isMobile.value = window.innerWidth < 1025;
}

onMounted(() => {
  window.addEventListener('resize', onResize);
  onResize();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});

function validateApplication(validate: boolean) {
  allowSubmit.value = validate;
}

function triggerSubmit() {
  if (!allowSubmit.value){
    alert(t('cny_family_fun_information_incomplete'));
    return;
  }
  if (formRef.value && formRef.value.submitForm) {
    formRef.value.submitForm();
  }
}

function onClickNext() {
  if (currentPage.value == 3) {
    currentPage.value = 1;
  } else if (currentPage.value == 2) {
    triggerSubmit();
  } else {
    currentPage.value++;
    allowSubmit.value = false;
  }
}

function onClickPrev() {
  currentPage.value = 1;
  allowSubmit.value = true;
}

function onSendingSubmission() {
  isLoading.value = true;
}

function onSuccessSubmission() {
  currentPage.value = 3;
  isLoading.value = false;
}

function onErrorSubmission() {
  currentPage.value = 2;
  isLoading.value = false;
  alert(t('cny_family_fun_submission_error'));
  return;
}

</script>


<style scoped>
</style>
