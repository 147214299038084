<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :cny-25-navigation="true"
      :cny25-p2-navigation="wordpressData?.unlock_sidebar"
    />

    <secondary-navigation
      :menu-items-data="menuItems"
      :offset-y="70"
    />

    <!-- Header -->
    <cny-25-header-banner
      :title="'cny_about'"
      :description="'cny_about_subtitle'"
    />

    <!-- Content -->
    <div class="tw-flex tw-flex-col tw-w-full">
      <section
        :class="[
          'tw-flex tw-flex-col tw-justify-center tw-items-center',
          'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
          'tw-py-5 sgTablet:tw-py-10'
        ]"
      >

        <div
          :class="[
            'tw-flex tw-flex-col',
            'tw-max-w-[600px] tw-w-full',
            'text tw-text-sg-cny25-body tw-gap-6'
          ]"
          v-html="wordpressData.description"
        />
      </section>
      <CNY25FloatingDivider />
      <!-- Yusheng -->
      <section
        id="yu-sheng"
        :class="[
          'tw-flex tw-flex-col tw-items-center tw-justify-center',
          'tw-bg-[#FFD68C] tw-text-[#1A1A1A]',
          'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
          'tw-pt-10 sgTablet:tw-pt-5'
        ]"
      >
        <div class="tw-max-w-[600px] tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-6">
          <img
            class="tw-w-[200px]"
            :src="$cdn('images/cny2024/yusheng.png')"
          >
          <p
            class="tw-text-[#C52600] tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          >
            {{ $t('cny_yusheng') }}
          </p>
          <!-- Image and Text Row -->
          <div
            :class="[
              'tw-flex tw-flex-col sgTablet:tw-flex-row tw-items-center',
              'tw-max-w-[600px] tw-gap-6'
            ]"
          >
            <img
              class="tw-max-w-[343px] tw-w-full sgTablet:tw-max-h-[168px] sgTablet:tw-max-w-[288px] tw-object-contain"
              :src="$cdn('images/cny2024/yusheng-group-people.png')"
            >
            <p class="tw-text-sg-cny25-body">
              {{ $t('cny25_yusheng_description_1') }}
            </p>
          </div>
          <!-- Text -->
          <div
            class="tw-max-w-[600px] tw-text-sg-cny25-body"
            v-html="$t('cny25_yusheng_description_2')"
          />
          <!-- Four Heavely Kings -->
          <div class="tw-flex tw-flex-row sgTablet:tw-flex-row sgDesktop:tw-flex-row sgDesktop:tw-max-w-[600px]">
            <div class="tw-flex tw-flex-col">
              <p
                class="tw-text-[#C52600] tw-text-sg-cny25-sh1"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                {{ $t('cny25_four_heavenly_kings') }}
                <span
                  id="sup-handler-container"
                  class="text tw-text-sg-cny25-body tw-cursor-pointer tw-text-[#0366d8]"
                  v-html="'<sub>[A]</sub>'"
                />
              </p>
              <div class="tw-pb-6" />
              <div
                id="sup-handler-container"
                class="text tw-text-sg-cny25-body"
                v-html="$t('cny25_yusheng_heavenly_kings_description')"
              />
            </div>
            <!-- Padding right -->
            <div class="tw-pr-6" />
            <!-- chefs image -->
            <div class="tw-flex tw-flex-col tw-justify-center tw-align-middle tw-w-[100px]">
              <img
                v-for="index in 4"
                :key="index"
                :class="[index !== 4 ? 'tw-pb-10 sgTablet:tw-pb-6 sgDesktop:tw-pb-6' : '', 'tw-min-w-[100px] tw-min-h-[100px]']"
                :src="$cdn(`/images/cny2024/chef/chef${index}.png`)"
              >
            </div>
          </div>
          <div class="tw-pb-[100px]" />
        </div>
      </section>

      <!-- Mandarin Oranges -->
      <section
        id="mandarin-oranges"
        :class="[
          'tw-relative',
          'tw-flex tw-flex-col tw-justify-center tw-items-center',
          'tw-bg-[#FFF3DE] tw-h-full tw-w-full',
          'tw-text-[#1A1A1A]',
          'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
          'tw-pb-[100px]'
        ]"
      >
        <img
          class="tw-w-[200px] tw-h-[200px] tw-mt-[-100px]"
          :src="$cdn('images/cny2024/mandarin-orange.png')"
        >
        <div class="tw-max-w-[600px] tw-flex tw-flex-col tw-gap-6">
          <p
            :class="[
              'tw-pb-6',
              'tw-text-[#C52600] tw-text-sg-h2-mobile-cny sgTablet:tw-text-sg-h2-tablet-cny sgDesktop:tw-text-sg-h2-cny',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
              'tw-text-center'
            ]"
          >
            {{ $t('cny25_mandarin_oranges') }}
          </p>
          <div class="tw-max-w-[600px] tw-text-sg-cny25-body tw-relative tw-z-10">
            <div
              class="text"
              v-html="$t('cny25_mandarin_oranges_description')"
            />
          </div>
        </div>
      </section>

      <!-- Pineapple Tarts -->
      <section
        id="pineapple-tarts"
        :class="[
          'tw-relative',
          'tw-flex tw-flex-col tw-justify-center tw-items-center',
          'tw-h-full tw-w-full',
          'tw-bg-[#FFD68C] tw-text-[#1A1A1A]',
          'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
          'tw-pb-[100px]'
        ]"
      >
        <img
          class="tw-w-[200px] tw-mt-[-120px]"
          :src="$cdn('images/cny2025/pineapple-tart.png')"
        >
        <p
          class="tw-pb-6 tw-text-[#C52600] tw-text-sg-h2-mobile-cny sgTablet:tw-text-sg-h2-tablet-cny sgDesktop:tw-text-sg-h2-cny"
          :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
        >
          {{ $t('cny25_pineapple_tarts') }}
        </p>
        <div class="tw-max-w-[600px]">
          <div
            class="text tw-text-sg-cny25-body"
            :class="{
              'tw-break-keep': checkLang() == 'en',
            }"
            v-html="$t('cny25_pineapple_tarts_description')"
          />
        </div>
      </section>
      
      <!-- Bak Kwa -->
      <section
        id="bak-kwa"
        :class="[
          'tw-relative',
          'tw-h-full tw-w-full',
          'tw-flex tw-flex-col tw-justify-center tw-items-center',
          'tw-bg-[#FFF5E5] tw-text-[#1A1A1A]',
          'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
          'tw-pb-10 sgTablet:tw-pb-[80px]'
        ]"
      >
        <img
          class="tw-w-[200px] tw-h-[200px] tw-mt-[-100px]"
          :src="$cdn('images/cny2024/bakkwa.png')"
        >
        <p
          class="tw-text-[#C52600] tw-pb-6 tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop"
          :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
        >
          {{ $t('cny25_bak_kwa') }}
        </p>
        <div class="text tw-text-sg-cny25-body tw-max-w-[600px]">
          <div v-html="$t('cny25_bak_kwa_description')" />
        </div>
      </section>
    </div>
    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
      :title-style="`${
        checkLang() == 'zh-hans'?
          'tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop tw-font-notosansbold':
          'tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop tw-font-akkuratbold'
      }`"
    />
    <ap-footer />
  </div>
</template>

<script setup lang="ts">

import { onBeforeUnmount, onMounted, ref } from 'vue';
import { checkLang } from '../../support/helper';
import CNY25FloatingDivider from '../../components/cny25/CNY25FloatingDivider.vue';
import { nextTick } from 'process';

const props = defineProps<{
  wordpressData:any
}>();

const imageSourceOpen = ref<boolean>(false);
const imageSourcesData = ref(props.wordpressData.sources);
const menuItems = [
  {
    label: 'cny_yusheng',
    id: 'yu-sheng',
  },
  {
    label: 'cny_mandarin_oranges',
    id: 'mandarin-oranges',
  },
  {
    label: 'cny_pineapple_tarts',
    id: 'pineapple-tarts',
  },
  {
    label: 'cny_bak_kwa',
    id: 'bak-kwa',
  },
];

const handleSupClick = () => {
  imageSourceOpen.value = true;
};

onMounted(() => {
  nextTick(() => {
    addSourcesPanelHandler();
  });
});

onBeforeUnmount(() => {
  removeSourcesPanelHandler();
});

function addSourcesPanelHandler() {
  const supElements = document.querySelectorAll('#sup-handler-container sup');
  if(supElements?.length > 0){
    supElements.forEach((supElement) => {
      supElement.addEventListener('click', handleSupClick);
    });
  }

  const subElements = document.querySelectorAll('#sup-handler-container sub');
  if(subElements?.length > 0){
    subElements.forEach((supElement) => {
      supElement.addEventListener('click', handleSupClick);
    });
  }
}



function removeSourcesPanelHandler() {
  const supElements = document.querySelectorAll('#sup-handler-container sup');
  if(supElements?.length > 0){
    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }

  const subElements = document.querySelectorAll('#sup-handler-container sub');
  if(subElements?.length > 0){
    subElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }
}

</script>

<style scoped>
.header-image {
  width: 100%;
  object-fit: cover;
}

.header-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.yusheng {
  position: relative;
  display: flex;
  height: 200px;
  margin-top: -100px;
}

.text :deep(a) {
  text-decoration: underline;
}

.text :deep(sup) {
  cursor: pointer;
  color: #0366D8;
}
</style>
