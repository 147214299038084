<template>
  <section
    id="cny_family_programmes"
    :class="[
      'tw-px-4 sgTablet:tw-px-20 sgDesktop:tw-px-30',
      'tw-py-5 sgTablet:tw-py-10 sgDesktop:tw-py-[60px]',
    ]"
    style="background: linear-gradient(180deg, #FFF9EE 15.9%, #FFA99F 89.49%);"
  >
    <div class="tw-max-w-[1000px] tw-mx-auto">
      <!-- title -->
      <div
        :class="[
          'tw-text-sg-cny25-h3-mobile sgTablet:tw-text-sg-cny25-h3-tablet sgDesktop:tw-text-sg-cny25-h3-desktop',
          'tw-text-[#C52600]',
          'tw-text-center',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
        :style="{
          letterSpacing:'0.015em'
        }"
      >
        {{ $t('ma_family_fun_section_5_title') }}
      </div>

      <!-- main tab -->
      <div
        :class="[
          'tw-mt-8 sgDesktop:tw-mt-10',
          'tw-flex sgTablet:tw-justify-center',
        ]"
      >

        <!-- right arrow button -->
        <img
          v-if="isProgramStart && showTabNav"
          id="ma-program-tab-left-button"
          :class="[
            'tw-cursor-pointer',
            'tw-py-2 tw-pr-[10px] tw-pl-[6px]',
          ]"
          style="transform: rotate(180deg);"
          :src="$cdn('/icons/cny25/program_more_button.svg')"
          @click="scrollLeft()"
        >

        <!-- tabs -->
        <div
          ref="programTabListRef"
          :class="[
            'tw-flex',
            'tw-gap-x-3 sgDesktop:tw-gap-x-5',
            'tw-overflow-x-auto',
            'scrollbar-hide',
          ]"
        >
          <button
            v-for="item in programme_filter_list"
            :key="item.key"
            :class="[
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
              'tw-flex tw-items-center tw-justify-center tw-flex-shrink-0',
              'tw-text-sg-cny25-bt1 tw-leading-[16px]',
              'tw-bg-[#C5260033] tw-text-[#C52600]',
              'tw-rounded-full tw-text-center tw-cursor-pointer ',
              'tw-py-3 tw-px-6',
              'tw-uppercase',
              {
                '!tw-bg-[#C52600] tw-text-white':item.key==post_filter,
                'hover:tw-bg-[#DA745C] hover:tw-text-white active:tw-bg-[#C52600] active:tw-text-white':item.key!=post_filter,
              }
            ]"
            @click="resetFilter({pagination:true});post_filter=item.key"
          >
            {{ item.label }}
          </button>

        </div>
        <!-- right arrow button -->
        <img
          v-if="isProgramEnd && showTabNav"
          id="ma-program-tab-right-button"
          :class="[
            'tw-cursor-pointer',
            'tw-py-2 tw-pl-[10px] tw-pr-[6px]',
          ]"
          :src="$cdn('/icons/cny25/program_more_button.svg')"
          @click="scrollRight()"
        >
      </div>

      <!-- day filter -->
      <div

        :class="[
          'tw-flex tw-justify-center tw-relative',
          'tw-mt-3 sgTablet2:tw-mt-4 sgDesktop:tw-mt-5'
        ]"
      >
        <div
          id="selectionbox"
          :class="[
            'tw-flex tw-gap-x-5 tw-gap-y-3 tw-flex-wrap tw-justify-center',
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
        >
          <div
            v-for="[rowkey,row] of Object.entries(dates)"
            :key="rowkey"
            :class="[
              'tw-text-center tw-text-[#651300]',
              'tw-pl-4 tw-pr-6 tw-py-[10px]',
              'tw-bg-[#C5260033] tw-bg-opacity-[0.25]',
              'tw-rounded-[22px]',
              'hover: tw-cursor-pointer',
              'tw-border-2 tw-border-[#651300]',
              'tw-text-sg-cny25-bt1',
              {
                '!tw-bg-[#651300] !tw-border-[#651300] !tw-text-white': selectedFilterDate.includes(rowkey),
              }
            ]"
            @click="resetFilter({pagination:true}),toggleSelected(rowkey);"
          >
            <div
              :class="[
                'tw-flex tw-gap-2 tw-items-center',
              ]"
            >
              <img
                v-if="selectedFilterDate.includes(rowkey)"
                class="tw-h-5 tw-w-5 tw-self-center"
                :src="$cdn('icons/cny25/program_filter_check.svg')"
              >
              <img
                v-else
                class="tw-h-5 tw-w-5 tw-self-center"
                :src="$cdn('icons/cny25/program_filter_add.svg')"
              >
              <div class="tw-uppercase">
                {{ checkLang()=='en'? row.filter_en : row.filter_cn }}
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- cards -->
      <div
        v-if="paginated_filtered_prorgammes_list.length > 0"
        class="tw-mt-5 sgTablet:tw-mt-8 sgDesktop:tw-mt-10"
      >
        <div
          :class="[
            'tw-flex tw-flex-wrap tw-justify-center tw-mx-auto',
            'tw-gap-3 sgTablet:tw-gap-4 sgDesktop:tw-gap-5',
          ]"
        >
          <cny-25-family-fun-programme-card
            v-for="row in paginated_filtered_prorgammes_list"
            :key="row.title"
            :event="row"
            @open-modal="openProgrammeModal"
          />

        </div>


      </div>

      <!-- Pagination Part -->
      <div
        v-show="filtered_prorgammes_list.length > 0"
        class="tw-mt-8 sgDesktop:tw-mt-10 tw-flex tw-justify-center"
      >
        <div
          :class="[
            'tw-relative tw-flex tw-gap-3'
          ]"
        >
          <div>
            <button
              class="tw-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-rounded-full"
              :disabled="pagination.currentPage === 1"
              @click="handleClickPagination(1,'prev')"
            >
              <inline-svg
                alt="Previous Page"

                :class="{
                  ' [&>path]:tw-fill-[#C52600] [&>path]:hover:tw-fill-white [&>rect]:tw-stroke-[#C52600] [&>rect]:hover:tw-fill-[#C52600]': pagination.currentPage !== 1,

                } "
                :src="pagination.currentPage === 1 ?
                  $cdn('icons/pagination/keyboard_arrow_left_disable.svg') :
                  $cdn('icons/pagination/keyboard_arrow_left.svg')"
              />
            </button>
          </div>

          <button
            v-for="number in lastPage"
            :key="number"
            :class="{
              'tw-bg-[#C52600] tw-text-white': number === pagination.currentPage,
              'tw-text-[#C52600] hover:tw-border-2 tw-[border-style:inset] tw-border-[#C52600]': number !== pagination.currentPage,
            }"
            class="tw-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-rounded-full"
            @click="handleClickPagination(number, 'page')"
          >
            <span>
              {{ number }}
            </span>
          </button>

          <button
            class="tw-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-rounded-full"
            :disabled="pagination.currentPage === lastPage"
            @click="handleClickPagination(lastPage,'next')"
          >
            <inline-svg
              alt="Next Page"
              :src="pagination.currentPage === lastPage ?
                $cdn('icons/pagination/keyboard_arrow_right_disable.svg') :
                $cdn('icons/pagination/keyboard_arrow_right.svg')"
              :class="{
                ' [&>path]:tw-fill-[#C52600] [&>path]:hover:tw-fill-white [&>rect]:tw-stroke-[#C52600] [&>rect]:hover:tw-fill-[#C52600]': pagination.currentPage !== lastPage,
              } "
            />
          </button>
        </div>
      </div>
    </div>

    <cny-25-family-fun-programme-modal
      :key="modalKey"
      v-model="programmeModal.show"
      :data="programmeModal.data"
    />

  </section>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../../support/helper';
import { computed, ComputedRef, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import { cdn } from '../../../support/cdn';
const { t } = useI18n();

type CNY25FamilyFunProgrammeCategoryType = 'activities' | 'workshops' | 'performances' | 'talks' | 'exhibits';
type CNY25FamilyFunProgrammeCategoryFilterType = 'all' | CNY25FamilyFunProgrammeCategoryType;
type CNY25FamilyFunProgrammeStatusType = 'free_event' | 'paid_event' | 'selling_fast' | 'sold_out' | 'fully_booked';

interface CNY25FamilyFunProgrammePostType {
  title:string,
  description:string,
  category: CNY25FamilyFunProgrammeCategoryType,
  dates:string[],
  date_str:string,
  time:string,
  venue:string,
  paid:boolean,
  status: CNY25FamilyFunProgrammeStatusType,
  image:string,
  external_link?:string,
  is_video_embed:boolean,
  video_link?:string,
  expired_datetime?:string,
}

const modalKey = ref(Date.now());
const programmes_list = ref<CNY25FamilyFunProgrammePostType[]>([]);
const selectedFilterDate = ref<string[]>([]);
const post_filter = ref<CNY25FamilyFunProgrammeCategoryFilterType>('all');
const programTabListRef = ref<HTMLDivElement | null>(null);

const isProgramStart = ref(false);
const isProgramEnd = ref(true);

const lastPage = computed(()=>Math.ceil(filtered_prorgammes_list.value.length / pagination.maxPostPerPage));

const programmeModal = reactive({
  show: false,
  data: undefined
});

const props = defineProps<{
  wordpressData:any
  offsetY?:number
}>();

const pagination = reactive({
  currentPage:1,
  maxPostPerPage:12,
});

const showTabNav = ref(false);

const filtered_prorgammes_list = computed(()=>{
  let tempList = programmes_list.value;
  if(post_filter.value !== 'all'){
    tempList = tempList.filter((item)=>item.category.value === post_filter.value);
  }


  if(selectedFilterDate.value.length !== 0){
    tempList = tempList.filter((item)=>selectedFilterDate.value.some(rowkey=>item.dates.includes(rowkey)));
  }

  return tempList;
});

const paginated_filtered_prorgammes_list = computed(()=>{
  let tempList = filtered_prorgammes_list.value;

  let start = (pagination.currentPage - 1) * pagination.maxPostPerPage;
  let end = pagination.currentPage * pagination.maxPostPerPage;
  tempList = tempList.slice(start,end);

  return tempList;
});

onMounted(() => {
  const element = programTabListRef.value;
  if (element) {
    element.addEventListener('scroll', checkScrollPosition);
    checkScrollPosition(); // Initial check on mount
    checkTabOverflow();
  }
  handleOnResize();
  window.addEventListener('resize', handleOnResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleOnResize);
});

function handleOnResize() {
  checkTabOverflow();
}

function checkTabOverflow() {
  const parent = programTabListRef?.value?.parentNode;
  const tabList = programTabListRef;

  if (parent && tabList) {
    const parentWidth = parent.offsetWidth;
    const tabListWidth = tabList.value.scrollWidth; // Use scrollWidth to account for overflow content

    showTabNav.value = tabListWidth > parentWidth;
  }
}

const openProgrammeModal = (evt:CNY25FamilyFunProgrammePostType)=>{
  programmeModal.data = evt;
  programmeModal.show = true;
  modalKey.value = Date.now();
};

const checkScrollPosition = () => {
  const element = programTabListRef.value;
  if (element) {
    isProgramStart.value = element.scrollLeft > 0;
    isProgramEnd.value = element.scrollLeft + element.clientWidth < (element.scrollWidth - 50);
  }
};

const toggleSelected = (item:string)=>{
  if(selectedFilterDate.value.includes(item)){
    selectedFilterDate.value.splice(selectedFilterDate.value.indexOf(item),1);
  }else{
    selectedFilterDate.value.push(item);
  }
};

const resetFilter = (config:{
  pagination?:boolean,
  filter_date?:boolean,
  filter_category?:boolean
}) =>{
  setTimeout(() => {
    if(config.pagination)
      pagination.currentPage = 1;
    if(config.filter_date){
      selectedFilterDate.value = [];
    }
    if(config.filter_category)
      post_filter.value = 'all';
  },300);
};

const scrollRight = () => {
  const element = programTabListRef.value;
  if (element) {
    element.scrollLeft += 50;
  }
};

const scrollLeft = () => {
  const element = programTabListRef.value;
  if (element) {
    element.scrollLeft -= 50;
  }
};

function handleClickPagination(index:number, event: string){
  if (event === 'prev') {
    pagination.currentPage -= 1;
  } else if (event === 'next') {
    pagination.currentPage += 1;
  } else {
    pagination.currentPage = index;
  }
  handleProgrammeScroll();
}

const handleProgrammeScroll = () => {
  const element = document.getElementById('cny_family_programmes');
  const topNav = document.getElementById('top-nav');
  const topNavHeight = topNav!.getBoundingClientRect().height!;
  const temp = props.offsetY ?? topNavHeight;
  if (element) {
    const elementRect = element.getBoundingClientRect().top;
    const bodyRect = document.body.getBoundingClientRect().top;
    const offset = elementRect - bodyRect - temp;

    window.scrollTo({
      top: offset,
      behavior: 'smooth',
    });
  }

};

const programme_filter_list: ComputedRef<{key:CNY25FamilyFunProgrammeCategoryFilterType,label:string}[]> = computed(()=>[
  {
    key:'all',
    label:t('cny25_family_fun_category_all'),
  },
  {
    key:'activities',
    label:t('cny25_family_fun_category_activities'),
  },
  {
    key:'workshops',
    label:t('cny25_family_fun_category_workshops'),
  },
  {
    key:'performances',
    label:t('cny25_family_fun_category_performances'),
  },
  {
    key:'talks',
    label:t('cny25_family_fun_category_talks'),
  },
  {
    key:'exhibits',
    label:t('cny25_family_fun_category_exhibits'),
  }
]);

const dates = {
  '1': {
    index:1,
    filter_en:'Friday',
    filter_cn:'星期五',
    aag_handle_en: '06 Sept, Friday',
    aag_handle_cn: '06 Sept, 星期五',
    dayVal: '6',
  },
  '2':{
    index:2,
    filter_en:'Saturday',
    filter_cn:'星期六',
    aag_handle_en: '07 Sept, Saturday',
    aag_handle_cn: '07 Sept, 星期六',
    dayVal: '7',
  },
  '3':{
    index:3,
    filter_en:'Sunday',
    filter_cn:'星期天',
    aag_handle_en: '08 Sept, Sunday',
    aag_handle_cn: '08 Sept, 星期天',
    dayVal: '8',
  }
};
const programmesByDates = ref<{[key:string]:CNY25FamilyFunProgrammePostType[]}>({
  '1':[],
  '2':[],
  '3':[]
});

onBeforeMount(()=>{
  if (props.wordpressData?.programme_post) {
    const suffixEN = 'Sep 2024';
    const suffixCN = '年9月';
    const language = checkLang(); // Set to 'en' for English or any other value for Chinese

    for (const item of props.wordpressData.programme_post) {
      if (!item.image) {
        item.image = cdn('images/ma2024/family-fun/card_placeholder.png');
      }
      item.date_str = item.date;
      programmes_list.value.push(item);
    }
  }
});
</script>
