<script lang="ts" setup>
import { checkLang } from '../../support/helper';
import { onMounted } from 'vue';
import { ref } from 'vue';
import { cdn } from '../../support/cdn';
import { computed } from 'vue';
const props = defineProps<{
  wordpressData: any;
}>();
const card_list = ref<
  {
    title: string;
    image: string;
    datetime: string;
    venue: string;
  }[]
>([]);

const social_media_list = ref<{ id: number; name: string; icon: string; href: string }[]>([]);

type Post = {
  title: string;
  description: string;
  category: {
    label: string;
    value: string;
  };
  date: string;
  time: string;
  paid: boolean;
  image: string;
  external_link: string;
};

const programme_post = ref<Post[]>([]);
const downloadables = ref({});

const festivalMap = ref('');
const programmeSchedule = ref('');
const taglineHeader = ref('');
const taglineDescription = ref('');
const showSocial = ref(false);

onMounted(() => {

  if (props.wordpressData?.go_to_competition === 'true') {
    handleScroll('cny_family_competition_menu');
    window.history.replaceState({},'',window.location.origin + window.location.pathname);
  }

  if (props.wordpressData?.programme_post) {
    programme_post.value = props.wordpressData.programme_post.map((item: any) => {
      return {
        ...item,
        image: item.image ? item.image : cdn('images/cny2024/family-fun/programme_placeholder.png'),
      };
    });
  }

  if (props.wordpressData?.downloadables) {
    downloadables.value = props.wordpressData.downloadables;
  }

  if (props.wordpressData?.social_link) {
    const links = Object.values(props.wordpressData.social_link);
    showSocial.value = links.filter((item) => !item).length != links.length;
    social_media_list.value = [
      {
        id: 1,
        name: 'Facebook',
        icon: 'icons/cny25/familyfun/yellow_facebook.svg',
        href: props.wordpressData.social_link?.facebook_link ?? '',
      },
      {
        id: 2,
        name: 'Instagram',
        icon: 'icons/cny25/familyfun/yellow_instagram.svg',
        href: props.wordpressData.social_link?.instagram_link ?? '',
      },
      {
        id: 3,
        name: 'Youtube',
        icon: 'icons/cny25/familyfun/yellow_youtube.svg',
        href: props.wordpressData.social_link?.youtube_link ?? '',
      },
      {
        id: 4,
        name: 'Wechat',
        icon: 'icons/cny25/familyfun/yellow_wechat.svg',
        href: props.wordpressData.social_link?.wechat_link ?? '',
      },
      {
        id: 5,
        name: 'Tiktok',
        icon: 'icons/cny25/familyfun/yellow_tiktok.svg',
        href: props.wordpressData.social_link?.tiktok_link ?? '',
      },
      {
        id: 6,
        name: 'XiaoHongShu',
        icon: 'icons/cny25/familyfun/yellow_xiaohongshu.svg',
        href: props.wordpressData.social_link?.xiaohongshu_link ?? '',
      },
      {
        id: 7,
        name: 'Linkedin',
        icon: 'icons/cny25/familyfun/yellow_linkedin.svg',
        href: props.wordpressData.social_link?.linkedin_link ?? '',
      },
    ].filter((item) => !!item.href);
  }

  if (props.wordpressData?.festival_map_image) {
    festivalMap.value = props.wordpressData.festival_map_image;
  }
  if (props.wordpressData?.programme_image) {
    programmeSchedule.value = props.wordpressData.programme_image;
  }
  if (props.wordpressData?.tagline_header) {
    taglineHeader.value = props.wordpressData.tagline_header;
  }
  if (props.wordpressData?.tagline_description) {
    taglineDescription.value = props.wordpressData.tagline_description;
  }

  if (props.wordpressData?.freebies) {
    card_list.value = props.wordpressData.freebies;
  }
});

const menuItems = computed(() => [
  {
    label: 'cny_family_about_menu',
    id: 'cny_family_about_menu',
  },
  {
    label: 'cny_family_giveaway',
    id: 'cny_family_giveaway',
  },
  {
    label: 'cny_family_programmes',
    id: 'cny_family_programmes',
  },
  {
    label: 'cny_family_free_stickers',
    id: 'cny_family_free_stickers',
  }
]);

const handleScroll = (id: string) => {
  const element = document.getElementById(id);
  const topNav = document.getElementById('top-nav');
  const topNavHeight = topNav!.getBoundingClientRect().height!;
  if (element) {
    const elementRect = element.getBoundingClientRect().top;
    const bodyRect = document.body.getBoundingClientRect().top;
    const offset = elementRect - bodyRect - topNavHeight;

    window.scrollTo({
      top: offset,
      behavior: 'smooth',
    });
  }

};
</script>

<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :cny-25-navigation="true"
      :cny25-p2-navigation="wordpressData?.unlock_sidebar"
    />

    <secondary-navigation :menu-items-data="menuItems" />
    <section
      :class="[
        'tw-relative family-fun-header tw-flex tw-flex-col tw-items-center',
        'tw-h-[661px] sgTablet2:tw-h-[1072px] sgDesktop:tw-h-[1244px] sgDesktop2:tw-h-[902px]',
      ]"
      :style="{
        backgroundImage: `url(${$cdn('images/cny2025/family_fun/banner/cny25-header-banner.jpg')}), linear-gradient(#FF9300, #FF9300)`,
        backgroundPosition: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover, cover',
      }"
    >
      <img
        :src="$cdn('images/cny2025/page_breaks/cny25-home-page-break-1.png')"
        :class="[
          'tw-w-full tw-absolute -tw-bottom-[1px]',
          'tw-h-[28px] sgTablet:tw-h-[104px]',
          'tw-z-[1]'
        ]"
      >
      <div class="tw-absolute tw-inset-0 tw-top-[38px] sgTablet:tw-top-[118px] sgDesktop:tw-top-[66px] tw-z-[1]">
        <div class="tw-text-white tw-text-center">
          <div
            :class="[
              'tw-text-sg-cny25-mobile-h1 sgTablet:tw-text-sg-cny25-tablet-h1 sgDesktop:tw-text-sg-cny25-desktop-h1',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('cny25_family_fun_title') }}
          </div>
        </div>
      </div>

    </section>

    <section
      id="cny_family_about_menu"
      :class="[
        'tw-relative',
        'tw-pb-5 sgTablet:tw-pb-10 tw-pt-10',
        'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
        'tw-flex tw-flex-col tw-justify-center tw-items-center',
      ]"
      style="background: linear-gradient(0deg, #FFF9EE 20%, #FFD68C 100%);"
    >
      <div
        :class="[
          'tw-w-full tw-max-w-[500px] sgTablet:tw-max-w-[800px]',
          'tw-pb-4 tw-text-[#C52600]',
          'tw-text-sg-cny25-h3-mobile sgTablet:tw-text-sg-cny25-h3-tablet sgDesktop:tw-text-sg-cny25-h3-desktop tw-text-center',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
        v-html="$t('cny_family_fun_title_1')"
      />
      <div
        id="sup-handler-container"
        class="
          about-header-description
          tw-max-w-[500px] sgTablet:tw-max-w-[800px]
          tw-w-full
          tw-text-sg-cny25-body
          tw-text-center tw-text-[#651300]
          [&_sup]:tw-cursor-pointer [&_sup]:tw-text-[#0366D8]
        "
        v-html="wordpressData.description"
      />
    </section>

    <section
      id="cny_family_giveaway"
      :class="[
        'tw-flex tw-flex-col tw-justify-center tw-items-center',
        'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
        'tw-bg-[#FFF9EE]'
      ]"
    >
      <div
        :class="[
          'tw-flex tw-flex-col sgTablet2:tw-flex-row tw-justify-center tw-items-center',
          'tw-pb-5',
        ]"
      >
        <img
          class="tw-w-[400px]"
          :src="$cdn('images/cny2025/family_fun/banner/cny25-free-giveaway-banner.v2.png')"
          draggable="false"
        >
        <div
          :class="[
            'tw-flex tw-flex-col tw-justify-center',
            'tw-items-center sgTablet2:tw-items-start',
            'tw-w-full sgTablet2:tw-w-[400px]',
            'sgTablet2:tw-pl-5'
          ]"
        >
          <div
            :class="[
              'tw-text-sg-cny25-h3-mobile sgTablet:tw-text-sg-cny25-h3-tablet sgDesktop:tw-text-sg-cny25-h3-desktop tw-text-[#C52600]',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold !tw-leading-[60px]' : checkLang() != 'en' },
            ]"
          >
            {{ $t('cny25_family_fun_free_giveaway') }}
          </div>
          <div
            :class="[
              'tw-text-sg-cny25-sh1 tw-text-[#651300] tw-text-center',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold !tw-leading-[36px] tw-text-nowrap' : checkLang() != 'en' },
            ]"
          >
            {{ $t('cny25_family_fun_free_giveaway_description') }}
          </div>
          <div
            :class="[
              'tw-text-sg-cny25-sh3 tw-text-[#651300]',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold !tw-leading-[24px] tw-text-nowrap' : checkLang() != 'en' },
            ]"
          >
            {{ $t('cny25_family_fun_free_giveaway_date') }}
          </div>
          <div
            :class="[
              'tw-text-sg-cny25-b3 tw-text-[#651300]',
              { '!tw-leading-[20px]' : checkLang() != 'en' },
            ]"
          >
            {{ checkLang() == 'zh-hans' ? '到新加坡华族文化中心接待处领取' : 'Redeem @ SCCC Reception' }}
          </div>
          <div
            :class="[
              'tw-text-sg-cny25-c1 tw-text-[#651300]',
              'tw-pt-3',
              { '!tw-leading-[20px]' : checkLang() != 'en' },
            ]"
          >
            {{ $t('cny25_family_fun_free_giveaway_tnc_apply') }}
          </div>
          <div
            :class="[
              'tw-text-sg-cny25-c1 tw-text-[#651300]',
              { '!tw-leading-[20px]' : checkLang() != 'en' },
            ]"
          >
            {{ $t('cny25_family_fun_free_giveaway_limited_redemption') }}
          </div>
        </div>
      </div>
      <div
        v-if="social_media_list.length > 0"
        :class="[
          'tw-flex tw-flex-wrap tw-gap-x-5 sgTablet2:tw-gap-x-10 tw-gap-y-4 tw-justify-center'
        ]"
      >
        <a
          v-for="item in social_media_list"
          :key="item.id"
          :href="item.href"
          target="_blank"
          rel="noopener"
        >
          <img
            :src="$cdn(item.icon)"
            :alt="item.name"
          >
        </a>

      </div>
      <div
        :class="[
          'tw-text-sg-cny25-h4-mobile sgTablet:tw-text-sg-cny25-h4-tablet sgDesktop:tw-text-sg-cny25-h4-desktop tw-text-[#651300]',
          'tw-pt-5 tw-pb-5 tw-max-w-[600px] tw-text-center',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
        v-html="$t('cny25_family_fun_stay_tuned')"
      />
    </section>

    <cny-25-family-fun-programmes :wordpress-data="wordpressData" />

    <section
      :class="[
        'tw-hidden',
        'tw-flex tw-flex-col tw-justify-center tw-items-center',
        'tw-px-[80px] sgDesktop:tw-px-[120px]',
        'tw-py-10',
      ]"
    >
      <div
        :class="[
          'tw-text-sg-cny25-sh2 tw-text-center tw-text-[#C52600] tw-uppercase tw-pb-5',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
      >
        {{ $t('cny_official_community_partners') }}
      </div>
      <div :class="['tw-flex tw-flex-row tw-justify-center tw-gap-x-10 tw-gap-y-4', 'tw-flex-wrap']">
        <img
          class="tw-w-[100px]"
          :src="$cdn('images/cny2025/family_fun/partners/ntuc.png')"
          draggable="false"
        >
        <img
          class="tw-w-[100px]"
          :src="$cdn('images/cny2025/family_fun/partners/safra.png')"
          draggable="false"
        >
        <img
          class="tw-w-[100px]"
          :src="$cdn('images/cny2025/family_fun/partners/hometeamns.png')"
          draggable="false"
        >
        <img
          class="tw-w-[100px]"
          :src="$cdn('images/cny2025/family_fun/partners/merewards.png')"
          draggable="false"
        >
      </div>
    </section>
    <cny-25-sticker-section
      id="cny_family_free_stickers"
      :data="wordpressData.downloadables"
    />
    <ap-footer />
  </div>
</template>
