<template>

  <div>
    <!-- Drawer backdrop -->
    <div
      :class="{'tw-fixed tw-z-[999] tw-left-0 tw-top-0 tw-w-full tw-h-[100vh] tw-bg-gray-900 tw-bg-opacity-50': cnyDrawerOpen || isDrawerOpen, 'tw-font-akkuratbold': checkLang() == 'en', 'tw-font-notosansbold': checkLang() == 'zh-hans'}"
      @click.self="handleBackdropClose"
    />
    <nav
      v-if="!props.isHidden"
      id="top-nav"
      class="tw-fixed tw-top-0 tw-flex tw-flex-row tw-items-center tw-justify-between tw-w-full tw-bg-ap-white tw-z-[99999] tw-h-[52px] sgDesktop:tw-h-[68px]"
      style="box-shadow: 0px 4px 8px 0px #0000000F, 0px 0px 4px 0px #0000000A;"
      :class="{'!tw-bg-transparent !tw-shadow-none': !isScrolled && !isDrawerOpen && !!isTransparent }"
      @keydown="handleKeyDown"
    >
      <!-- For now the wordpress covered, when in not debug not covering -->
      <!-- Drawer Icon -->
      <div
        class="tw-w-20 tw-ml-4 sgTablet:tw-ml-10 sgDesktop:tw-ml-20 tw-relative tw-h-full "
      >
        <button
          class="tw-absolute tw-top-1/2 tw-left-0 -tw-translate-y-1/2 tw-opacity-100 tw-transition-opacity tw-duration-300 tw-w-full"
          :class="{'!tw-opacity-0': isDrawerOpen}"
          @click="toggleDrawer"
        >
          <img
            v-if="!isScrolled && !isDrawerOpen && !!isTransparent"
            :src="$cdn('icons/menu_2.svg')"
            alt="Menu"
            class="tw-h-9 tw-w-9 tw-p-2"
          >
          <img
            v-else
            :src="$cdn('icons/purple_menu_icon_2.svg')"
            alt="Menu"
            class="tw-h-9 tw-w-9 tw-p-2"
          >
        </button>
        <button
          class="tw-absolute tw-top-1/2 tw-left-0 -tw-translate-y-1/2 tw-opacity-0 tw-transition-opacity tw-duration-300 tw-w-full
          tw-flex tw-items-center"
          :class="{'!tw-opacity-100': isDrawerOpen}"
          @click="toggleDrawer"
        >
          <div>
            <inline-svg
              :src="$cdn('icons/x_icon_2.svg')"
              class="tw-fill-sg-sccc1 tw-w-9 tw-h-9 tw-p-2 tw-cursor-pointer"
              alt="Arrow Right"
            />
          </div>
          <div class="tw-uppercase tw-text-[12px] tw-leading-[20px] tw-text-sg-sccc1">
            {{ $t('modal_close') }}
          </div>
        </button>
      </div>


      <!-- Logo (Center) -->
      <div
        class="tw-flex-grow tw-text-center tw-cursor-pointer top-nav-logo-animation-container"
        :style="{
          '--top-nav-logo-animation-direction':1,
        }"
        @click="goToHomePage"
      >
        <Transition

          name="top-nav-logo-animation"
        >
          <img
            v-if="showLogoMark"
            key="logomark"
            :src="!isScrolled && !isDrawerOpen && !!isTransparent ? $cdn('images/logomark_white.png') : $cdn('images/logomark_colour.png')"
            alt="Logo"
            class=" tw-h-9 sgDesktop:tw-h-12  tw-inline-block  tw-[92px] sgDesktop:tw-w-[123px]"
          >
          <img
            v-else
            key="logo"
            :src="!isScrolled && !isDrawerOpen && !!isTransparent ? $cdn('images/logo_white.png') : $cdn('images/logo_colour.png')"
            alt="Logo"
            class=" tw-h-9 sgDesktop:tw-h-12  tw-inline-block  tw-[92px] sgDesktop:tw-w-[123px]"
          >
        </Transition>
      </div>

      <!-- Placeholder for balancing the layout -->
      <div class="tw-w-20 tw-mr-4 sgTablet:tw-mr-10 sgDesktop:tw-mr-20 tw-opacity-0">
        <div

          class="tw-h-9 tw-w-9 sgDesktop:tw-h-12 sgDesktop:tw-w-12"
        />
      </div>


    </nav>

    <div
      v-if="!isHidden && !isTransparent && !disableTopPadding"
      class="tw-h-[52px] sgDesktop:tw-h-[68px]"
    />
    <!-- Drawer -->
    <ap-drawer
      :is-drawer-open="isDrawerOpen"
      :page-url="pageUrl"
      @toggle-drawer="toggleDrawer"
    />

    <!-- Chinese New Year -->
    <primary-navigation
      v-if="cnyNavigation"
      v-model="cnyDrawerOpen"
      class="tw-z-[9999]"
    />

    <cny-25-primary-navigation
      v-if="cny25Navigation"
      v-model="cnyDrawerOpen"
      :cny-p2-navigation="cny25P2Navigation"
      class="tw-z-[9999]"
    />

    <qm-primary-navigation
      v-if="qmNavigation"
      v-model="cnyDrawerOpen"
      class="tw-z-[9999]"
    />

    <dw-primary-navigation
      v-if="dwNavigation"
      v-model="cnyDrawerOpen"
      class="tw-z-[9999]"
    />

    <zy-primary-navigation
      v-if="zyNavigation"
      v-model="cnyDrawerOpen"
      class="tw-z-[9999]"
    />

    <ma-primary-navigation
      v-if="maNavigation"
      v-model="cnyDrawerOpen"
      :ma2-navigation="ma2Navigation"
      class="tw-z-[9999]"
    />

    <ws-primary-navigation
      v-if="wsNavigation"
      v-model="cnyDrawerOpen"
      :ws-navigation="wsNavigation"
      class="tw-z-[9999]"
    />
  </div>


</template>


<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed, watch} from 'vue';
import { checkLang } from '../../support/helper';
import { langRedirect } from '../../support/langRedirect';
const isDrawerOpen = ref<boolean>(false);
const isScrolled = ref<boolean>(false);
const cnyDrawerOpen = ref<boolean>(false);
const showLogoMark = ref(false);
const singaporenLogoBottom = ref<number | null>(null);
onMounted(() => {
  window.addEventListener('scroll', handleScroll);


  const logoEl = document.querySelector('.big-big-logo');
  if(!!logoEl){
    showLogoMark.value = true;
    singaporenLogoBottom.value = (logoEl.getBoundingClientRect().bottom + window.scrollY) * 0.9;
  }
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

const handleKeyDown = (event: any) => {
  if (event.key === 'Escape') {
    isDrawerOpen.value = false;
    cnyDrawerOpen.value = false;
  }
};
let lastScroll = 0;
const handleScroll = () => {
  isScrolled.value = window.scrollY > 50;

  if(!!singaporenLogoBottom.value){
    if( window.scrollY > singaporenLogoBottom.value)
      showLogoMark.value = false;
    else showLogoMark.value = true;
    const scrollTop = window.scrollY;

    //optional because sentry error
    (document.querySelector('.top-nav-logo-animation-container')as HTMLElement | null)?.style.setProperty('--top-nav-logo-animation-direction',lastScroll > scrollTop ? '-1' : '1' );
    lastScroll = scrollTop;
  };
};

const handleBackdropClose = () => {
  cnyDrawerOpen.value = false;
  isDrawerOpen.value = false;
};

watch(() => cnyDrawerOpen.value, () => {
  if (cnyDrawerOpen.value === true) {
    isDrawerOpen.value = false;
  }
});

// const isBackdropOpen = computed(() => isDrawerOpen.value || cnyBackdropOpen.value);

const props = withDefaults(defineProps<{
  disableTopPadding?: boolean,
  isTransparent: boolean,
  isHidden: boolean,
  pageUrl?: string,
  cnyNavigation?: boolean,
  cny25Navigation?: boolean,
  cny25P2Navigation?: boolean,
  qmNavigation?: boolean,
  dwNavigation?: boolean,
  zyNavigation?: boolean,
  maNavigation?: boolean,
  ma2Navigation?: boolean,
  wsNavigation?: boolean,
}>(), {
  disableTopPadding: false,
  isTransparent: false,
  isHidden: true,
  pageUrl: '',
  cnyNavigation: false,
  cny25Navigation: false,
  cny25P2Navigation: false,
  qmNavigation: false,
  dwNavigation: false,
  zyNavigation: false,
  maNavigation: false,
  ma2Navigation: false,
  wsNavigation: false,
});


const goToHomePage = () => {
  window.location.href = langRedirect('/');
};

const toggleDrawer = () => {
  isDrawerOpen.value = !isDrawerOpen.value;
  cnyDrawerOpen.value = false;
};

</script>
<style lang="scss">
.top-nav-logo-animation-leave-active{
  transition: all 0.3s ease-out;
  position:absolute;
}

.top-nav-logo-animation-enter-active {
  transition: all 0.3s ease-in;
}



.top-nav-logo-animation-enter-from{
  transform: translateY(calc(20px * var(--top-nav-logo-animation-direction)));
  opacity: 0;
}
.top-nav-logo-animation-leave-to{
  transform: translateY(calc(-20px * var(--top-nav-logo-animation-direction)));
  opacity: 0;
}

</style>
