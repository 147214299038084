import he from 'he';

export const getScreenSize = () => {
  const width = window.innerWidth;
  if (width < 480) {
    return 'mobile';
  } else if (width >= 480 && width <= 768) {
    return 'tablet';
  } else if (width > 768) {
    return 'desktop';
  }

  return null;
};


export const getSgScreenSize = () => {
  const width = window.innerWidth;
  if (width < 680) {
    return 'mobile';
  } else if (width >= 680 && width <= 1024) {
    return 'tablet';
  } else {
    return 'desktop';
  }

  return null;
};

export const removeHtmlString = (htmlString: string) => {
  const unescapedHtml = he.decode(htmlString);
  const plainText = unescapedHtml.replace(/<[^>]*>/g, '');

  return plainText;
};

export const arraysAreEqual = (array1: Array<any>, array2: Array<any>) =>  {
  return JSON.stringify(array1) === JSON.stringify(array2);
};

//https://stackoverflow.com/a/49448231
 type  BROWSER_ENUM =
  'EDGE' |
  'INTERNET_EXPLORER' |
  'FIRE_FOX' |
  'OPERA' |
  'UC_BROWSER' |
  'SAMSUNG_BROWSER' |
  'CHROME' |
  'SAFARI' |
  'UNKNOWN' ;


const testUserAgent = (regexp: RegExp): boolean => regexp.test(window.navigator.userAgent);

export function detectBrowser(): BROWSER_ENUM {
  switch (true) {
    case testUserAgent(/edg/i): return 'EDGE';
    case testUserAgent(/trident/i): return 'INTERNET_EXPLORER';
    case testUserAgent(/firefox|fxios/i): return 'FIRE_FOX';
    case testUserAgent(/opr\//i): return 'OPERA';
    case testUserAgent(/ucbrowser/i): return 'UC_BROWSER';
    case testUserAgent(/samsungbrowser/i): return 'SAMSUNG_BROWSER';
    case testUserAgent(/chrome|chromium|crios/i): return 'CHROME';
    case testUserAgent(/safari/i): return 'SAFARI';
    default: return 'UNKNOWN';
  }
}


export const checkLang = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const langParam = urlParams.get('lang');

  return langParam == 'zh-hans' ? 'zh-hans' : 'en';
};

/**
 * Converts a file size into a human-readable string.
 * - Below 1 MB: Outputs in KB (e.g., "900 KB").
 * - 1 MB and above: Outputs in MB with one decimal (e.g., "1.2 MB").
 *
 * @param size - The file size in bytes.
 * @returns A formatted string representing the file size.
 */
export function formatFileSize(size: number): string {
  if (size < 1024 * 1024) {
    // Convert to KB and round to the nearest integer
    const kb = Math.round(size / 1024);
    return `${kb} KB`;
  } else {
    // Convert to MB with one decimal precision
    const mb = (size / (1024 * 1024)).toFixed(1);
    return `${mb} MB`;
  }
}

/**
 * Truncates a long filename to a readable format.
 * - Keeps the file extension intact.
 * - Shortens the filename if it exceeds a specified length.
 *
 * @param filename - The full name of the file.
 * @param maxLength - The maximum allowed length for the filename (default: 20).
 * @returns A truncated filename with the extension intact.
 */
export function truncateFilename(filename: string, maxLength: number = 20): string {
  const extension = filename.slice(filename.lastIndexOf('.')); // Extract file extension
  const name = filename.slice(0, filename.lastIndexOf('.')); // Extract name without extension

  if (filename.length <= maxLength) {
    return filename; // Return as is if it fits within maxLength
  }

  const truncatedName = name.slice(0, maxLength - extension.length - 3); // Reserve space for "..."
  return `${truncatedName}...${extension}`;
}

/**
 * Checks if the file size is within the allowed limit.
 *
 * @param file - The file object to check.
 * @param maxSizeInMB - The maximum allowed file size in megabytes (default: 10 MB).
 * @returns `true` if the file size is within the limit, otherwise `false`.
 */
export function isFileSizeValid(file: File, maxSizeInMB: number = 10): boolean {
  const maxSizeInBytes = maxSizeInMB * 1024 * 1024; // Convert MB to bytes
  return file.size <= maxSizeInBytes;
}
