<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :cny-25-navigation="true"
      :cny25-p2-navigation="wordpressData?.unlock_sidebar"
    />

    <secondary-navigation
      :menu-items-data="menuItems"
      :do-emit="true"
      @select-story="selectStory"
    />
    <!-- Header -->
    <cny-25-header-banner
      :title="'cny_origin_stories'"
    />

    <div
      :class="[
        'tw-flex tw-flex-col tw-justify-center tw-items-center',
        'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
        'tw-pb-5 tw-pt-5 sgTablet:tw-pt-10'
      ]"
    >
      <div
        class="text sg-cny25-body tw-max-w-[600px] tw-flex tw-flex-col tw-gap-6"
        v-html="props.wordpressData.description"
      />
    </div>

    <!-- Selection -->
    <cny-25-origin-card-section @select-story="selectStory" />

    <CNY25FloatingDivider custom-class="!tw-translate-y-0" />
    <div>
      <cny-25-origin-story-section
        id="storySection"
        ref="storySection"
        @open-image-source-modal="handleOpenImageSourceModal"
      />
    </div>

    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
      :title-style="`${
        checkLang() == 'zh-hans'?
          'tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop tw-font-notosansbold':
          'tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop tw-font-akkuratbold'
      }`"
    />
    <ap-footer />
  </div>
</template>
<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { nextTick } from 'vue';
import { checkLang } from '../../support/helper';
import CNY25FloatingDivider from '../../components/cny25/CNY25FloatingDivider.vue';

const props = defineProps<{
  wordpressData: any;
}>();

let selectedId = ref(1);
const storySection = ref();

const imageSourceOpen = ref<boolean>(false);
const imageSourcesData = ref(props.wordpressData.sources);

onMounted(() => {
  addSourcesPanelHandler();
});

onBeforeUnmount(() => {
  removeSourcesPanelHandler();
});

const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = true;
};

function addSourcesPanelHandler() {
  removeSourcesPanelHandler();
  const supElements = document.querySelectorAll('#sup-handler-container sup');
  if(supElements?.length > 0){
    supElements.forEach((supElement) => {
      supElement.addEventListener('click', handleOpenImageSourceModal);
    });
  }
}

function removeSourcesPanelHandler() {
  const supElements = document.querySelectorAll('#sup-handler-container sup');
  if(supElements?.length > 0){
    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleOpenImageSourceModal);
    });
  }
}

const selectStory = async (id: number) => {
  selectedId.value = id;

  // Ensure storySection is properly bound and initialized
  if (storySection.value) {
    await storySection.value.getStoryData(selectedId.value);
  }

  // Use nextTick to ensure DOM updates are complete before scrolling
  await nextTick();

  const section = document.getElementById('storySection');

  if (section) {
    const headerOffset = 50; // Adjust this value to scroll slightly higher
    const elementPosition = section.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;

    // Perform the scroll to the calculated position
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};
const menuItems = [
  {
    label: 'cny25_the_legend_nian_selection',
    id: '1',
  },
  {
    label: 'cny25_origin_stories_zodiac_race_selection',
    id: '2',
  },
  {
    label: 'cny25_bai_tian_gong_custom_selection',
    id: '3',
  },
  {
    label: 'cny25_the_origins_of_ren_ri_selection',
    id: '4',
  },
];
</script>

<style scoped>
.text :deep(a) {
  text-decoration: underline;
}
</style>
