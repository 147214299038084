<template>
  <div
    :id="customId"
    :class="['tw-relative tw-flex tw-flex-col tw-justify-between tw-items-center', sectionBgColour]"
  >
    <div class="tw-pt-5 sgTablet:tw-pt-10 tw-text-center">
      <div
        class="tw-font-normal tw-text-sg-h3-mobile-cny-title sgTablet:tw-text-sg-h3-tablet-cny-title sgDesktop:tw-text-sg-h3-cny-title tw-uppercase tw-m-auto tw-text-[#C52600]"
        :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
      >
        {{ sectionTitle }}
      </div>
      <div
        class="tw-text-sg-sh3-mobile-cny sgTablet:tw-text-sg-sh3-tablet-cny sgDesktop:tw-text-sg-sh3-cny tw-pt-2 tw-text-[#C52600]"
        :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
      >
        {{ sectionDesc }}
      </div>
    </div>

    <div class="tw-max-w-[375px] sgTablet:tw-max-w-[600px] tw-w-full tw-mx-auto tw-h-auto sgTablet:tw-h-[600px]">
      <swiper
        id="cnySwiper"
        :effect="'coverflow'"
        :grab-cursor="true"
        :centered-slides="true"
        :slides-per-view="1.44"
        :breakpoints="{
          680: {
            slidesPerView: 2,
            coverflowEffect: {
              rotate: 0,
              stretch: 150,
              depth: 300,
              modifier: 1, // 2,3
              scale: 1,
              slideShadows: false,
            },
          },
        }"
        :auto-height="true"
        :coverflow-effect="{
          rotate: 0,
          stretch: 145,
          depth: 73,
          modifier: 1, // 2,3
          scale: 1,
          slideShadows: false,
        }"
        :class="swiperClassName"
        :loop="true"
        @swiper="onSwiper"
        @touch-end="onTouchEnd"
        @touch-start="onTouchStart"
      >
        <swiper-slide
          v-for="(item, index) in datas"
          :key="index"
        >
          <div class="tw-flex tw-items-center tw-justify-center tw-h-full">
            <div :class="['tw-border-2 tw-rounded-lg', swiperStyle]">
              <div class="slideContentWrapper">
                <div
                  class="tw-h-[200px] tw-rounded-t-md tw-bg-center tw-bg-contain tw-bg-no-repeat"
                  :style="{ backgroundImage: `url(${$cdn(item.image_url)})` }"
                />
                <div class="tw-py-5 tw-px-4 tw-flex tw-flex-wrap tw-group tw-cursor-pointer">
                  <p
                    class="slideTitle tw-text-sg-sh2-mobile-cny sgTablet:tw-text-sg-sh2-tablet-cny sgDesktop:tw-text-sg-sh2-cny"
                    :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                    v-html="item.title"
                  />
                  <p
                    class="slideDesc tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 tw-pt-5"
                    v-html="item.description"
                  />
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="tw-flex tw-flex-wrap tw-pb-5 sgTablet:tw-pb-10 tw-justify-center">
      <div class="tw-hidden sgTablet:tw-flex sgTablet:tw-flex-wrap">
        <button
          class="tw-rounded-full tw-h-[56px] tw-w-[56px] tw-mx-5 tw-text-white tw-bg-[#C52600] tw-text-[35px] tw-pb-2 tw-pr-1"
          @click="slidePrev()"
        >
          &lt;
        </button>
        <button
          class="tw-rounded-full tw-h-[56px] tw-w-[56px] tw-mx-5 tw-text-white tw-bg-[#C52600] tw-text-[35px] tw-pb-2 tw-pl-1"
          @click="slideNext()"
        >
          >
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectCoverflow } from 'swiper/modules';
import { useI18n } from 'vue-i18n';
import SwiperCore from 'swiper';

SwiperCore.use([EffectCoverflow]);
import { onMounted, toRefs, ref, Ref } from 'vue';
import { checkLang } from '../../support/helper';
const { t } = useI18n();
const props = defineProps({
  swiperType: {
    type: String,
    default: 'doThese',
  },
  customId: {
    type: String,
    default: '',
  },
});

const { swiperType } = toRefs(props);
let sectionTitle: Ref<string> = ref('');
let sectionDesc: Ref<string> = ref('');
let swiperStyle: Ref<string> = ref('');
let swiperClassName: Ref<string> = ref(swiperType.value + 'Swiper');
let sectionBgColour: Ref<string> = ref('');

let swiperElm = ref<SwiperCore | null>(null);
const tempIndex = ref(0);

const datas: Ref<Array<any>> = ref([]);

onMounted(() => {
  getSwiperStyle();
  if (props.swiperType === 'do-these') {
    datas.value = doTheseData;
  } else if (props.swiperType === 'taboos') {
    datas.value = taboosData;
  } else if (props.swiperType === 'do-and-dont') {
    datas.value = doAndDontData;
  }
});

function onSwiper(swiper: SwiperCore) {
  //   console.log(swiper);
  swiperElm.value = swiper;
}

let timeout = ref<NodeJS.Timeout | null>(null);
function onTouchEnd(swiper: SwiperCore) {
  if (datas.value.length < 5) return;
  if (timeout.value) {
    clearTimeout(timeout.value);
  }
  if (swiper.realIndex == tempIndex.value) return;
  timeout.value = setTimeout(() => {
    let activeIndex = swiper.realIndex;
    swiper.slidePrev(0, false);
    swiper.slidePrev(0, false);
    swiper.slideToLoop(activeIndex, 0, false);
    swiper.slideNext(0, false);
    swiper.slideNext(0, false);
    swiper.slideToLoop(activeIndex, 0, false);
  }, 350);
}

function onTouchStart(swiper: SwiperCore) {
  if (timeout.value) {
    clearTimeout(timeout.value);
  }
  tempIndex.value = swiper.realIndex;
}

function getSwiperStyle() {
  if (swiperType.value == 'do-these') {
    sectionTitle.value = t('cny25_good_luck_do_these');
    sectionDesc.value = t('cny25_good_luck_do_these_subtitle');
    swiperStyle.value = 'tw-border-[#CC8C18] tw-bg-[#FFF7EA]';
    sectionBgColour.value = 'tw-bg-[#FFD68C] tw-h-[716px] sgTablet:tw-h-[804px] sgDesktop:tw-h-[856px] tw-pb-5 sgTablet:tw-pb-0';
  } else if (swiperType.value == 'taboos') {
    sectionTitle.value = t('cny25_good_luck_taboos');
    sectionDesc.value = t('cny25_good_luck_taboos_subtitle');
    swiperStyle.value = 'tw-border-[#BF374C] tw-bg-[#F7A0AD]';
    sectionBgColour.value = 'tw-bg-[#FFF9EE] tw-h-[716px] sgTablet:tw-h-[804px] sgDesktop:tw-h-[856px] tw-pb-5 sgTablet:tw-pb-0';
  } else if (swiperType.value == 'do-and-dont') {
    sectionTitle.value = t('cny25_good_luck_do_donts');
    sectionDesc.value = t('cny25_good_luck_do_donts_subtitle');
    swiperStyle.value = 'tw-border-[#CC8C18] tw-bg-[#F6F5E9]';
    sectionBgColour.value = 'tw-bg-[#FFD68C] tw-h-[760px] sgTablet:tw-h-[864px] sgDesktop:tw-h-[916px] tw-pb-5 sgTablet:tw-pb-0';
  }
}

function slideNext() {
  if (swiperElm.value) {
    swiperElm.value.slideNext(0, false);
    swiperElm.value.slideNext(0, false);
    swiperElm.value.slidePrev(0, false);
    swiperElm.value.slidePrev(0, false);
    swiperElm.value.slideNext();
  }
}

function slidePrev() {
  if (swiperElm.value) {
    swiperElm.value.slidePrev(0, false);
    swiperElm.value.slidePrev(0, false);
    swiperElm.value.slideNext(0, false);
    swiperElm.value.slideNext(0, false);
    swiperElm.value.slidePrev();
  }
}

const doTheseData = [
  {
    id: 1,
    title: t('cny25_good_luck_do_these_1_title'),
    description: t('cny25_good_luck_do_these_1_desc'),
    image_url: '/images/cny2024/good-luck-guides/do-these/stay-up-late.png',
  },
  {
    id: 2,
    title: t('cny25_good_luck_do_these_2_title'),
    description: t('cny25_good_luck_do_these_2_desc'),
    image_url: '/images/cny2024/good-luck-guides/do-these/decorate-the-house.png',
  },
  {
    id: 3,
    title: t('cny25_good_luck_do_these_3_title'),
    description: t('cny25_good_luck_do_these_3_desc'),
    image_url: '/images/cny2024/good-luck-guides/do-these/purchase-festive-goods.png',
  },
  {
    id: 4,
    title: t('cny25_good_luck_do_these_4_title'),
    description: t('cny25_good_luck_do_these_4_desc'),
    image_url: '/images/cny2024/good-luck-guides/do-these/purchase-new-clothes.png',
  },
  {
    id: 5,
    title: t('cny25_good_luck_do_these_5_title'),
    description: t('cny25_good_luck_do_these_5_desc'),
    image_url: '/images/cny2024/good-luck-guides/do-these/spring-cleaning.png',
  },
  {
    id: 6,
    title: t('cny25_good_luck_do_these_6_title'),
    description: t('cny25_good_luck_do_these_6_desc'),
    image_url: '/images/cny2024/good-luck-guides/do-these/settle-all-debts.png',
  },
  {
    id: 7,
    title: t('cny25_good_luck_do_these_7_title'),
    description: t('cny25_good_luck_do_these_7_desc'),
    image_url: '/images/cny2024/good-luck-guides/do-these/fill-the-rice-jar.png',
  },
];

const taboosData = [
  {
    id: 1,
    title: t('cny25_good_luck_taboos_1_title'),
    description: t('cny25_good_luck_taboos_1_desc'),
    image_url: '/images/cny2024/good-luck-guides/taboos/eating-porridge.png',
  },
  {
    id: 2,
    title: t('cny25_good_luck_taboos_2_title'),
    description: t('cny25_good_luck_taboos_2_desc'),
    image_url: '/images/cny2024/good-luck-guides/taboos/washing-hair.png',
  },
  {
    id: 3,
    title: t('cny25_good_luck_taboos_3_title'),
    description: t('cny25_good_luck_taboos_3_desc'),
    image_url: '/images/cny2024/good-luck-guides/taboos/cutting-needlework.png',
  },
  {
    id: 4,
    title: t('cny25_good_luck_taboos_4_title'),
    description: t('cny25_good_luck_taboos_4_desc'),
    image_url: '/images/cny2024/good-luck-guides/taboos/saying-unlucky-words.png',
  },
  {
    id: 5,
    title: t('cny25_good_luck_taboos_5_title'),
    description: t('cny25_good_luck_taboos_5_desc'),
    image_url: '/images/cny2024/good-luck-guides/taboos/sweeping-of-floor.png',
  },
  {
    id: 6,
    title: t('cny25_good_luck_taboos_6_title'),
    description: t('cny25_good_luck_taboos_6_desc'),
    image_url: '/images/cny2024/good-luck-guides/taboos/waking-sleeping-person.png',
  },
  {
    id: 7,
    title: t('cny25_good_luck_taboos_7_title'),
    description: t('cny25_good_luck_taboos_7_desc'),
    image_url: '/images/cny2024/good-luck-guides/taboos/taking-afternoon-naps.png',
  },
];

const doAndDontData = [
  {
    id: 1,
    title: t('cny25_good_luck_do_donts_1_title'),
    description: t('cny25_good_luck_do_donts_1_desc'),
    image_url: '/images/cny2024/good-luck-guides/dos-and-dont/visit-family.png',
  },
  {
    id: 2,
    title: t('cny25_good_luck_do_donts_2_title'),
    description: t('cny25_good_luck_do_donts_2_desc'),
    image_url: '/images/cny2024/good-luck-guides/dos-and-dont/red-packets.png',
  },
  {
    id: 3,
    title: t('cny25_good_luck_do_donts_3_title'),
    description: t('cny25_good_luck_do_donts_3_desc'),
    image_url: '/images/cny2024/good-luck-guides/dos-and-dont/no-breaking.png',
  },
  {
    id: 4,
    title: t('cny25_good_luck_do_donts_4_title'),
    description: t('cny25_good_luck_do_donts_4_desc'),
    image_url: '/images/cny2024/good-luck-guides/dos-and-dont/no-crying.png',
  },
  {
    id: 5,
    title: t('cny25_good_luck_do_donts_5_title'),
    description: t('cny25_good_luck_do_donts_5_desc'),
    image_url: '/images/cny2024/good-luck-guides/dos-and-dont/no-scissors.png',
  },
  {
    id: 6,
    title: t('cny25_good_luck_do_donts_6_title'),
    description: t('cny25_good_luck_do_donts_6_desc'),
    image_url: '/images/cny2024/good-luck-guides/dos-and-dont/no-lending-borrowing.png',
  },
  {
    id: 7,
    title: t('cny25_good_luck_do_donts_7_title'),
    description: t('cny25_good_luck_do_donts_7_desc'),
    image_url: '/images/cny2024/good-luck-guides/dos-and-dont/no-black-white.png',
  },
  {
    id: 8,
    title: t('cny25_good_luck_do_donts_8_title'),
    description: t('cny25_good_luck_do_donts_8_desc'),
    image_url: '/images/cny2024/good-luck-guides/dos-and-dont/avoid-odd-amounts.png',
  },
  {
    id: 9,
    title: t('cny25_good_luck_do_donts_9_title'),
    description: t('cny25_good_luck_do_donts_9_desc'),
    image_url: '/images/cny2024/good-luck-guides/dos-and-dont/no-hospital-visit.png',
  },
  // {
  //   id: 10,
  //   title: t('cny25_good_luck_do_donts_10_title'),
  //   description: t('cny25_good_luck_do_donts_10_desc'),
  //   image_url: '/images/cny2024/good-luck-guides/dos-and-dont/no-going-out.png',
  // },
];
</script>

<style scoped>
div#cnySwiper .slideDesc {
  opacity: 0;
  height: 0;
}
div#cnySwiper .swiper-slide-active .slideDesc {
  opacity: 1;
  height: fit-content;
}

div#cnySwiper .swiper-slide:not(.swiper-slide-active) {
  width: 216px;
  height: -webkit-fill-available;
  max-height: 252px;
}

div#cnySwiper .swiper-slide.swiper-slide-prev,
div#cnySwiper .swiper-slide.swiper-slide-next {
  width: 216px;
  height: 292px !important;
}

div#cnySwiper .swiper-slide.swiper-slide-active {
  height: 620px;
}

div#cnySwiper.swiper-autoheight .swiper-wrapper {
  align-items: center !important;
}

div#cnySwiper .swiper-slide .slideContentWrapper {
  opacity: 0.5;
}

div#cnySwiper .swiper-slide.swiper-slide-active .slideContentWrapper {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

@media (max-width: 680px) {
  div#cnySwiper .swiper-slide {
    width: 160px;
    height: 244px;
  }

  div#cnySwiper .swiper-slide.swiper-slide-prev,
  div#cnySwiper .swiper-slide.swiper-slide-next {
    width: 160px;
    height: 284px !important;
  }

  div#cnySwiper.swiper-3d {
    perspective: 152px;
  }

  div#cnySwiper.do-and-dontSwiper {
    padding: 30px 0;
  }
}
</style>
