<template>
  <div
    :id="customId"
    :class="[
      sectionBgColour,
      {
        'sgTablet:tw-pb-[48px]' : swiperType == 'zodiac',
        'sgTablet:tw-pb-10' : swiperType != 'zodiac',
      }
    ]"
    class="tw-mx-auto tw-pb-8 tw-py-8 sgTablet:tw-py-10 sgDesktop:tw-pb-10 sgTablet:tw-px-10 sgDesktop:tw-px-20"
  >
    <div class="tw-text-center">
      <div
        class="tw-font-bold tw-text-sg-h3-mobile-cny-title sgTablet:tw-text-sg-h3-tablet-cny-title sgDesktop:tw-text-sg-h3-cny-title tw-m-auto tw-text-[#C52600]"
        :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
      >
        {{ sectionTitle }}
      </div>
    </div>

    <!-- Section if swiperType != zodiac -->
    <div
      v-if="swiperType != 'zodiac'"
      :class="[
        'tw-py-8 sgTablet:tw-py-10 sgDesktop:tw-py-[60px]',
      ]"
    >
      <swiper
        :id="swiperClassName"
        :effect="'coverflow'"
        class="sgTablet:tw-w-[510px] sgDesktop:tw-w-[530px] tw-w-full swiper-slide-width-mobile"
        :grab-cursor="true"
        :centered-slides="true"
        :center-insufficient-slides="true"
        :slides-per-view="'auto'"
        :modules="[EffectCoverflow]"
        :coverflow-effect="{
          rotate: 0,
          stretch: 65,
          depth: 350,
          modifier: 1,
          scale: 1,
          slideShadows: false,
        }"
        :breakpoints="{
          680: {
            coverflowEffect: {
              rotate: 0,
              stretch: 80,
              depth: 200,
              modifier: 1,
              scale: 1,
              slideShadows: false,
            },
          },
          1025: {
            coverflowEffect: {
              rotate: 0,
              stretch: 80,
              depth: 200,
              modifier: 1,
              scale: 1,
              slideShadows: false,
            },
          },
        }"
        :loop="true"
        @swiper="onSwiper"
        @touch-start="onTouchStart"
        @touch-end="onTouchEnd"
        @slide-change="onSlideChange"
      >
        <swiper-slide
          v-for="(item, index) in greetingsDatas"
          :id="`swiper-greeting-${index}`"
          :key="index"
          v-slot="{ isActive }"
          class="!tw-w-[152px] sgTablet:!tw-w-[192px] sgDesktop:!tw-w-[212px] !tw-h-[280px] sgTablet:!tw-h-[320px]"
        >
          <div class="tw-flex tw-items-center tw-h-full">
            <div
              class="tw-bg-ap-white tw-border-[#7DBCA4] tw-px-4 tw-py-5 sgTablet:tw-px-5 tw-shadow-md green-shadow tw-border!-2 tw-relative tw-w-[140px] tw-h-auto sgTablet:tw-w-[180px] sgDesktop:tw-w-[200px] sgTablet:!tw-h-237px"
              :style="{
                borderWidth: 'medium',
              }"
            >
              <div class="tw-flex tw-justify-center tw-items-center mobile-height">
                <p
                  class="tw-text-ap-navy-blue tw-text-sg-h1-mobile sgTablet:tw-text-sg-h1-tablet sgDesktop:tw-text-sg-h1 !tw-leading-[52px] sgTablet:!tw-leading-[72px] sgDesktop:!tw-leading-[84px] tw-text-center tw-tracking-[0.48px]"
                  :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                  v-html="item.title"
                />
              </div>
              <p
                v-if="isActive"
                class="tw-text-sg-cny24-red tw-text-sg-sh2-mobile-cny sgTablet:tw-text-sg-sh2-tablet-cny sgDesktop:tw-text-sg-sh2-cny tw-w-full tw-text-center tw-mt-5 tw-min-h-[84px] sgTablet:tw-min-h-[56px]"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                {{ item.pinyin }}
              </p>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <!-- Speaker Icon -->
      <div class="tw-w-[152px] sgTablet:tw-w-[212px] tw-h-[180px] tw-mx-auto">
        <div class="tw-flex tw-items-center tw-h-full">
          <div class="slideDesc tw-flex tw-flex-col tw-w-[140px] sgTablet:tw-w-[200px]">
            <div class="tw-flex tw-justify-center tw-cursor-pointer">
              <div
                class="tw-my-[24px] tw-rounded-full tw-border-2 tw-w-[40px] tw-h-[40px] tw-min-w-[40px] tw-items-center tw-border-sg-cny24-red tw-bg-ap-white hover:tw-bg-sg-cny24-red-w60 tw-flex tw-justify-center tw-flex-row active:tw-bg-sg-cny24-red-w40 tw-cursor-pointer"
              >
                <inline-svg
                  :src="$cdn('images/cny2024/icon/volume_up.svg')"
                  @click="onSpeakerClick(descSpeakerIndex)"
                />
              </div>
            </div>
            <div
              class="tw-text-ap-body tw-text-sm tw-leading-6 tw-text-sg-cny24-red tw-text-center tw-min-h-[72px]"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
            >
              {{ descContent }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="swiperType != 'zodiac'"
      class="tw-flex tw-flex-wrap tw-justify-center"
    >
      <div class="tw-flex sgTablet:tw-flex-wrap">
        <button
          class="tw-rounded-full tw-flex tw-justify-center tw-items-center tw-h-[56px] tw-w-[56px] tw-mx-5 tw-text-white tw-bg-[#C52600] tw-text-[35px]"
          @click="slidePrev()"
        >
          <inline-svg
            class="tw-fill-ap-white tw-h-6 tw-w-6 tw-mr-1"
            :src="$cdn('icons/arrow_chevron_left.svg')"
            alt="Arrow Left"
          />
        </button>
        <div class="tw-px-[60px] tw-items-center tw-flex">
          <p
            class="tw-text-sg-cny24-red tw-text-sg-caption tw-flex-nowrap"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
            :style="{ minWidth: '26px' }"
          >
            {{ `${realIndex} / ${maxItemSize ?? swiperLength}` }}
          </p>
        </div>
        <button
          class="tw-rounded-full tw-flex tw-justify-center tw-mx-5 tw-items-center  tw-h-[56px] tw-w-[56px] tw-text-white tw-bg-[#C52600] tw-text-[35px] disabled:tw-bg-sg-cny24-red-w60"
          @click="slideNext()"
        >
          <inline-svg
            class="tw-fill-ap-white tw-h-6 tw-w-6 tw-ml-1"
            :src="$cdn('icons/arrow_chevron_right.svg')"
            alt="Arrow Left"
          />
        </button>
      </div>
    </div>

    <!-- Section if swiperType == zodiac -->
    <div
      v-if="swiperType == 'zodiac'"
      class="tw-pt-8 sgTablet:tw-pt-10 sgDesktop:tw-pt-[60px] tw-flex tw-flex-row tw-gap-5 sgTablet:tw-gap-10 sgDesktop:tw-gap-12 tw-justify-center"
    >
      <div
        v-for="(item, index) in greetingsDatas"
        :key="index"
        class="tw-w-[152px] sgTablet:tw-w-[192px] sgDesktop:tw-w-[212px]"
      >
        <div class="tw-flex tw-flex-col tw-items-center tw-h-full">
          <div
            class="tw-bg-ap-white tw-border-[#7DBCA4] tw-px-4 tw-py-5 sgTablet:tw-px-5 tw-shadow-md green-shadow tw-border!-2 tw-relative tw-w-[140px] sgTablet:tw-w-[180px] sgDesktop:tw-w-[200px]"
            :style="{
              borderWidth: 'medium',
            }"
          >
            <div class="tw-flex tw-justify-center tw-items-center mobile-height">
              <p
                class="tw-text-ap-navy-blue tw-text-sg-h1-mobile sgTablet:tw-text-sg-h1-tablet sgDesktop:tw-text-sg-h1 !tw-leading-[52px] sgTablet:!tw-leading-[72px] sgDesktop:!tw-leading-[84px] tw-text-center tw-tracking-[0.48px]"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                v-html="item.title"
              />
            </div>
            <p
              class="tw-text-sg-cny24-red tw-text-sg-sh2-mobile-cny sgTablet:tw-text-sg-sh2-tablet-cny sgDesktop:tw-text-sg-sh2-cny tw-w-full tw-text-center tw-mt-5 tw-min-h-[84px] sgTablet:tw-min-h-[56px]"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ item.pinyin }}
            </p>
          </div>
          <!-- Speaker Icon -->
          <div class="tw-w-[152px] sgTablet:tw-w-[212px] tw-h-[180px] tw-mx-auto">
            <div class="tw-flex tw-items-center tw-h-full">
              <div class="slideDesc tw-flex tw-flex-col tw-w-[140px] sgTablet:tw-w-[200px]">
                <div class="tw-flex tw-justify-center tw-cursor-pointer">
                  <div
                    class="tw-my-[24px] tw-rounded-full tw-border-2 tw-w-[40px] tw-h-[40px] tw-min-w-[40px] tw-items-center tw-border-sg-cny24-red tw-bg-ap-white hover:tw-bg-sg-cny24-red-w60 tw-flex tw-justify-center tw-flex-row active:tw-bg-sg-cny24-red-w40 tw-cursor-pointer"
                  >
                    <inline-svg
                      :src="$cdn('images/cny2024/icon/volume_up.svg')"
                      @click="onSpeakerClick(index)"
                    />
                  </div>
                </div>
                <div
                  class="tw-text-ap-body tw-text-sm tw-leading-6 tw-text-sg-cny24-red tw-text-center tw-min-h-[72px]"
                  :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
                >
                  {{ item.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, watch } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectCoverflow } from 'swiper/modules';
import { useI18n } from 'vue-i18n';

import { toRefs, ref, Ref } from 'vue';
import { cdn } from '../../../support/cdn';
import { nextTick } from 'vue';
import { onBeforeMount } from 'vue';
import { checkLang } from '../../../support/helper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';

const { t } = useI18n();
const props = defineProps({
  swiperType: {
    type: String,
    default: 'basic',
  },
  customId: {
    type: String,
    default: '',
  },
  greetingsDatas: {
    type: Array as () => GreetingData[],
    default: () => [],
  },
  maxItemSize: {
    type: Number,
    default: null,
  }
});

const { swiperType } = toRefs(props);
let sectionTitle: Ref<string> = ref('');
let swiperClassName: Ref<string> = ref(swiperType.value + 'Swiper');
let sectionBgColour: Ref<string> = ref('tw-bg-[#F8F7ED]');
let swiperElm = ref<typeof Swiper | null>(null);

const tempIndex = ref(0);
const descSpeakerIndex = ref();
const descContent = ref('');
const audioPlayer = new Audio();

interface GreetingData {
  id: number,
  title: string,
  pinyin: string,
  description: string,
  sound_url: string,
}

onBeforeMount(() => {
  initializeSwiper();
  window.addEventListener('resize', onResize);
  window.addEventListener('load', onLoad);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});

const onResize = () => {
  refreshSwiper();
};

function refreshSwiper() {
  if (swiperElm.value){
    setTimeout(() => {
      transitionSwiperSlide();
    },250);
  }
}

const onSlideChange = (swiper: any) => {
  const index = swiper.realIndex;
  const slideItem = props.greetingsDatas?.[index];

  if (slideItem && slideItem.description){
    descContent.value = slideItem.description;
  }
  descSpeakerIndex.value = index;
  refreshSwiper();
};

function onSwiper(swiper: any) {
  swiperElm.value = swiper;
  if (props.greetingsDatas.length >= 5) {
    nextTick(() => {
      let activeIndex = swiper.realIndex;
      swiper.slidePrev(0, false);
      swiper.slidePrev(0, false);
      swiper.slideToLoop(activeIndex, 0, false);
      swiper.slideNext(0, false);
      swiper.slideNext(0, false);
      swiper.slideToLoop(activeIndex, 0, false);
      onSlideEndChange(swiper);
    });
  }
}

function onLoad() {
  if(swiperElm.value) {
    transitionSwiperSlide();
    watch(descSpeakerIndex,() => {
      onSlideStartChange();
    }
    );
  }
}

let timeout = ref<NodeJS.Timeout | null>(null);
function onTouchEnd(swiper: any) {
  if (props.greetingsDatas.length < 5) return;
  if (timeout.value) {
    clearTimeout(timeout.value);
  }
  timeout.value = setTimeout(() => {
    let activeIndex = swiper.realIndex;
    swiper.slidePrev(0, false);
    swiper.slidePrev(0, false);
    swiper.slideToLoop(activeIndex, 0, false);
    swiper.slideNext(0, false);
    swiper.slideNext(0, false);
    swiper.slideToLoop(activeIndex, 0, false);
    onSlideEndChange(swiper);
  }, 350);

  transitionSwiperSlide();
}

function onTouchStart(swiper: any) {
  if (timeout.value) {
    clearTimeout(timeout.value);
  }
  tempIndex.value = swiper.realIndex;
  onSlideStartChange();
}

function initializeSwiper() {
  if (props.swiperType === 'basic') {
    sectionTitle.value = t('cny_greetings_basic');
  } else if (props.swiperType === 'intermediate') {
    sectionTitle.value = t('cny_greetings_intermediate');
    sectionBgColour.value = 'tw-bg-[#FFF3DE]';
  } else if (props.swiperType === 'advanced') {
    sectionTitle.value = t('cny_greetings_advanced');
    sectionBgColour.value = 'tw-bg-[#FFD68C]';
  } else if (props.swiperType === 'zodiac') {
    sectionTitle.value = t('cny25_greetings_zodiac');
  }
}

function slideNext() {
  if (swiperElm.value) {
    swiperElm.value.slideNext();
    refreshSwiper();
  }
}

function slidePrev() {
  if (swiperElm.value) {
    swiperElm.value.slidePrev();
    refreshSwiper();
  }
}

const onSpeakerClick = (index: number) => {
  audioPlayer.pause();
  const soundUrl = props.greetingsDatas?.[index]?.sound_url;
  audioPlayer.src = cdn(soundUrl);
  if (props.swiperType === 'zodiac') {
    audioPlayer.play();
    audioPlayer.volume = 0.18;
  } else {
    audioPlayer.play();
    audioPlayer.volume = 1;
  }
};

const realIndex = computed(() => {
  if (props.maxItemSize) {
    return (swiperElm.value?.realIndex % props.maxItemSize) + 1;
  }
  return swiperElm.value?.realIndex + 1;
});

const swiperLength = computed(() => {
  return swiperElm.value?.slides?.length ?? 0;
});

const onSlideStartChange = () => {
  transitionSwiperSlide();
};

function transitionSwiperSlide(){
  if (swiperElm.value && swiperElm.value.slides) {
    let activeSlideIndex = swiperElm.value.slides.findIndex((slide: any) => {
      return slide.classList.contains('swiper-slide-active');
    });

    let prevPrevSlideIndex = (activeSlideIndex - 2 + swiperElm.value.slides.length) % swiperElm.value.slides.length;
    let prevSlideIndex = (activeSlideIndex - 1 + swiperElm.value.slides.length) % swiperElm.value.slides.length;
    let nextSlideIndex = (activeSlideIndex + 1) % swiperElm.value.slides.length;
    let nextNextSlideIndex = (activeSlideIndex + 2) % swiperElm.value.slides.length;

    swiperElm.value.slides.forEach((slide: any, index: number) => {
      if (![prevPrevSlideIndex, prevSlideIndex, activeSlideIndex, nextSlideIndex, nextNextSlideIndex].includes(index)) {
        slide.classList.add('swiper-slide-hidden');
      } else {
        slide.classList.remove('swiper-slide-hidden');
      }
    });
  }
}

const onSlideEndChange = (swiper: any) => {
  transitionSwiperSlide();
  if (props.greetingsDatas.length < 5) return;
  let activeIndex = swiper.realIndex;
  swiper.slidePrev(0, false);
  swiper.slidePrev(0, false);
  swiper.slideToLoop(activeIndex, 0, false);
  swiper.slideNext(0, false);
  swiper.slideNext(0, false);
  swiper.slideToLoop(activeIndex, 0, false);
};
</script>

<style scoped>
.swiper-slide-width-mobile {
  width: 530px !important;
}

@media screen and (max-width: 1024px) {
  .swiper-slide-width-mobile {
    width: 510px !important;
  }
}

@media screen and (max-width: 768px) {
  .swiper-slide-width-mobile {
    width: 440px !important;
  }
}

@media screen and (max-width: 425px) {
  .swiper-slide-width-mobile {
    width: 315px !important;
  }
}

.swiper-slide {
  width: 200px !important;
}

@media screen and (max-width: 768px) {
  .swiper-slide {
    width: 180px !important;
  }
}

@media screen and (max-width: 425px) {
  .swiper-slide {
    width: 150px !important;
  }
  .swiper-slide:not(.swiper-slide-active) .mobile-height {
    height: 190px !important;
  }
}

div#cny_greeting_Swiper .slideDesc {
  opacity: 0;
  height: 0;
}
div#cny_greeting_Swiper .swiper-slide-active .slideDesc {
  opacity: 1;
  height: fit-content;
}

div#cny_greeting_Swiper.swiper-autoheight .swiper-wrapper {
  align-items: center !important;
}

.swiper-slide-hidden {
  opacity: 0;
}
.green-shadow {
  box-shadow: 12px 12px 0px 0px #007c4c;
  border-radius: 8px;
}
</style>
