<template>
  <section
    id="cny_calendar_menu"
    :class="[
      'tw-relative',
      'tw-flex tw-flex-col tw-justify-start tw-items-center',
      'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
      'tw-pb-4 sgTablet:tw-pb-[80px]'
    ]"
    :style="{
      background: `linear-gradient(180deg, #FFD68C 0%, #FFF 100%)`
    }"
  >
    <div
      :class="[
        'tw-text-[#C52600]',
        'tw-py-10 tw-text-center',
        'tw-text-sg-ws-h3-mobile sgTablet:tw-text-sg-ws-h3-tablet sgDesktop:tw-text-sg-ws-h3-desktop',
        { 'tw-font-akkuratbold' : checkLang() == 'en' },
        { 'tw-font-notosansbold' : checkLang() != 'en' },
      ]"
    >
      {{ $t('cny_guide_to_fifteen_days') }}
    </div>
    <div
      id="cny-calendar-title-banner"
      :class="[
        'tw-relative tw-flex tw-items-center tw-justify-center',
        'tw-w-full tw-max-w-[900px] tw-h-[64px]',
        'tw-text-sg-ws-h4-mobile sgTablet:tw-text-sg-ws-h4-tablet sgDesktop:tw-text-sg-ws-h4-desktop',
        { 'tw-font-akkuratbold' : checkLang() == 'en' },
        { 'tw-font-notosansbold' : checkLang() != 'en' },
      ]"
    >
      <cny-25-title-banner-svg-box />
      <div
        :class="[
          'tw-absolute tw-left-0 tw-w-full',
          'tw-flex tw-flex-row tw-justify-between tw-items-center',
          'tw-px-4 sgTablet:tw-px-[56px] tw-py-2',
        ]"
      >
        <div class="tw-text-[#007C4C]">
          {{ dateData[2] }}
        </div>
        <div class=" tw-text-[#C52600]">
          {{ dateData[1] }}
        </div>
        <div class="tw-text-[#007C4C] tw-font-notosansbold">
          {{ activeState.chinese_month }}
        </div>
      </div>
    </div>
    <div
      :class="[
        'tw-bg-[#C52600]',
        'tw-w-full tw-max-w-[900px] tw-h-[24px]',
        'tw-rounded-[4px] tw-my-2'
      ]"
    />
    <!-- Calendar Details -->
    <div
      :class="[
        'tw-relative',
        'tw-flex tw-flex-col sgTablet:tw-flex-row ',
        'tw-w-full tw-max-w-[900px] tw-h-full sgTablet:tw-h-[312px] tw-py-5',
        'tw-border-[4px] tw-border-[#007C4C] tw-rounded-[4px]',
        'tw-bg-[#F8F7ED]',
        'tw-gap-10 sgTablet:tw-gap-0',
        'tw-px-4 sgTablet:tw-px-0',
      ]"
    >
      <!-- left side -->
      <div
        :class="[
          'tw-flex tw-flex-col tw-justify-center tw-items-center',
          'tw-w-full sgTablet:tw-max-w-[50%] tw-text-[#007C4C]',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
      >
        <div
          :class="[
            'tw-uppercase',
            'tw-text-sg-ws-h2-mobile sgTablet:tw-text-sg-ws-h2-tablet sgDesktop:tw-text-sg-ws-h2-desktop',
          ]"
        >{{ checkLang() != 'en' ? activeState.chinese_title : (activeState.title ?? 'Day '+(activeState.show_day)) }}</div>
        <div class="calendar-date tw-mr-4">
          {{ dateData[0] }}
        </div>
      </div>
      <!-- right side -->
      <div
        :class="[
          'sgTablet:tw-absolute sgTablet:tw-right-0 sgTablet:tw-w-[50%]',
        ]"
      >
        <simplebar
          :auto-hide="false"
          :class="[
            'tw-pr-[32px] sgTablet:tw-pr-[56px] tw-h-[200px]',
            'tw-flex tw-flex-col',
            'tw-gap-5',
          ]"
        >
          <div
            id="sup-handler-container"
            :class="['custom-content tw-flex-grow tw-overflow-auto']"
            v-html="contentLeft"
          />
        </simplebar>
        <div
          :class="[
            'tw-flex tw-justify-center',
            'tw-pt-5',
            { 'tw-hidden' : !dataRef.external_link_left },
          ]"
        >
          <a
            :href="dataRef.external_link_left"
            target="_blank"
            rel="noopener"
            :class="[
              'tw-text-white tw-bg-[#C52600]',
              'tw-px-6 tw-py-4',
              'tw-text-sg-ma-bt1',
              'tw-rounded-[26px] !tw-leading-[20px] tw-h-[52px] tw-uppercase',
              'hover:tw-bg-[#D15133] active:tw-bg-[#9E1E00]',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('cny_find_out_more') }}
          </a>
        </div>
      </div>
    </div>

    <!-- Swiper -->
    <div class="tw-flex tw-flex-row tw-max-w-[900px] tw-w-full tw-py-2">
      <!-- button left -->
      <div
        :class="[
          'tw-hidden sgTablet:tw-flex',
          'tw-bg-[#339670]',
          'tw-py-8 tw-px-3 tw-rounded-[4px]',
          { 'tw-opacity-[0.25]' : !leftButtonEnabled }
        ]"
        :style="{
          boxShadow: leftButtonEnabled ? `-4px 0px 10px 0px rgba(0, 0, 0, 0.30)` : ''
        }"
      >
        <button @click="onClickPrev">
          <inline-svg
            class="tw-fill-ap-white tw-h-6 tw-w-6"
            :src="$cdn('icons/arrow_chevron_left.svg')"
            alt="Arrow Left"
          />
        </button>
      </div>

      <swiper
        :space-between="4"
        :slides-per-view="'auto'"
        class="calendarSwiper tw-h-[144px]"
        @progress="onScroll"
        @swiper="onSwiper"
      >
        <swiper-slide
          v-for="(item, index) in calendars"
          :key="`calendar_${index}`"
          :style="{
            width: '160px',
          }"
          @click="onSelectDate(index)"
        >
          <cny-25-calendar-block
            :item="item"
            :is-selected="index === currentSelectedIndex"
          />
        </swiper-slide>
      </swiper>

      <!-- button right -->
      <div
        :class="[
          'tw-hidden sgTablet:tw-flex',
          'tw-bg-[#339670]',
          'tw-py-4 tw-px-3 tw-rounded-[4px]',
          { 'tw-opacity-[0.25]' : !rightButtonEnabled }
        ]"
        :style="{
          boxShadow: rightButtonEnabled ? `-4px 0px 10px 0px rgba(0, 0, 0, 0.30)` : ''
        }"
      >
        <button @click="onClickNext">
          <inline-svg
            class="tw-fill-ap-white tw-h-6 tw-w-6"
            :src="$cdn('icons/arrow_chevron_right.svg')"
            alt="Arrow Right"
          />
        </button>
      </div>
    </div>

    <!-- Calendar Details 2 -->
    <div
      :class="[
        'tw-flex tw-flex-col',
        'tw-w-full tw-max-w-[900px] tw-pb-5',
        'tw-rounded-[4px] tw-border-[4px] tw-border-[#007C4C]',
      ]"
      style="background-color: #F8F7ED"
    >
      <div
        :class="[
          'tw-flex tw-flex-row',
          'tw-text-[#C52600] tw-font-akkuratbold',
          'tw-border-b-[4px] tw-border-[#007C4C]',
        ]"
      >
        <div
          :class="[
            'tw-flex tw-justify-center',
            'tw-py-2',
            'tw-flex-1 tw-bg-[#F8F7ED]',
            'tw-border-r-[2px] tw-border-[#007C4C]',
          ]"
        >
          {{ activeState.date }}
        </div>
        <div
          :class="[
            'tw-flex tw-justify-center',
            'tw-py-2',
            'tw-flex-1 tw-bg-[#F8F7ED]',
            'tw-border-l-[2px] tw-border-[#007C4C] tw-uppercase',
          ]"
        >
          {{ activeState.day }}
        </div>
      </div>
      <div
        :class="[
          'tw-flex tw-flex-row',
          'tw-border-b-[4px] tw-border-[#007C4C]',
          'tw-font-notosansbold',
        ]"
      >
        <div
          :class="[
            'tw-flex tw-justify-center',
            'tw-py-2',
            'tw-flex-1 tw-bg-[#339670] tw-text-white',
            'tw-border-r-[2px] tw-border-[#007C4C]',
          ]"
        >
          {{ activeState.chinese_date }}
        </div>
        <div
          :class="[
            'tw-flex tw-justify-center',
            'tw-py-2',
            'tw-flex-1 tw-bg-[#339670] tw-text-white',
            'tw-border-l-[2px] tw-border-[#007C4C]',
          ]"
        >
          {{ activeState.chinese_day }}
        </div>
      </div>
      <simplebar
        :auto-hide="false"
        :class="[
          'scrollable',
          'tw-flex tw-flex-col tw-justify-center tw-gap-5',
          'tw-h-[320px]  tw-bg-[#F8F7ED]',
          'tw-px-4 sgTablet:tw-px-[56px] tw-py-5',
        ]"
      >

        <div
          id="sup-handler-container"
          :class="['custom-content tw-flex-grow tw-overflow-auto tw-w-full']"
          v-html="contentRight"
        />
      </simplebar>
      <div
        :class="[
          'tw-flex tw-justify-center',
          'tw-pt-5',
          { 'tw-hidden' : !dataRef.external_link_right },
        ]"
      >
        <a
          :href="dataRef.external_link_right"
          target="_blank"
          rel="noopener"
          :class="[
            'tw-text-white tw-bg-[#C52600]',
            'tw-px-6 tw-py-4',
            'tw-text-sg-ma-bt1',
            'tw-rounded-[26px] !tw-leading-[20px] tw-h-[52px] tw-uppercase',
            'hover:tw-bg-[#D15133] active:tw-bg-[#9E1E00]',
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
        >
          {{ $t('cny_find_out_more') }}
        </a>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { Swiper as SwiperInstance } from 'swiper';
import { checkLang } from '../../../support/helper';
import { onBeforeUnmount } from 'vue';
import simplebar from 'simplebar-vue';
import { nextTick } from 'process';

interface CalendarType {
  id: number;
  reference: string;
  title?: string | null;
  chinese_title: string;
  date: string;
  chinese_date: string;
  show_day: number;
  day: string;
  chinese_day: string;
  chinese_month: string;
};

const swiperRef = ref<SwiperInstance | null>(null);

const leftButtonEnabled = ref(false);
const rightButtonEnabled = ref(true);

const isMobile = ref(window.innerWidth < 400);
const currentSelectedIndex = ref(0);

const emit = defineEmits(['openSources']);

onMounted(() => {
  window.addEventListener('resize', updateWindowSize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateWindowSize);
});

const props = defineProps<{
  data:any
}>();

const updateWindowSize = () => {
  isMobile.value = window.innerWidth < 400;
};

const handleSupClick = () => {
  emit('openSources');
};

function addSourcesPanelHandler() {
  removeSourcesPanelHandler();
  const supElements = document.querySelectorAll('#sup-handler-container sup');
  if(supElements?.length > 0){
    supElements.forEach((supElement) => {
      supElement.addEventListener('click', handleSupClick);
    });
  }
}

function removeSourcesPanelHandler() {
  const supElements = document.querySelectorAll('#sup-handler-container sup');
  if(supElements?.length > 0){
    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }
}

function onSelectDate(index: number) {
  currentSelectedIndex.value = index;
  nextTick(() => {
    addSourcesPanelHandler();
  });
}

const activeState = computed(() => {
  return calendars.value[currentSelectedIndex.value];
});

const dataRef = computed(() => {
  const key = calendars.value[currentSelectedIndex.value]?.reference;
  return props.data[key];
});

const dateData = computed(() => {
  const active = activeState.value;
  if (active && active.date) {
    return active.date.split(' ');
  }
  return [];
});

const cleanDateTitle = (title: string) => {
  return title.replace('<br/>', '');
};

const contentLeft = computed(() => dataRef.value?.content_left);
const contentRight = computed(() => dataRef.value?.content_right);

function onSwiper(swiper:any) {
  swiperRef.value = swiper;
}

function onScroll(swiper:any) {
  leftButtonEnabled.value = !swiper.isBeginning;
  rightButtonEnabled.value = !swiper.isEnd;
}

function onClickNext() {
  swiperRef.value?.slideNext();
}

function onClickPrev() {
  swiperRef.value?.slidePrev();
}

const calendars = ref<CalendarType[]>([
  {
    id: 1,
    reference: 'eve',
    title: 'Eve',
    chinese_title: '除夕',
    date: '28 JANUARY 2025',
    chinese_date: '2025 年 1 月 28 日',
    show_day: 0,
    day: 'Tuesday',
    chinese_day: '星期二',
    chinese_month: '一月',
  },
  {
    id: 2,
    reference: 'day_1',
    title: null,
    chinese_title: '正月初一',
    date: '29 JANUARY 2025',
    chinese_date: '2025 年 1 月 29 日',
    show_day: 1,
    day: 'Wednesday',
    chinese_day: '星期三',
    chinese_month: '一月',
  },
  {
    id: 3,
    reference: 'day_2',
    title: null,
    chinese_title: '正月初二',
    date: '30 JANUARY 2025',
    chinese_date: '2025 年 1 月 30 日',
    show_day: 2,
    day: 'Thursday',
    chinese_day: '星期四',
    chinese_month: '一月',
  },
  {
    id: 4,
    reference: 'day_3',
    title: null,
    chinese_title: '正月初三',
    date: '31 JANUARY 2025',
    chinese_date: '2025 年 1 月 31 日',
    show_day: 3,
    day: 'Friday',
    chinese_day: '星期五',
    chinese_month: '一月',
  },
  {
    id: 5,
    reference: 'day_4',
    title: null,
    chinese_title: '正月初四',
    date: '1 FEBRUARY 2025',
    chinese_date: '2025 年 2 月 1 日',
    show_day: 4,
    day: 'Saturday',
    chinese_day: '星期六',
    chinese_month: '二月',
  },
  {
    id: 6,
    reference: 'day_5',
    title: null,
    chinese_title: '正月初五',
    date: '2 FEBRUARY 2025',
    chinese_date: '2025 年 2 月 2 日',
    show_day: 5,
    day: 'Sunday',
    chinese_day: '星期日',
    chinese_month: '二月',
  },
  {
    id: 7,
    reference: 'day_6',
    title: null,
    chinese_title: '正月初六',
    date: '3 FEBRUARY 2025',
    chinese_date: '2025 年 2 月 3 日',
    show_day: 6,
    day: 'Monday',
    chinese_day: '星期一',
    chinese_month: '二月',
  },
  {
    id: 8,
    reference: 'day_7',
    title: null,
    chinese_title: '正月初七',
    date: '4 FEBRUARY 2025',
    chinese_date: '2025 年 2 月 4 日',
    show_day: 7,
    day: 'Tuesday',
    chinese_day: '星期二',
    chinese_month: '二月',
  },
  {
    id: 9,
    reference: 'day_8',
    title: null,
    chinese_title: '正月初八',
    date: '5 FEBRUARY 2025',
    chinese_date: '2025 年 2 月 5 日',
    show_day: 8,
    day: 'Wednesday',
    chinese_day: '星期三',
    chinese_month: '二月',
  },
  {
    id: 10,
    reference: 'day_9',
    title: null,
    chinese_title: '正月初九',
    date: '6 FEBRUARY 2025',
    chinese_date: '2025 年 2 月 6 日',
    show_day: 9,
    day: 'Thursday',
    chinese_day: '星期四',
    chinese_month: '二月',
  },
  {
    id: 11,
    reference: 'day_10',
    title: null,
    chinese_title: '正月初十',
    date: '7 FEBRUARY 2025',
    chinese_date: '2025 年 2 月 7 日',
    show_day: 10,
    day: 'Friday',
    chinese_day: '星期五',
    chinese_month: '二月',
  },
  {
    id: 12,
    reference: 'day_11',
    title: null,
    chinese_title: '正月十一',
    date: '8 FEBRUARY 2025',
    chinese_date: '2025 年 2 月 8 日',
    show_day: 11,
    day: 'Saturday',
    chinese_day: '星期六',
    chinese_month: '二月',
  },
  {
    id: 13,
    reference: 'day_12',
    title: null,
    chinese_title: '正月十二',
    date: '9 FEBRUARY 2025',
    chinese_date: '2025 年 2 月 9 日',
    show_day: 12,
    day: 'Sunday',
    chinese_day: '星期日',
    chinese_month: '二月',
  },
  {
    id: 14,
    reference: 'day_13',
    title: null,
    chinese_title: '正月十三',
    date: '10 FEBRUARY 2025',
    chinese_date: '2025 年 2 月 10 日',
    show_day: 13,
    day: 'Monday',
    chinese_day: '星期一',
    chinese_month: '二月',
  },
  {
    id: 15,
    reference: 'day_14',
    title: null,
    chinese_title: '正月十四',
    date: '11 FEBRUARY 2025',
    chinese_date: '2025 年 2 月 11 日',
    show_day: 14,
    day: 'Tuesday',
    chinese_day: '星期二',
    chinese_month: '二月',
  },
  {
    id: 16,
    reference: 'day_15',
    title: null,
    chinese_title: '正月十五',
    date: '12 FEBRUARY 2025',
    chinese_date: '2025 年 2 月 12 日',
    show_day: 15,
    day: 'Wednesday',
    chinese_day: '星期三',
    chinese_month: '二月',
  },
]);
</script>
<style scoped>

.calendar-date {
  text-align: center;
  font-size: 200px;
  font-style: normal;
  font-weight: 400;
  line-height: 200px;
  letter-spacing: -16px;
}

.custom-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.custom-content :deep(sup),
.custom-content :deep(u),
.custom-content :deep(a) {
  color: #0366D8;
  cursor: pointer;
}

.custom-content :deep(li),
.custom-content :deep(p) {
  color: #1A1A1A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.7px;
}

.custom-content :deep(h4) {
  color: #007C4C;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.48px;
}

.custom-content :deep(ul) {
  list-style: disc;
  list-style-position: outside;
  padding-left: 16px;
}

.custom-content :deep(ol) {
  list-style: none; /* Remove the default marker style */
  counter-reset: list-counter; /* Initialize counter */
}

.custom-content :deep(ol li) {
  counter-increment: list-counter; /* Increment counter for each item */
  position: relative; /* Create a local positioning context */
  padding-left: 20px; /* Ensure consistent spacing for text */
}

.custom-content :deep(ol li)::before {
  content: counter(list-counter) ". "; /* Add the number as a marker */
  position: absolute;
  left: 0; /* Align marker to the left edge */
  width: 16px; /* Adjust marker width */
  text-align: right; /* Ensure the number aligns properly */
}

@media screen and (max-width: 1024px) {
  .calendar-date {
    text-align: center;
    font-size: 160px;
    font-style: normal;
    font-weight: 400;
    line-height: 160px;
    letter-spacing: -12.8px;
  }
}

@media screen and (max-width: 679px) {
  .calendar-date {
    text-align: center;
    font-size: 120px;
    font-style: normal;
    font-weight: 400;
    line-height: 120px;
    letter-spacing: -9.6px;
  }
}
</style>
