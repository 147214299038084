<template>
  <div
    :class="[
      'tw-w-[43.5vw] sgTablet:tw-w-[240px] sgDesktop:tw-w-[260px] tw-flex tw-flex-col',
      {'tw-font-notosans': checkLang() != 'en', 'tw-font-akkurat': checkLang() == 'en'}
    ]"
  >
    <!-- Image Border Rounded  -->

    <div class="tw-relative tw-h-full">
      <img
        :src="event.image"
        alt="Event Image"
        :class="[
          'tw-h-full tw-w-full',
          'tw-rounded-t-[4px]',
          'tw-object-cover',
          'tw-max-h-[92px] sgTablet:tw-max-h-[134px] sgDesktop:tw-max-h-[146px]',
          'tw-flex-shrink-0'
        ]"
      >
      <div
        v-if="event.status=='fully_booked' || event.status=='sold_out' || isPastEvent(event.expired_datetime)"
        class="tw-absolute tw-inset-0 tw-w-full tw-h-full tw-bg-black/40 tw-flex tw-justify-center"
      >
        <div
          v-if="isPastEvent(event.expired_datetime)"
          class="tw-place-self-center tw-text-sg-sh1-mobile sgTablet:tw-text-sg-sh1-tablet sgDesktop:tw-text-sg-sh1 tw-text-white"
        >
          {{ $t('ma_family_fun_past_event') }}
        </div>
      </div>
    </div>

    <div
      :class="[
        getEventStatusColor(),
        'tw-text-white tw-text-sg-body-3 tw-tracking-wider',
        'tw-px-4',
        'sgTablet:tw-py-[2px] sgDesktop:tw-py-1',
        'tw-text-center',
        'tw-w-full',
        'tw-h-6 sgTablet:tw-h-7 sgDesktop:tw-h-8',
        'tw-flex-shrink-0'
      ]"
    >
      {{ getEventStatusText() }}
    </div>
    <!-- Event Card Content -->
    <div
      class="tw-h-full tw-w-full tw-rounded-b-[4px] tw-bg-white hover:tw-inset-0 hover:tw-bg-gray-50 tw-flex-1"
      :style="{
        boxShadow:'0px 4px 8px 0px #0000000F,0px 0px 4px 0px #0000000A'
      }"
    >
      <div class="tw-px-2 sgTablet:tw-px-4 tw-pt-2 sgTablet:tw-pt-3">
        <!-- Event Type -->
        <p class="tw-h-6 tw-text-sg-body-3-mobile md:tw-text-sg-body-3-tablet lg:tw-text-sg-body-3 tw-pb-1 tw-normal-case">
          {{ $t(getEventTypeText()) }}
        </p>
        <!-- Event Title -->
        <h3
          :class="[
            'tw-line-clamp-3 sgTablet:tw-line-clamp-2',
            'tw-text-sg-ma-sh3',
            'tw-min-h-[60px] sgTablet:tw-min-h-[40px]',
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
        >
          {{ event.title }}
        </h3>
        <!-- Event Date -->
        <div
          class="tw-pt-4 tw-flex tw-flex-col tw-gap-2"
          :class="doubleLineDate ? 'tw-flex-col' : 'tw-flex-row'"
        >
          <p class="tw-text-sg-caption tw-text-nowrap">
            {{ $t('common.dates') }}
          </p>
          <span
            class="tw-block sgTablet:tw-inline tw-text-sg-caption tw-h-[32px] tw-line-clamp-2"
          >
            {{ event.date_str ?? 'Date not specified' }}
          </span>
        </div>
        <!-- Find Out More Text Button -->
        <div class="tw-py-2 sgTablet:tw-py-3 tw-flex tw-justify-end">
          <button
            :class="[
              'tw-flex tw-flex-row tw-align-middle tw-items-center ',
              'tw-py-1 tw-pr-2 tw-gap-2',
            ]"
            @click="$emit('openModal', event)"
          >
            <span class="tw-text-sg-button-2 tw-text-[#0366D8] hover:tw-underline">{{ $t('ma_family_fun_find_out_more') }}</span>
            <inline-svg
              class="tw-w-3 tw-h-3"
              fill="#0366D8"
              :src="$cdn('icons/ce/arrow_chevron_right_blue.svg')"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../../support/helper';
import { onBeforeUnmount, onMounted, ref } from 'vue';
const { t } = useI18n();

type CNY25FamilyFunProgrammeCategoryType = 'activities' | 'workshops' | 'performances' | 'talks' | 'exhibits';
type CNY25FamilyFunProgrammeCategoryFilterType = 'all' | CNY25FamilyFunProgrammeCategoryType;
type CNY25FamilyFunProgrammeStatusType = 'free_event' | 'paid_event' | 'selling_fast' | 'sold_out' | 'fully_booked';
type CNY25FamilyFunProgrammePostType = {
  title:string,
  description:string,
  category: CNY25FamilyFunProgrammeCategoryType,
  dates:string[],
  date_str:string,
  time:string,
  venue:string,
  paid:boolean,
  status: CNY25FamilyFunProgrammeStatusType,
  image:string,
  external_link?:string,
  is_video_embed:boolean,
  video_link?:string,
  expired_datetime?:string,
  sold_out:boolean,
  selling_fast:boolean,
}

defineEmits<{
  (e:'openModal',event:CNY25FamilyFunProgrammePostType):void
}>();

function getEventTypeText() {
  if(!props.event.category)
    return '';
  return 'cny25_family_fun_category_' + props.event.category.value;
}

function getEventStatusColor() {
  if(!props.event.status)
    return 'tw-bg-[#E4100E]';

  const statusMap:{[key:string]:string} = {
    'free_event':'tw-bg-[#36A93F]',
    'fully_booked':'tw-bg-[#E4100E]',
    'paid_event':'tw-bg-[#0366D8]',
    'selling_fast':'tw-bg-[#F6610C]',
    'sold_out':'tw-bg-[#E4100E]',
  };

  return statusMap[props.event.status];
}

function getEventStatusText(): string {
  if(!props.event.status)
    return '';
  return t(`ma_family_fun_status_${props.event.status}`);
}
function isPastEvent(inputDate?: string): boolean {
  if (!inputDate) return false;

  // Parse the input in the format "YYYY-MM-DD HH:MM:SS"
  const [datePart, timePart] = inputDate.split(' '); // Split date and time
  const [year, month, day] = datePart.split('-').map(Number);
  const [hour, minute, second] = timePart.split(':').map(Number);

  // Create a Date object
  const date = new Date(year, month - 1, day, hour, minute, second); // Month is zero-based in JS Date
  const now = new Date();

  // Compare the input date with the current date
  return date < now;
}

const doubleLineDate = ref(false);

onMounted(() => {
  if (props.event.dates.length > 20) {
    doubleLineDate.value = true;
  }
});

const props = defineProps<{
  event:CNY25FamilyFunProgrammePostType
}>();

</script>
