<template>
  <section
    :class="[
      'tw-flex tw-flex-col tw-justify-center tw-items-start sgTablet2:tw-items-center',
      'tw-pb-10 tw-pt-5',
      'tw-w-full tw-overflow-scroll no-scrollbar',
    ]"
  >

    <div
      :class="[
        'tw-flex tw-flex-row tw-justify-center tw-items-center',
        'tw-gap-2 sgTablet:tw-gap-5',
        'tw-px-4'
      ]"
    >
      <div
        v-for="(card, index) in cards"
        :key="index"
        :class="[
          'card-wrapper',
          'tw-flex tw-flex-col tw-rounded-[8px] tw-bg-[#F4F4F4]',
          'tw-min-w-[140px] tw-w-[25vw] sgTablet:tw-w-[180px] !tw-h-[220px] sgTablet:!tw-h-[252px]',
          'tw-border-[2px] tw-cursor-pointer',
          { 'selected-card': card.id === selectedId },
        ]"
        @mouseover="hoverId = index"
        @mouseleave="hoverId = null"
        @click="onClickCard(card.id)"
      >
        <img
          class="tw-w-[120px] sgTablet:tw-w-[180px]"
          :src="$cdn('images/cny2024/storiesCard/' + card.imageUrlPath)"
        >
        <div
          class="tw-text-[#C52600] tw-px-2 sgTablet:tw-px-5 tw-py-4"
          :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
        >
          <p>{{ card.title }}</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
// Import Swiper Vue.js components
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { checkLang } from '../../support/helper';

const { t } = useI18n();
const emit = defineEmits(['selectStory']);

const selectedId = ref(1);
const hoverId = ref<number|null>(null);

const cards = [
  {
    id: 1,
    imageUrlPath: 'story.png',
    title: t('cny25_the_legend_nian_selection'),
  },
  {
    id: 2,
    imageUrlPath: 'story2.png',
    title: t('cny25_origin_stories_zodiac_race_selection'),
  },
  {
    id: 3,
    imageUrlPath: 'story3.png',
    title: t('cny25_bai_tian_gong_custom_selection'),
  },
  {
    id: 4,
    imageUrlPath: 'story4.png',
    title: t('cny25_the_origins_of_ren_ri_selection'),
  },
];

function onClickCard(id: number) {
  selectedId.value = id;
  emit('selectStory', id);
}
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.card-wrapper {
  transition: all 0.1s ease-in-out;
  border-color: transparent;
}

.card-wrapper:hover {
  background-color: #ffffff !important;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}

.card-wrapper.selected-card {
  background-color: #ffffff !important;
  border-color: #C52600;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}
</style>
