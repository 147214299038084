<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :cny-25-navigation="true"
      :cny25-p2-navigation="wordpressData?.unlock_sidebar"
    />
    <secondary-navigation :menu-items-data="menuItems" />
    <!-- Header -->
    <cny-25-header-banner
      :title="'cny25_whats_open_title'"
      :description="'cny25_whats_open_subtitle'"
    />
    <!-- Don't Say Bo Jio -->
    <section class="tw-px-4 sgTablet:tw-px-0">
      <!-- Title & Description -->
      <div
        id="dont-say-bojio"
        class="tw-text-center"
      >
        <div class="tw-flex tw-flex-row tw-gap-3 tw-justify-center">
          <img
            class="tw-h-[80px] sgTablet:tw-h-[100px] tw-absolute tw-left-4 sgTablet:tw-left-10 sgDesktop:tw-left-20 sgDesktop2:tw-relative sgDesktop2:tw-left-0"
            :src="$cdn('images/cny2024/whats-open/lantern.png')"
          >
          <div class="tw-flex tw-flex-col tw-max-w-[240px] sgTablet:tw-max-w-[800px] tw-gap-5">
            <h1
              class="tw-text-[#C52600] tw-pt-10 tw-text-sg-h1-tablet-cny sgDesktop:tw-text-sg-h1-cny"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('cny_whats_open_bojio_title') }}
            </h1>
            <p
              class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 tw-break-words"
              v-html="bojio_description"
            />
          </div>
          <img
            class="-tw-scale-x-100 tw-h-[80px] sgTablet:tw-h-[100px] tw-absolute tw-right-4 sgTablet:tw-right-10 sgDesktop:tw-right-20 sgDesktop2:tw-relative sgDesktop2:tw-right-0"
            :src="$cdn('images/cny2024/whats-open/lantern.png')"
          >
        </div>
      </div>
      <!-- Table -->
      <div
        class="tw-flex tw-flex-wrap tw-justify-center tw-max-w-[800px] tw-w-full tw-mx-auto tw-mt-6 sgTablet:tw-mt-10 tw-pb-10 sgTablet:tw-pb-20 tw-gap-4 sgDesktop:tw-gap-5"
      >
        <div
          v-for="(item, index) in bojio_post"
          :key="index"
          class="tw-w-[160px] sgTablet:tw-w-[252px] tw-bg-[#FFF3DE] tw-rounded-lg tw-overflow-hidden tw-flex tw-flex-col"
          :style="{
            boxShadow: '0px 4px 8px 0px #0000000F,0px 0px 4px 0px #0000000A',
          }"
        >
          <div class="tw-relative">
            <img
              :src="item.image"
              class="tw-w-full tw-h-[90px] sgTablet:tw-h-[141.75px] tw-object-center tw-object-cover"
            >
            <div
              v-if="item.is_past"
              class="tw-absolute tw-inset-0 tw-w-full tw-h-full tw-bg-black/40 tw-flex tw-justify-center"
            >
              <div class="tw-place-self-center tw-text-sg-sh1-mobile sgTablet:tw-text-sg-sh1-tablet sgDesktop:tw-text-sg-sh1 tw-text-white">
                {{ $t('cny_whats_open_past_event') }}
              </div>
            </div>
          </div>
          <div class="tw-my-5 tw-mx-4 tw-flex-1 tw-flex tw-flex-col">
            <div
              class="tw-text-sg-sh3-mobile-cny sgTablet:tw-text-sg-sh3-tablet-cny sgDesktop:tw-text-sg-sh3-cny tw-mt-2 tw-flex-1"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ item.title }}
            </div>
            <div
              class="tw-flex tw-justify-end tw-text-sg-button-2 tw-mt-4 tw-cursor-pointer tw-group"
              @click="handleOpenModal(item)"
            >
              <div class="tw-flex tw-gap-x-2 tw-items-center">
                <span class="group-hover:tw-underline">{{ $t('cny_whats_open_find_out_more') }}</span><inline-svg :src="$cdn('images/cny2024/whats-open/arrow_forward_ios.svg')" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Page Break -->
    <CNY25FloatingDivider />
    <!-- Other Exciting Programmes -->
    <section
      class="tw-px-4 sgTablet:tw-px-0"
      :style="{
        background: 'linear-gradient(180deg, #FFD68C 0%, #FFF9EE 100%)'
      }"
    >
      <!-- Title & Description -->
      <div
        id="other-exciting-programmes"
        class="tw-max-w-[340px] sgTablet:tw-max-w-[512px] sgTablet2:tw-max-w-[800px] tw-w-full tw-mx-auto tw-text-center"
      >
        <div class="tw-flex tw-flex-col tw-gap-7 tw-pt-5">
          <h1
            class="tw-text-[#C52600] tw-text-sg-h1-mobile-cny sgTablet:tw-text-sg-h1-cny"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          >
            {{ $t('cny_whats_open_other_programmes_title') }}
          </h1>
          <p
            class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 tw-break-words"
            v-html="other_programme_description"
          />
        </div>
      </div>
      <!-- Table -->
      <div
        class="tw-flex tw-flex-wrap tw-justify-center tw-max-w-[800px] tw-w-full tw-mx-auto tw-mt-6 sgTablet:tw-mt-10 tw-pb-10 sgTablet:tw-pb-20 tw-gap-4 sgDesktop:tw-gap-5"
      >
        <div
          v-for="(item, index) in other_programme_post"
          :key="index"
          class="tw-w-[160px] sgTablet:tw-w-[252px] tw-bg-[#FFCC6F] tw-rounded-lg tw-overflow-hidden tw-flex tw-flex-col"
          :style="{
            boxShadow: '0px 4px 8px 0px #0000000F,0px 0px 4px 0px #0000000A',
          }"
        >
          <div class="tw-relative">
            <img
              :src="item.image"
              class="tw-w-full tw-h-[90px] sgTablet:tw-h-[141.75px] tw-object-center tw-object-cover"
            >
            <div
              v-if="item.is_past"
              class="tw-absolute tw-inset-0 tw-w-full tw-h-full tw-bg-black/40 tw-flex tw-justify-center"
            >
              <div class="tw-place-self-center tw-text-sg-sh1-mobile sgTablet:tw-text-sg-sh1-tablet sgDesktop:tw-text-sg-sh1 tw-text-white">
                {{ $t('cny_whats_open_past_event') }}
              </div>
            </div>
          </div>

          <div class="tw-my-5 tw-mx-4 tw-flex-1 tw-flex tw-flex-col">
            <div
              class="tw-text-sg-sh3-mobile-cny sgTablet:tw-text-sg-sh3-tablet-cny sgDesktop:tw-text-sg-sh3-cny tw-mt-2 tw-flex-1"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ item.title }}
            </div>
            <div
              class="tw-flex tw-justify-end tw-text-sg-button-2 tw-mt-4 tw-cursor-pointer tw-group"
              @click="handleOpenModal(item)"
            >
              <div class="tw-flex tw-gap-x-2 tw-items-center">
                <span class="group-hover:tw-underline">{{ $t('cny_whats_open_find_out_more') }}</span><inline-svg :src="$cdn('images/cny2024/whats-open/arrow_forward_ios.svg')" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Online Programmes -->
    <section class=" tw-bg-[#FFF9EE] tw-px-4">
      <div
        id="online-programmes"
        class="tw-max-w-[340px] sgTablet2:tw-max-w-[800px] tw-w-full tw-mx-auto tw-text-center tw-pt-5"
      >
        <h1
          class="tw-text-[#C52600] tw-text-sg-h1-mobile-cny sgTablet:tw-text-sg-h1-cny-title"
          :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
        >
          {{ $t('cny_whats_open_online_programmes_title') }}
        </h1>
        <p
          class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 tw-mt-7 tw-break-words"
          v-html="online_programme_description"
        />
      </div>
      <div
        class="tw-flex tw-flex-wrap tw-justify-center tw-max-w-[800px] tw-w-full tw-mx-auto tw-mt-6 sgTablet:tw-mt-10 tw-pb-10 sgTablet:tw-pb-20 tw-gap-4 sgDesktop:tw-gap-5"
      >
        <div
          v-for="(item, index) in online_programme_post"
          :key="index"
          class="tw-w-[160px] sgTablet:tw-w-[252px] tw-bg-[#FFAF1E] tw-rounded-lg tw-overflow-hidden tw-flex tw-flex-col"
          :style="{
            boxShadow: '0px 4px 8px 0px #0000000F,0px 0px 4px 0px #0000000A',
          }"
        >
          <div class="tw-relative">
            <img
              :src="item.image"
              class="tw-w-full tw-h-[90px] sgTablet:tw-h-[141.75px] tw-object-center tw-object-cover"
            >
            <div
              v-if="item.is_past"
              class="tw-absolute tw-inset-0 tw-w-full tw-h-full tw-bg-black/40 tw-flex tw-justify-center"
            >
              <div class="tw-place-self-center tw-text-sg-sh1-mobile sgTablet:tw-text-sg-sh1-tablet sgDesktop:tw-text-sg-sh1 tw-text-white">
                {{ $t('cny_whats_open_past_event') }}
              </div>
            </div>
          </div>
          <div class="tw-my-5 tw-mx-4 tw-flex-1 tw-flex tw-flex-col">
            <div
              class="tw-text-sg-sh3-mobile-cny sgTablet:tw-text-sg-sh3-tablet-cny sgDesktop:tw-text-sg-sh3-cny tw-mt-2 tw-flex-1"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ item.title }}
            </div>
            <div
              class="tw-flex tw-justify-end tw-text-sg-button-2 tw-mt-4 tw-cursor-pointer tw-group"
              @click="handleOpenModal(item)"
            >
              <div class="tw-flex tw-gap-x-2 tw-items-center">
                <span class="group-hover:tw-underline">{{ $t('cny_whats_open_find_out_more') }}</span><inline-svg :src="$cdn('images/cny2024/whats-open/arrow_forward_ios.svg')" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <cny-25-whats-on-modal
      :key="modalKey"
      v-model="modal.show"
      :data="modal.data"
    />
    <ap-footer />
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue';
import { ref } from 'vue';
import { cdn } from '../../support/cdn';
import { checkLang } from '../../support/helper';
import CNY25FloatingDivider from '../../components/cny25/CNY25FloatingDivider.vue';

const props = defineProps<{
  wordpressData: any;
}>();

type Post = {
  title: string;
  description: string;
  image: string;
  external_link: string;
  is_past?: boolean;
};

const bojio_post = ref<Post[]>([]);
const other_programme_post = ref<Post[]>([]);
const online_programme_post = ref<Post[]>([]);
const bojio_description = ref('');
const other_programme_description = ref('');
const online_programme_description = ref('');

const modalKey = ref(Date.now());

onMounted(() => {
  if (props.wordpressData?.bojio_post) {
    bojio_post.value = props.wordpressData.bojio_post.map((item: any) => {
      return {
        ...item,
        image: item.image ? item.image : cdn('images/cny2024/whats-open/card_placeholder.png'),
      };
    });
  }
  if (props.wordpressData?.other_programme_post) {
    other_programme_post.value = props.wordpressData.other_programme_post.map((item: any) => {
      return {
        ...item,
        image: item.image ? item.image : cdn('images/cny2024/whats-open/card_placeholder.png'),
      };
    });
  }
  if (props.wordpressData?.online_programme_post) {
    online_programme_post.value = props.wordpressData.online_programme_post.map((item: any) => {
      return {
        ...item,
        image: item.image ? item.image : cdn('images/cny2024/whats-open/card_placeholder.png'),
      };
    });
  }
  if (props.wordpressData?.bojio_description) {
    bojio_description.value = props.wordpressData.bojio_description;
  }
  if (props.wordpressData?.other_programme_description) {
    other_programme_description.value = props.wordpressData.other_programme_description;
  }
  if (props.wordpressData?.online_programme_description) {
    online_programme_description.value = props.wordpressData.online_programme_description;
  }
});

const modal = ref<{ show: boolean; data: Post }>({
  show: false,
  data: {} as Post,
});
const handleOpenModal = (item: Post) => {
  modal.value.show = true;
  modal.value.data = item;
  modalKey.value = Date.now();
};

const menuItems = [
  {
    label: 'cny_whats_open_menu_bojio',
    id: 'dont-say-bojio',
  },
  {
    label: 'cny_whats_open_menu_other_programmes',
    id: 'other-exciting-programmes',
  },
  {
    label: 'cny_whats_open_menu_online_programmes',
    id: 'online-programmes',
  },
];
</script>
