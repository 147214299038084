<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :cny-25-navigation="true"
      :cny25-p2-navigation="wordpressData?.unlock_sidebar"
    />
    <secondary-navigation :menu-items-data="menuItems" />
    <!-- Header -->
    <cny-25-header-banner
      :title="'cny_music_title'"
      :description="'cny_music_subtitle'"
    />
    <!-- Content -->
    <section class="tw-bg-white">
      <!-- Title & Description -->
      <div
        class="tw-px-4 sgTablet:tw-px-0 tw-text-center"
      >
        <div class="tw-flex tw-flex-row tw-justify-center tw-gap-3">
          <img
            class="tw-hidden sgTablet:tw-block tw-h-[80px] sgTablet:tw-h-[100px]"
            :src="$cdn('images/cny2024/music/lantern.png')"
          >
          <div class="tw-flex tw-flex-col tw-gap-5 tw-max-w-[600px] tw-py-10">
            <h1
              class="tw-text-[#C52600] tw-text-sg-cny25-mobile-h1 sgTablet:tw-text-sg-cny25-tablet-h1 sgDesktop:tw-text-sg-cny25-desktop-h1"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('cny_music_section_1_title') }}
            </h1>
            <p
              class="tw-text-[#1A1A1A] tw-text-sg-cny25-body"
              v-html="wordpressData.description"
            />
          </div>
          <img
            class="tw-hidden sgTablet:tw-block tw-h-[80px] sgTablet:tw-h-[100px]"
            :src="$cdn('images/cny2024/music/lantern.png')"
          >
        </div>
      </div>
    </section>
    <!-- Page Break -->
    <CNY25FloatingDivider :type="3" />
    <!-- Spotify -->
    <section
      id="cny-music-spotify"
      class="tw-bg-[#FFF3DE] tw-flex tw-flex-col tw-items-center tw-gap-10 tw-pb-[100px] sgTablet:tw-pt-5"
    >
      <div class="tw-flex tw-flex-col tw-max-w-[600px]">
        <img
          class="tw-w-[200px] tw-h-[200px]"
          :src="$cdn('images/cny2024/music/spotify.png')"
        >
        <h2
          class="tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop tw-text-center tw-text-[#C52600]"
          :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
        >
          {{ $t('cny_music_spotify_title') }}
        </h2>
      </div>
      <!-- Grid -->
      <div
        class="tw-flex tw-flex-wrap tw-justify-center tw-max-w-[800px] tw-w-full tw-mx-auto tw-gap-4 sgDesktop:tw-gap-5"
      >
        <div
          v-for="(item, index) in spotify_post"
          :key="index"
          class="tw-w-[160px] sgTablet:tw-w-[252px] tw-bg-[#7DBCA4] tw-rounded-lg tw-overflow-hidden tw-flex tw-flex-col"
          :style="{
            boxShadow: '0px 4px 8px 0px #0000000F,0px 0px 4px 0px #0000000A',
          }"
        >
          <img
            :src="item.image"
            class="tw-w-full tw-h-[160px] sgTablet:tw-h-[252px] tw-object-center tw-object-cover"
          >
          <div
            class="tw-flex-1 tw-flex tw-flex-col tw-mt-3 tw-mx-2 tw-mb-3 sgTablet:tw-mt-4 sgTablet:tw-mx-3 sgTablet:tw-mb-3 sgDesktop:tw-mt-5 sgDesktop:tw-mx-4"
          >
            <div
              class="tw-min-h-[40px] tw-text-sg-sh3-mobile-cny sgTablet:tw-text-sg-sh3-tablet-cny sgDesktop:tw-text-sg-sh3-cny tw-mb-2"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ item.title }}
            </div>
            <div class="tw-flex-1 tw-min-h-[120px] sgTablet:tw-min-h-[72px]">
              {{ item.description }}
            </div>
            <div class="tw-flex tw-justify-end tw-text-sg-button-2 tw-pt-2 tw-pr-2 tw-cursor-pointer tw-group tw-min-h-[52px]">
              <a
                :href="item.external_link"
                target="_blank"
                rel="noopener"
                class="tw-flex tw-gap-x-2 tw-items-center"
              >
                <span class="group-hover:tw-underline">{{ $t('cny_music_find_out_more') }}</span>
                <inline-svg :src="$cdn('images/cny2024/music/arrow_outward.svg')" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Youtube -->
    <section
      id="cny-music-youtube"
      class="tw-flex tw-flex-col tw-items-center tw-gap-10 tw-pb-[100px]"
      :style="{
        background: 'linear-gradient(180deg, #FFF3DE 0%, #FFF 100%)'
      }"
    >
      <div class="tw-flex tw-flex-col tw-items-center">
        <img
          class="tw-w-[200px] tw-h-[200px]"
          :src="$cdn('images/cny2024/music/youtube.png')"
        >
        <h2
          class="tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop tw-text-center tw-text-[#C52600]"
          :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
        >
          {{ $t('cny_music_youtube_title') }}
        </h2>
      </div>

      <div
        class="tw-flex tw-flex-wrap tw-justify-center tw-max-w-[800px] tw-w-full tw-mx-auto tw-gap-4 sgDesktop:tw-gap-5"
      >
        <div
          v-for="(item, index) in youtube_post"
          :key="index"
          class="tw-w-[160px] sgTablet:tw-w-[252px] tw-bg-[#E1907D] tw-rounded-lg tw-overflow-hidden tw-flex tw-flex-col"
          :style="{
            boxShadow: '0px 4px 8px 0px #0000000F,0px 0px 4px 0px #0000000A',
          }"
        >
          <img
            :src="item.image"
            class="tw-w-full tw-h-[90px] sgTablet:tw-h-[142px] tw-object-center tw-object-cover"
          >
          <div class="tw-my-3 tw-mx-2 tw-flex-1 tw-flex tw-flex-col sgTablet:tw-mt-4 sgTablet:tw-mx-3 sgTablet:tw-mb-3 sgDesktop:tw-mt-5 sgDesktop:tw-mx-4">
            <div
              class="tw-min-h-[40px] tw-mb-2 tw-text-sg-sh3-mobile-cny sgTablet:tw-text-sg-sh3-tablet-cny sgDesktop:tw-text-sg-sh3-cny tw-line-clamp-3 sgTablet:tw-line-clamp-2"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ item.title }}
            </div>
            <div class="tw-flex-1 tw-min-h-[120px] sgTablet:tw-min-h-[72px] tw-text-sg-cny25-b3 sgTablet:tw-line-clamp-3">
              {{ item.description }}
            </div>
            <div class="tw-min-h-[32px] tw-flex tw-justify-end tw-text-sg-button-2 tw-pt-2 tw-pr-2 tw-cursor-pointer tw-group">
              <a
                :href="item.external_link"
                target="_blank"
                rel="noopener"
                class="tw-flex tw-gap-x-2 tw-items-center"
              >
                <span class="group-hover:tw-underline">{{ $t('cny_music_find_out_more') }}</span>
                <inline-svg :src="$cdn('images/cny2024/music/arrow_outward.svg')" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ap-footer />
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue';
import { ref } from 'vue';
import { cdn } from '../../support/cdn';
import { checkLang } from '../../support/helper';
import CNY25FloatingDivider from '../../components/cny25/CNY25FloatingDivider.vue';

const props = defineProps<{
  wordpressData: any;
}>();

const menuItems = [
  {
    label: 'cny_music_menu_spotify',
    id: 'cny-music-spotify',
  },
  {
    label: 'cny_music_menu_youtube',
    id: 'cny-music-youtube',
  },
];
type Post = {
  title: string;
  description: string;
  image: string;
  external_link: string;
};

const spotify_post = ref<Post[]>([]);
const youtube_post = ref<Post[]>([]);

onMounted(() => {
  if (props.wordpressData?.spotify_post) {
    spotify_post.value = props.wordpressData.spotify_post.map((item: any) => {
      return {
        ...item,
        image: item.image ? item.image : cdn('images/cny2024/music/card_placeholder.png'),
      };
    });
  }
  if (props.wordpressData?.youtube_post) {
    youtube_post.value = props.wordpressData.youtube_post.map((item: any) => {
      return {
        ...item,
        image: item.image ? item.image : cdn('images/cny2024/music/card_placeholder.png'),
      };
    });
  }
});
</script>
