<template>
  <svg
    :width="w"
    height="64"
    :viewBox="`0 0 ${w} 64`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :d="computedPath"
      fill="#F8F7ED"
      stroke="#C52600"
      stroke-width="4"
    />
  </svg>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
const w = ref(900);
onMounted(() => {
  window.addEventListener('resize', handleOnResize);
  handleOnResize();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleOnResize);
});

const computedPath = computed(() => {
  const leftFixed = 12; // Fixed left position for the inner border
  const rightFixed = w.value - 12; // Calculate right position dynamically
  const strokeOuterLeft = 8; // Left border (outer stroke alignment)
  const strokeOuterRight = w.value - 8; // Right border (outer stroke alignment)

  return `
    M${strokeOuterLeft} 10
    H10
    V8
    V4
    C10 2.89543 10.8954 2 12 2
    H${rightFixed - 2}
    C${rightFixed - 0.89543} 2 ${rightFixed} 2.89542 ${rightFixed} 4
    V8
    V10
    H${rightFixed + 2}
    H${strokeOuterRight}
    C${strokeOuterRight + 1.105} 10 ${strokeOuterRight + 2} 10.8954 ${strokeOuterRight + 2} 12
    V52
    C${strokeOuterRight + 2} 53.1046 ${strokeOuterRight + 1.105} 54 ${strokeOuterRight} 54
    H${rightFixed + 2}
    H${rightFixed}
    V56
    V60
    C${rightFixed} 61.1046 ${rightFixed - 0.89543} 62 ${rightFixed - 2} 62
    H${leftFixed}
    C${leftFixed - 0.89543} 62 ${leftFixed - 2} 61.1046 ${leftFixed - 2} 60
    V56
    V54
    H8
    H4
    C2.89543 54 2 53.1046 2 52
    V12
    C2 10.8954 2.89543 10 4 10
    H8
    Z
  `;
});

function handleOnResize() {
  const parentContainer = document.querySelector('#cny-calendar-title-banner');
  if (parentContainer) {
    w.value = parentContainer?.offsetWidth;
  }
}
</script>
