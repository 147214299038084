<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :cny-25-navigation="true"
      :cny25-p2-navigation="wordpressData?.unlock_sidebar"
    />
    <secondary-navigation
      :menu-items-data="menuItems"
      :offset-y="70"
    />
    <!-- Header -->
    <cny-25-header-banner
      :title="'cny25_visiting_101'"
      :description="'cny25_snack_guides'"
    />

    <!-- Content -->
    <div class="tw-flex tw-flex-col tw-w-full">
      <!-- Snack Guides Header -->
      <div
        class="tw-relative tw-flex tw-flex-row tw-bg-white tw-w-full tw-justify-center tw-items-center tw-pt-5 tw-px-4 sgTablet:tw-pb-[60px] sgTablet:tw-pt-10 sgTablet:tw-px-10 sgDesktop:tw-px-20"
      >
        <img
          v-if="currentSize !== 'mobile'"
          class="tw-w-[120px] tw-h-[120px]"
          :src="$cdn('images/cny2024/snack-guides/image-left.png')"
        >
        <div
          v-if="currentSize !== 'mobile'"
          class="tw-pr-5"
        />
        <div class="tw-flex tw-flex-col tw-gap-5 sgTablet:tw-min-w-[360px] sgTablet:tw-max-w-[800px] sgDesktop:tw-max-w-[600px]">
          <p
            class="tw-text-center tw-text-[#C52600] tw-text-sg-h3-mobile-cny-title sgTablet:tw-text-sg-h3-tablet-cny-title sgDesktop:tw-text-sg-h3-cny-title"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          >
            {{ pageHeaderTitle }}
          </p>
          <div
            class="tw-flex tw-flex-col tw-gap-5 tw-px-4 tw-pb-10 sgTablet:tw-px-0 sgTablet:tw-py-0 tw-items-center tw-text-center tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-max-w-[800px]"
            :class="{
              'tw-break-keep': checkLang() == 'en',
            }"
            v-html="wordpressData.description"
          />
        </div>
        <div
          v-if="currentSize !== 'mobile'"
          class="tw-pl-5"
        />
        <img
          v-if="currentSize !== 'mobile'"
          class="tw-w-[120px] tw-h-[120px]"
          :src="$cdn('images/cny2024/snack-guides/image-right.png')"
        >
      </div>
      <CNY25FloatingDivider />
      <!-- Snack Guides Content -->
      <div class="tw-pb-10 tw-px-4 sgTablet:tw-px-20 sgDesktop:tw-px-30 tw-bg-[#FFD68C]">
        <div
          v-for="(item, index) in snackData"
          :key="index"
        >
          <div :id="getSectionId(index)">
            <cny-25-snack-guides-section
              :snack="item"
              :is-build-left="index % 2 == 0"
              :is-last="index === snackData.length - 1"
              :is-first="index === 0"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Sources !! -->
    <ap-image-sources
      v-model="imageSourceOpen"
      :data="wordpressData.sources"
      :title-style="`${
        checkLang() == 'zh-hans'?
          'tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop tw-font-notosansbold':
          'tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop tw-font-akkuratbold'
      }`"
    />
    <ap-footer />
  </div>
</template>

<script lang="ts">
import { computed, onMounted, onBeforeUnmount, Ref, ref, ComputedRef } from 'vue';
import { checkLang, getScreenSize } from '../../support/helper';
import { useI18n } from 'vue-i18n';
import CNY25FloatingDivider from '../../components/cny25/CNY25FloatingDivider.vue';

interface Snack {
  title: string;
  description: string;
  subtitle: string;
  image: string;
}

export default {
  components: {
    CNY25FloatingDivider,
  },
  props: {
    wordpressData: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { t } = useI18n();
    const currentSize: Ref<string> = ref(getScreenSize() ?? 'desktop');
    const imageSourceOpen: Ref<boolean> = ref(false);

    onMounted(() => {
      window.addEventListener('resize', handleResize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
    });

    function handleResize() {
      currentSize.value = getScreenSize() ?? 'desktop';
    }

    function getImageSrc(index: number): string {
      const imagePath = '/images/cny2024/snack-guides/';
      switch (index) {
        case 1:
          return imagePath + 'pineapple-tarts.png';
        case 2:
          return imagePath + 'bak-kwa.png';
        case 3:
          return imagePath + 'love-letters.png';
        case 4:
          return imagePath + 'kueh-bahulu.png';
        case 5:
          return imagePath + 'mini-shrimp-rolls.png';
        case 6:
          return imagePath + 'kueh-bangkit.png';
        case 7:
          return imagePath + 'arrow-head-chips.png';
        case 8:
          return imagePath + 'niangao.png';
        case 9:
          return imagePath + 'honeycomb-biscuits.png';
        case 10:
          return imagePath + 'kueh-lapis.png';
        case 11:
          return imagePath + 'peanut-cookies.png';
        case 12:
          return imagePath + 'mandarin-oranges.png';
        default:
          return '';
      }
    }

    function getSectionId(index: number): string {
      return sectionId[index];
    }

    const pageHeaderTitle = computed(() => t('cny25_snack_guides_page_header_title'));

    const snackData: ComputedRef<Snack[]> = computed(() => [
      {
        title: t('cny25_snack_guides_pineapple_tarts_title'),
        description: t('cny25_snack_guides_pineapple_tarts_description'),
        subtitle: t('cny25_snack_guides_pineapple_tarts_subtitle'),
        image: getImageSrc(1),
      },
      {
        title: t('cny25_snack_guides_bakkwa_title'),
        description: t('cny25_snack_guides_bakkwa_description'),
        subtitle: t('cny25_snack_guides_bakkwa_subtitle'),
        image: getImageSrc(2),
      },
      {
        title: t('cny25_snack_guides_love_letters_title'),
        description: t('cny25_snack_guides_love_letters_description'),
        subtitle: t('cny25_snack_guides_love_letters_subtitle'),
        image: getImageSrc(3),
      },
      {
        title: t('cny25_snack_guides_kueh_bahulu_title'),
        description: t('cny25_snack_guides_kueh_bahulu_description'),
        subtitle: t('cny25_snack_guides_kueh_bahulu_subtitle'),
        image: getImageSrc(4),
      },
      {
        title: t('cny25_snack_guides_mini_shrimp_rolls_title'),
        description: t('cny25_snack_guides_mini_shrimp_rolls_description'),
        subtitle: t('cny25_snack_guides_mini_shrimp_rolls_subtitle'),
        image: getImageSrc(5),
      },
      {
        title: t('cny25_snack_guides_kueh_bangkit_title'),
        description: t('cny25_snack_guides_kueh_bangkit_description'),
        subtitle: t('cny25_snack_guides_kueh_bangkit_subtitle'),
        image: getImageSrc(6),
      },
      {
        title: t('cny25_snack_guides_arrow_head_chips_title'),
        description: t('cny25_snack_guides_arrow_head_chips_description'),
        subtitle: t('cny25_snack_guides_arrow_head_chips_subtitle'),
        image: getImageSrc(7),
      },
      {
        title: t('cny25_snack_guides_niangao_title'),
        description: t('cny25_snack_guides_niangao_description'),
        subtitle: t('cny25_snack_guides_niangao_subtitle'),
        image: getImageSrc(8),
      },
      {
        title: t('cny25_snack_guides_honeycomb_biscuits_title'),
        description: t('cny25_snack_guides_honeycomb_biscuits_description'),
        subtitle: t('cny25_snack_guides_honeycomb_biscuits_subtitle'),
        image: getImageSrc(9),
      },
      {
        title: t('cny25_snack_guides_kueh_lapis_title'),
        description: t('cny25_snack_guides_kueh_lapis_description'),
        subtitle: t('cny25_snack_guides_kueh_lapis_subtitle'),
        image: getImageSrc(10),
      },
      {
        title: t('cny25_snack_guides_peanut_cookies_title'),
        description: t('cny25_snack_guides_peanut_cookies_description'),
        subtitle: t('cny25_snack_guides_peanut_cookies_subtitle'),
        image: getImageSrc(11),
      },
      {
        title: t('cny25_snack_guides_mandarin_oranges_title'),
        description: t('cny25_snack_guides_mandarin_oranges_description'),
        subtitle: t('cny25_snack_guides_mandarin_oranges_subtitle'),
        image: getImageSrc(12),
      },
    ]);

    const menuItems = [
      {
        label: 'cny25_snack_guides_pineapple_tarts_title',
        id: 'pineapple_tarts',
      },
      {
        label: 'cny25_snack_guides_bakkwa_title',
        id: 'bakkwa',
      },
      {
        label: 'cny25_snack_guides_love_letters_title',
        id: 'love_letters',
      },
      {
        label: 'cny25_snack_guides_kueh_bahulu_title',
        id: 'kueh_bahulu',
      },
      {
        label: 'cny25_snack_guides_mini_shrimp_rolls_title',
        id: 'mini_shrimp_rolls',
      },
      {
        label: 'cny25_snack_guides_kueh_bangkit_title',
        id: 'kueh_bangkit',
      },
      {
        label: 'cny25_snack_guides_arrow_head_chips_title',
        id: 'arrow_head_chips',
      },
      {
        label: 'cny25_snack_guides_niangao_title',
        id: 'niangao',
      },
      {
        label: 'cny25_snack_guides_honeycomb_biscuits_title',
        id: 'honeycomb_biscuits',
      },
      {
        label: 'cny25_snack_guides_kueh_lapis_title',
        id: 'kueh_lapis',
      },
      {
        label: 'cny25_snack_guides_peanut_cookies_title',
        id: 'peanut_cookies',
      },
      {
        label: 'cny25_snack_guides_mandarin_oranges_title',
        id: 'mandarin_oranges',
      },
    ];

    const sectionId: string[] = [
      'pineapple_tarts',
      'bakkwa',
      'love_letters',
      'kueh_bahulu',
      'mini_shrimp_rolls',
      'kueh_bangkit',
      'arrow_head_chips',
      'niangao',
      'honeycomb_biscuits',
      'kueh_lapis',
      'peanut_cookies',
      'mandarin_oranges',
    ];

    return {
      currentSize,
      getImageSrc,
      getSectionId,
      checkLang,

      menuItems,
      pageHeaderTitle,
      snackData,
      imageSourceOpen,
    };
  },
};
</script>
