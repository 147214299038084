<template>
  <div :class="[paddingTop ? '' : currentSize !== 'mobile' ? 'tw-pt-[3.5rem]' : 'tw-pt-[12px]']">
    <!-- Title -->
    <div v-html="formattedTitle" />
    <p
      class="tw-pt-1"
      :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
    >
      {{ titleChinese }}
    </p>
    <div
      v-if="proTipsParagraph"
      class="tw-flex tw-flex-col sgTablet:tw-gap-5 tw-gap-4"
    >
      <p
        class="tw-pt-4 tw-text-sg-sh3-mobile tablet:tw-text-sg-sh3-tablet tablet:tw-pt-5 desktop:tw-text-sg-sh3 desktop:tw-pt-5"
        :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
      >
        {{ $t('cny_visiting_pro_tips') }}
      </p>
      <div
        class="tw-max-w-[600px]"
        v-html="formattedProTips"
      />
    </div>
    <div class="tw-flex tw-flex-col tw-gap-4">
      <p
        class="tw-pt-4 tw-text-sg-sh3-mobile-cny tablet:tw-text-sg-sh3-tablet-cny tablet:tw-pt-5 desktop:tw-text-sg-sh3-cny desktop:tw-pt-5"
        :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
      >
        {{ $t('cny_visiting_significance') }}
      </p>
      <div
        class="tw-max-w-[600px]"
        :class="[currentSize === 'mobile' ? 'tw-pb-[100px]' : '']"
        v-html="formattedText"
      />
    </div>
    <div :class="[proTipsParagraph ? 'tw-pb-[100px] tablet:tw-pb-0 desktop:tw-pb-0' : '']" />
  </div>
</template>

<script lang="ts">
import { checkLang, getScreenSize } from '../../support/helper';
import { Ref, ref, onMounted, onBeforeUnmount } from 'vue';

export default {
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    titleChinese: {
      type: String,
      required: true,
      default: '',
    },
    significanceParagraph: {
      type: String,
      required: true,
      default: '',
    },
    proTipsParagraph: {
      type: String,
      required: false,
      default: null,
    },
    paddingTop: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const currentSize: Ref<string | null> = ref(getScreenSize());

    function handleResize() {
      currentSize.value = getScreenSize();
    }

    onMounted(() => {
      window.addEventListener('resize', handleResize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
    });

    return {
      currentSize,
      checkLang,
    };
  },
  computed: {
    formattedText(): string {
      const className = checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat';

      const paragraphs: string[] = this.significanceParagraph.split('\n\n');
      return paragraphs
        .map((paragraph, index) => {
          if (index === 0) {
            return `<p class="tw-text-[#C52600] tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 ${className}">${paragraph}</p>`;
          } else {
            return `<p class="tw-text-[#C52600] tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 tw-pt-6 ${className}">${paragraph}</p>`;
          }
        })
        .join('');
    },
    formattedTitle(): string {
      const className = checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold';
      const title: string[] = this.title.split('\n');
      return title
        .map((paragraph) => {
          return ` <p class="tw-text-[#C52600] tw-text-sg-sh1-mobile-cny sgTablet:tw-text-sg-sh1-tablet-cny sgDesktop:tw-text-sg-sh1-cny ${className}"
        :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'">${paragraph}</p>`;
        })
        .join('');
    },
    formattedProTips(): string {
      const className = checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat';
      const paragraphs: string[] = this.proTipsParagraph.split('\n\n');
      return paragraphs
        .map((paragraph, index) => {
          if (index === 0) {
            return `<p class="tw-text-[#C52600] tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 ${className}">${paragraph}</p>`;
          } else {
            return `<p class="tw-text-[#C52600] tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 tw-pt-6 ${className}">${paragraph}</p>`;
          }
        })
        .join('');
    },
  },
};
</script>

<style scoped>
p {
  color: #c52600;
}
</style>
