<template>
  <div
    id="cny-conversations"
    class="tw-bg-[#F8F7ED] tw-relative tw-flex tw-flex-col tw-justify-between tw-items-center tw-py-5 sgTablet:tw-pt-10 sgTablet:tw-pb-[60px] tw-px-4 sgTablet:tw-px-6"
  >
    <div class="tw-h-[572px] tw-rounded-lg tw-border-2 tw-border-[#E1907D] tw-w-full tw-max-w-[1000px] tw-relative tw-bg-white tw-overflow-y-hidden">
      <div class="tw-bg-[#C52600] tw-p-5 tw-rounded-t-md">
        <h2
          class="tw-text-white tw-text-sg-sh1-mobile-cny sgTablet:tw-text-sg-sh1-tablet-cny sgDesktop:tw-text-sg-sh1-cny"
          :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
        >
          {{ $t('cny_conversation_window_title') }}
        </h2>
      </div>

      <div class="tw-flex tw-flex-row tw-relative">
        <div
          id="sidebar"
          class="tw-overflow-y-auto tw-overflow-x-hidden tw-transition-all tw-ease-in-out tw-duration-800 tw-h-[500px] tw-flex tw-flex-row tw-absolute tw-z-10 tw-top-0 tw-left-0 sgDesktop:tw-relative tw-w-20 sgDesktop:tw-w-[35%]"
        >
          <div class="tw-bg-[#E1907D] tw-w-full tw-h-full tw-flex tw-flex-col">
            <div
              v-for="(conversationType, index) in conversationTypes"
              :key="index"
              :class="[
                'tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-py-4 sgDesktop:tw-pl-[12px] tw-text-sg-sh3-mobile tablet:tw-text-sg-sh3-tablet desktop:tw-text-sg-sh3 sgDesktop:tw-pr-4 conversationType tw-mb-[2px] sgDesktop:tw-min-w-[221px]',
                selectedTypeStyle(conversationType.id),
                {
                  'tw-min-w-[221px]': convoDrawer === true,
                },
              ]"
              @click="changeType(conversationType.id)"
            >
              <div class="sidebarContent tw-bg-white tw-rounded-full tw-w-8 tw-h-8 sgDesktop:tw-w-9 sgDesktop:tw-h-9 tw-ml-3 sgDesktop:tw-ml-0">
                <img :src="$cdn(conversationType.imgSrc)">
              </div>
              <span
                class="tw-mx-4 tw-hidden sgDesktop:tw-flex tw-transition-opacity tw-ease-in-out tw-duration-1000 !tw-font-normal"
                :class="{ '!tw-flex': convoDrawer === true, 'tw-font-notosansbold': checkLang() == 'zh-hans', 'tw-font-akkuratbold': checkLang() == 'en' }"
              >{{ $t(conversationType.name) }}</span>
            </div>
          </div>
          <div
            class="tw-flex sgDesktop:tw-hidden tw-relative tw-h-full tw-w-[22px] -tw-z-10"
            @click="expandDrawer()"
          >
            <div
              class="side-bar-button tw-h-[52px] tw-bg-[#E1907D] tw-w-[22px] tw-pt-[19px] tw-pb-4 tw-cursor-pointer tw-rounded-r-lg tw-absolute tw-top-[240px]"
            >
              <inline-svg
                class="tw-fill-ap-black tw-h-3 tw-w-3 tw-pl-[3px]"
                :src="$cdn(convoDrawer === true ? 'icons/arrow_chevron_left.svg' : 'icons/arrow_chevron_right.svg')"
                alt="Arrow Right"
              />
            </div>
          </div>
        </div>
        <div
          id="general-content"
          class="tw-w-full tw-h-full tw-max-h-[500px] tw-p-5 tw-pl-8 tw-flex tw-flex-wrap tw-overflow-y-scroll tw-ml-[50px] sgDesktop:tw-pl-5"
          @scroll="closeSideBar"
        >
          <div
            v-if="selectedType != 5"
            class="tw-w-full tw-h-full"
          >
            <div
              v-for="(convoPoints, index) in mainConvo"
              :key="index"
              class="tw-flex tw-flex-row tw-pb-2 tw-w-full tw-pl-10 sgTablet:tw-pl-[100px] sgDesktop:tw-pl-0"
            >
              <div class="tw-w-[90%] tw-min-h-[80px] tw-bg-[#FFF9EE] tw-rounded-lg">
                <div class="tw-flex tw-flex-col sgTablet:tw-flex-row">
                  <div
                    v-if="convoPoints?.conversation_image"
                    class="tw-pt-2 tw-pl-1 sgTablet:tw-pt-0 tw-max-h-[100px] tw-max-w-[175px] tablet:tw-max-w-[100px] tw-self-center"
                  >
                    <img
                      :src="convoPoints?.conversation_image"
                      class="tw-m-auto sgTablet:tw-m-0 sgTablet:tw-pt-0 tw-max-h-[88px] tw-max-w-[175px] tablet:tw-max-w-[100px]"
                    >
                  </div>
                  <div
                    class="tw-w-[100%] tw-p-3 sgTablet:tw-py-5 sgTablet:tw-pl-3 sgTablet:tw-pr-5"
                    :class="{ 'tw-flex tw-items-center': convoPoints?.external_link === '' }"
                  >
                    <div
                      class="tw-text-sg-sh3-mobile-cny tablet:tw-text-sg-sh3-tablet-cny desktop:tw-text-sg-sh3-cny tw-flex tw-flex-col tw-gap-5"
                      :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                      v-html="convoPoints?.conversation_content"
                    />
                    <div
                      v-if="convoPoints?.external_link != ''"
                      class="tw-pt-3 tw-flex tw-justify-center sgTablet:tw-justify-end"
                    >
                      <a
                        class="tw-h-[32px] tw-py-[5px] tw-px-0 sg:tablet:tw-px-3 tw-w-fit tw-text-[#8C12D4] tw-cursor-pointer hover:tw-underline"
                        :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                        :href="convoPoints?.external_link"
                        target="'blank'"
                      >
                        {{ $t(getFindOutMoreText(convoPoints?.external_link)) }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tw-w-[52px] sgTablet:tw-w-[56px] tw-py-5 tw-flex tw-flex-row">
                <div class="tw-h-full tw-w-full tw-max-h-[32px] tw-max-w-[16px] tw-mr-2">
                  <svg
                    v-show="index == 0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="32"
                    viewBox="0 0 16 32"
                    fill="none"
                  >
                    <path
                      d="M16 16L0 0L0 32L16 16Z"
                      fill="#FFF9EE"
                    />
                  </svg>
                </div>
                <div class="tw-h-full tw-w-full tw-max-h-[32px] tw-max-w-[32px]">
                  <img
                    v-show="index == 0"
                    class="tw-min-w-[16px]"
                    :src="$cdn('images/cny2024/conversation-starters/anon-user.png')"
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- Counters -->
          <div
            v-else
            id="counter-content"
            class="tw-w-full tw-h-full"
            @scroll="closeSideBar"
          >
            <div
              v-for="(convoPoints, index) in mainConvo"
              :key="index"
              :class="['tw-flex tw-flex-row tw-pb-2 tw-w-full', !convoPoints.is_left ? 'tw-pl-10 sgTablet:tw-pl-[100px]' : 'tw-pr-10 sgTablet:tw-pr-[100px]']"
            >
              <div
                v-if="convoPoints.is_left"
                class="tw-w-[52px] sgTablet:tw-w-[56px] tw-py-5 tw-flex tw-flex-row tw-justify-end"
              >
                <div class="tw-h-full tw-w-full tw-max-h-[32px] tw-max-w-[32px]">
                  <img
                    :src="$cdn('images/cny2024/conversation-starters/anon-user.png')"
                    class="tw-min-w-[16px]"
                  >
                </div>
                <div class="tw-h-full tw-w-full tw-max-h-[32px] tw-max-w-[16px] tw-ml-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="32"
                    viewBox="0 0 16 32"
                    fill="none"
                  >
                    <path
                      d="M0 16L16 32V0L0 16Z"
                      fill="#FFD68C"
                    />
                  </svg>
                </div>
              </div>
              <div :class="['tw-w-[90%] tw-min-h-[80px] tw-rounded-lg sgDesktop:tw-p-[10px]', !convoPoints.is_left ? 'tw-bg-[#FFF9EE]' : 'tw-bg-[#FFD68C]']">
                <div class="tw-flex tw-flex-col-reverse sgTablet:tw-flex-row">
                  <div class="tw-w-full tw-p-3 sgTablet:tw-py-5 sgTablet:tw-pl-3 sgTablet:tw-pr-5 tw-flex tw-flex-wrap tw-content-center">
                    <div
                      class="tw-text-sg-sh3-mobile tablet:tw-text-sg-sh3-tablet desktop:tw-text-sg-sh3"
                      v-html="convoPoints?.conversation_content"
                    />
                  </div>
                  <div
                    v-if="convoPoints.is_left && convoPoints?.conversation_image"
                    class="tw-pt-2 sgTablet:tw-pt-0 tw-max-h-[80px] tw-max-w-[100px] tw-self-center"
                  >
                    <img
                      :src="convoPoints?.conversation_image"
                      class="tw-m-auto sgTablet:tw-m-0 xtw-pt-3 sgTablet:tw-pt-0 tw-max-h-[80px] tw-max-w-[100px]"
                    >
                  </div>
                </div>
              </div>
              <div
                v-if="!convoPoints.is_left"
                class="tw-w-[52px] sgTablet:tw-w-[56px] tw-py-5 tw-flex tw-flex-row tw-justify-start"
              >
                <div class="tw-h-full tw-w-full tw-max-h-[32px] tw-max-w-[16px] tw-mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="32"
                    viewBox="0 0 16 32"
                    fill="none"
                  >
                    <path
                      d="M16 16L0 0L0 32L16 16Z"
                      fill="#FFF9EE"
                    />
                  </svg>
                </div>
                <div class="tw-h-full tw-w-full tw-max-h-[32px] tw-max-w-[32px]">
                  <img
                    :src="$cdn('images/cny2024/conversation-starters/anon-user.png')"
                    class="tw-min-w-[16px]"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount } from 'vue';
import { onMounted, ref } from 'vue';
import { checkLang } from '../../../support/helper';

const props = withDefaults(
  defineProps<{
    conversationStarters?: any | null;
  }>(),
  {
    conversationStarters: null,
  }
);

const getFindOutMoreText = (link: string) => {
  if (link.includes('music')) {
    return 'cny25_click_to_listen';
  } else {
    return 'cny25_find_out_more';
  }
};

interface Conversation {
  conversation_content: string;
  conversation_image: string;
  external_link: string;
  is_left: boolean;
}

interface ConversationType {
  id: number;
  name: string;
  imgSrc: string;
}

const conversationTypes: ConversationType[] = [
  {
    id: 1,
    name: 'cny_conversation_older_generation',
    imgSrc: 'images/cny2024/conversation-starters/older-gen.png',
  },
  {
    id: 2,
    name: 'cny_conversation_young_adults',
    imgSrc: 'images/cny2024/conversation-starters/young-adult.png',
  },
  {
    id: 3,
    name: 'cny_conversation_youths',
    imgSrc: 'images/cny2024/conversation-starters/youths.png',
  },
  {
    id: 4,
    name: 'cny_conversation_kids',
    imgSrc: 'images/cny2024/conversation-starters/kids.png',
  },
  {
    id: 5,
    name: 'cny_conversation_counters',
    imgSrc: 'images/cny2024/conversation-starters/counters.png',
  },
];

const olderGenConvo = ref<Conversation[]>([]);
const youngAdultConvo = ref<Conversation[]>([]);
const youthsConvo = ref<Conversation[]>([]);
const kidsConvo = ref<Conversation[]>([]);
const countersConvo = ref<Conversation[]>([]);
const mainConvo = ref<Conversation[]>([]);

let convoTimeout = ref();

const selectedType = ref(1);

const convoDrawer = ref(false);

const isMobileTabletView = ref(window.innerWidth > 1024 ? false : true);

onMounted(() => {
  window.addEventListener('resize', handleStarterWindowResize);

  populateConvoData();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleStarterWindowResize);
});

const handleStarterWindowResize = () => {
  if (window.innerWidth < 1025) {
    isMobileTabletView.value = true;
  }
};

function populateConvoData() {
  props.conversationStarters.forEach((convo: any) => {
    if (convo.type == 'Older Generation') {
      olderGenConvo.value = convo.data;
    } else if (convo.type == 'Young Adults') {
      youngAdultConvo.value = convo.data;
    } else if (convo.type == 'Youths') {
      youthsConvo.value = convo.data;
    } else if (convo.type == 'Kids') {
      kidsConvo.value = convo.data;
    } else if (convo.type == 'Counters') {
      countersConvo.value = convo.data;
    }
  });

  changeConvoList();
}

function selectedTypeStyle(id: number) {
  if (id == selectedType.value) {
    return 'tw-bg-[#DA745C] tw-font-bold';
  } else {
    return '';
  }
}

function changeType(id: number) {
  selectedType.value = id;
  changeConvoList();
  const generalContentDiv = document.getElementById('general-content');
  const counterContentDiv = document.getElementById('counter-content');

  if (generalContentDiv) {
    generalContentDiv.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  if (counterContentDiv) {
    counterContentDiv.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}

function changeConvoList() {
  if (selectedType.value == 1) {
    mainConvo.value = olderGenConvo.value;
  } else if (selectedType.value == 2) {
    mainConvo.value = youngAdultConvo.value;
  } else if (selectedType.value == 3) {
    mainConvo.value = youthsConvo.value;
  } else if (selectedType.value == 4) {
    mainConvo.value = kidsConvo.value;
  } else if (selectedType.value == 5) {
    mainConvo.value = countersConvo.value;
  }
}

function expandDrawer() {
  let convoSidebar = document.getElementById('sidebar');
  if (isMobileTabletView.value && !!convoSidebar) {
    if (convoSidebar.classList.contains('tw-w-20')) {
      convoSidebar.classList.remove('tw-w-20');
      clearTimeout(convoTimeout.value);
      convoTimeout.value = setTimeout(() => {
        convoSidebar.classList.add('tw-w-58');
      }, 2);
      convoDrawer.value = true;
    } else {
      convoSidebar.classList.remove('tw-w-58');
      convoSidebar.classList.add('tw-w-20');
      convoDrawer.value = false;
    }
  } else {
    convoSidebar?.classList.add('sgDesktop:tw-w-[35%]');
  }
}

const closeSideBar = () => {
  if (convoDrawer.value === true) {
    expandDrawer();
  }
};
</script>

<style scoped>
.conversationType:hover {
  background-color: #da745c;
  cursor: pointer;
}

.side-bar-button {
  border-radius: 0px, 4px, 4px, 0px !important;
  box-shadow: -4px 4px 10px 0px rgb(0, 0, 0, 0.3);
}
</style>
