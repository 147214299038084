<template>
  <!-- Image Source Box -->
  <div
    class="image-source-box-shadow tw-bg-[#F4F4F4] hover:tw-bg-[#e7e7e7]"
  >
    <div
      class="tw-py-5 hover:tw-cursor-pointer"
      :class="checkLang() == 'zh-hans'? 'tw-font-notosans' :'tw-font-akkurat'"
      @click="handleImageSourceOpen"
    >
      <p
        class="tw-uppercase tw-text-sg-sccc1 tw-px-4 tw-py-[7px] tw-text-sg-ma-bt1 tw-text-center tw-w-full"
        :class="[
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
      >
        {{ $t('qm_image_source') }}
      </p>
    </div>

    <!-- Image Source Modal -->
    <Teleport to="body">
      <div
        id="modal"
        class="tw-fixed tw-hidden tw-bottom-0 tw-right-0 tw-bg-black/75 tw-bg-opacity-75 tw-items-center tw-justify-center tw-z-[99999] tw-overflow-hidden tw-w-full tw-h-screen"
        :class="{'!tw-flex': modelValue == true}"
        @keydown="handleKeyDown"
        @click.self="handleModalClose"
      >
        <div class="tw-h-4/6 tw-fixed tw-bottom-0 tw-right-0 tw-w-full">
          <!-- Cancel  -->
          <div class="tw-relative">
            <div class="tw-w-full tw-flex tw-flex-row tw-justify-end tw-bottom-5 tw-absolute">
              <div
                class="tw-inline-flex tw-text-ap-snow tw-cursor-pointer tw-group tw-pr-4"
                @click="handleModalClose"
              >
                <p class="tw-uppercase tw-mr-1 group-hover:tw-underline">
                  {{ $t('qm_modal_close') }}
                </p>
                <img
                  :src="$cdn('icons/cancel_icon_white.svg')"
                  alt="cancel_icon"
                >
              </div>
            </div>
          </div>

          <Transition name="sources-fade">
            <div
              v-if="modelValue"
              class="tw-h-full tw-flex tw-flex-col"
            >
              <!-- Header -->
              <div class="tw-bg-[#F4F4F4] tw-px-4 tw-py-5 sgTablet:tw-pt-10 sgTablet:tw-pb-5 sgDesktop:tw-px-20 sgTablet:tw-px-10 ">
                <h3 :class="titleStyle? titleStyle : checkLang() == 'zh-hans'? 'tw-text-sg-h2-mobile-dw-zh sgTablet:tw-text-sg-h2-tablet-dw-zh sgDesktop:tw-text-sg-h2-dw-zh tw-font-notosansbold': 'tw-text-sg-h2-mobile-dw sgTablet:tw-text-sg-h2-tablet-dw sgDesktop:tw-text-sg-h2-dw  tw-font-akkuratbold'">
                  {{ $t('qm_image_source') }}
                </h3>
              </div>

              <!-- Content -->
              <div
                class="tw-flex-1 tw-h-full tw-overflow-y-auto tw-bg-[#FFF]
              tw-py-5  tw-pl-4 sgTablet:tw-pl-20 tw-pr-5
              "
              >

                <article
                  class="
                    tw-max-w-none
                    tw-prose
                    !tw-font-akkurat
                    tw-text-sg-caption tablet:tw-text-sg-caption desktop:tw-text-sg-caption
                    prose-em:tw-italic prose-em:tw-text-sg-caption prose-p:tw-m-0 prose-h4:tw-text-sg-caption tablet:prose-h4:tw-text-sg-caption desktop:prose-h4:tw-text-sg-caption prose-li:tw-my-0 prose-h2:tw-text-sg-sh1
                    tablet:prose-h4:tw-mb-5

                    !tw-leading-5
                    tw-tracking-wide
                                      "
                  v-html="$props.data"
                />
                <!-- tw-text-sg-body-2-mobile tablet:tw-text-sg-body-2-tablet desktop:tw-text-sg-body-2 -->

              </div>
            </div>
          </Transition>
        </div>
      </div>
    </Teleport>

  </div>


</template>

<script setup lang="ts">
import { watch } from 'vue';
import { onMounted, computed, onBeforeUnmount } from 'vue';
import { checkLang } from '../../support/helper';
const props = defineProps<{
  modelValue: boolean,
  data: string|undefined,
  titleStyle?: string
}>();

const emit = defineEmits(['update:modelValue']);

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

watch(() => modelValue.value, () => {
  if(modelValue.value === true)
  {
    document.body.style.overflow = 'hidden'; // Disable body scrolling when modal is open
  } else {
    document.body.style.overflow = ''; // Enable body scrolling when modal is closed
  }
});

const handleKeyDown = (event: any) => {
  if (event.key === 'Escape') {
    modelValue.value = false;
  }
};

const handleModalClose = () => {
  modelValue.value = false;
};

const handleImageSourceOpen = () => {
  modelValue.value = true;
};


const imageSource = `<div>
  123Testing Image Source
</div>`;
</script>

<style>
.image-source-box-shadow {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05) inset;
}
</style>
