import Page from './page.vue';
import PageAboutUs from './page-about-us.vue';
import Single from './single.vue';
import PageHome from './page-home.vue';
import Page404 from './page-404.vue';
import PageRoots from './page-roots.vue';
import PageFood from './page-food.vue';
import PageValues from './page-values.vue';
import PagePractices from './page-practices.vue';
import PageLanguage from './page-language.vue';
import PageArts from './page-arts.vue';
import PageVisitorInformation from './exhibition/page-visitor-information.vue';
import PageGalleryEtiquette from './exhibition/page-gallery-etiquette.vue';
import PageAbout from './exhibition/page-about.vue';
import PageExhibitionMain from './exhibition/page-exhibition-main.vue';
// Values
import PageValuesLoyalty from './values/values-loyalty.vue';
import PageValuesConsiderateBehaviour from './values/values-considerate-behaviour.vue';
import PageValuesFilialPiety from './values/values-filial-piety.vue';
import PageValuesIntegrity from './values/values-integrity.vue';
import PageValuesKindness from './values/values-kindness.vue';
import PageValuesLove from './values/values-love.vue';
import PageValuesPerserverance from './values/values-perserverance.vue';
import PageValuesRighteousness from './values/values-righteousness.vue';
import PageValuesSenseOfShame from './values/values-sense-of-shame.vue';
import PageValuesThriftiness from './values/values-thriftiness.vue';
// End of Values
// Song
import PageLiangWernFook from './song/page-liang-wern-fook.vue';
// Poem
import PageKhooSeokWan from './poem/page-khoo-seok-wan.vue';
// Template
import PageGames from './games/page-games.vue';
import TemplateArticle from './template/page-template-article.vue';
import CeEventTemplate from './template/page-event-template.vue';
import CeVideoTemplate from './template/page-video-template.vue';
import RemixEventTemplate from './template/page-remix-event-template.vue';

// Chinese New Year
import PageChineseNewYear from './cny/page-chinese-new-year.vue';
import PageGoodLuckGuides from './cny/page-good-luck-guides.vue';
import PageOriginStories from './cny/page-origin-stories.vue';
import pageCnyAbout from './cny/page-cny-about.vue';
import PageCnyYusheng from './cny/page-cny-yusheng.vue';
import pageFamilyFun from './cny/page-family-fun.vue';
import PageRedPacket from './cny/page-red-packet.vue';
import pageWhatsOpen from './cny/page-whats-open.vue';
import pageSnackGuides from './cny/page-snack-guides.vue';
import pageConversationStarters from './cny/page-conversation-starters.vue';

// Chinese New Year 2025
import PageCny25Home from './cny25/page-chinese-new-year-25.vue';
import PageFamilyFun25 from './cny25/page-family-fun-25.vue';
import PageGoodLuckGuides25 from './cny25/page-good-luck-guides.vue';
import PageOriginStories25 from './cny25/page-origin-stories.vue';
import PageCnyAbout25 from './cny25/page-cny-about.vue';
import PageCnyYusheng25 from './cny25/page-cny-yusheng.vue';
import PageRedPacket25 from './cny25/page-red-packet.vue';
import PageWhatsOn25 from './cny25/page-whats-on.vue';
import PageConversationStarters25 from './cny25/page-conversation-starters.vue';
import PageFamilyTree25 from './cny25/page-greet-them-right.vue';
import PageGreetings25 from './cny25/page-cny-greetings.vue';
import PageSnackGuides25 from './cny25/page-snack-guides.vue';
import PageMusic25 from './cny25/page-music.vue';
import PagePersonalityQuiz25 from './cny25/page-cny-personality-quiz.vue';

// TnC
import pageTermOfUse from './term-of-use/page-term-of-use.vue';
// Festivals Overview
import PageFestivalsOverview from './page-festivals.vue';

//Roots
import PageRootsChineseNames from './roots/roots-chinese-names.vue';
import PageRootsDiscoverTenPolicies from './roots/roots-discover-ten-policies.vue';
import PageRootsFiveAspectsCulture from './roots/roots-five-aspects-culture.vue';
import pageMusic from './cny/page-music.vue';
import pageGreetThemRight from './cny/page-greet-them-right.vue';
import pageCnyGreetings from './cny/page-cny-greetings.vue';
import pageCnyPersonalityQuiz from './cny/page-cny-personality-quiz.vue';
import PageQmOriginStories from './qm/page-qm-origin-stories.vue';
import PageQmHome from './qm/page-qm-home.vue';
import PageQmAbout from './qm/page-qm-about.vue';
import PageQmExploreOnline from './qm/page-qm-explore-online.vue';
import PageQmQingming101 from './qm/page-qm-qingming-101.vue';


// Experience
import PageCeAbout from './experience/ce/page-ce-about.vue';
import PageCeEvent from './experience/ce/page-ce-event.vue';
import PageCeVideo from './experience/ce/page-ce-video.vue';
import PageDwHome from './dw/page-dw-home.vue';
import PageDwAbout from './dw/page-dw-about.vue';
import PageDwExploreOnline from './dw/page-dw-explore-online.vue';
import PageDwDuanwu101 from './dw/page-dw-duanwu-101.vue';
import PageDwPersonalityQuiz from './dw/page-dw-personality-quiz.vue';
import PageDwOriginStories from './dw/page-dw-origin-stories.vue';
import PageDwWhatsOpen from './dw/page-dw-whats-open.vue';

// Zhongyuan
import pageZyAbout from './zy/page-zy-about.vue';
import PageZyHome from './zy/page-zy-home.vue';
import PageZyExploreOnline from './zy/page-zy-explore-online.vue';
import PageZyZy101 from './zy/page-zy-zhongyuan-101.vue';
import PageZyOriginStories from './zy/page-zy-origin-stories.vue';

// Mid Autumn
import PageMaHome from './ma/page-ma-home.vue';
import PageMaFamilyFun from './ma/page-ma-family-fun.vue';
import PageMaAbout from './ma/page-ma-about.vue';
import PageMaOriginStories from './ma/page-ma-origin-stories.vue';
import PageMaExploreOnline from './ma/page-ma-explore-online.vue';
import PageMaMidAutumn101 from './ma/page-ma-mid-autumn-101.vue';
import PageMaWhatsOpen from './ma/page-ma-whats-open.vue';
import PageMaPersonalityQuiz from './ma/page-ma-personality-quiz.vue';

// Remix
import PageRemixAbout from './experience/remix/page-remix-about.vue';
import PageRemixEvent from './experience/remix/page-remix-event.vue';
import PageRemixPersonalityQuiz from './experience/remix/page-remix-personality-quiz.vue';

// Winter Solstice
import PageWsHome from './ws/page-ws-home.vue';
import PageWsAbout from './ws/page-ws-about.vue';
import PageWsOriginStories from './ws/page-ws-origin-stories.vue';
import PageWsExploreOnline from './ws/page-ws-explore-online.vue';
import PageWsWinterSolstice101 from './ws/page-ws-101.vue';


export default {
  page: Page,
  'page-about-us': PageAboutUs,
  single: Single,
  'page-home': PageHome,
  'page-roots': PageRoots,
  'page-food': PageFood,
  'page-values': PageValues,
  'page-404': Page404,
  // Values
  'page-values-loyalty': PageValuesLoyalty,
  'page-values-considerate-behaviour': PageValuesConsiderateBehaviour,
  'page-values-filial-piety': PageValuesFilialPiety,
  'page-values-integrity': PageValuesIntegrity,
  'page-values-kindness': PageValuesKindness,
  'page-values-love': PageValuesLove,
  'page-values-perserverance': PageValuesPerserverance,
  'page-values-righteousness': PageValuesRighteousness,
  'page-values-sense-of-shame': PageValuesSenseOfShame,
  'page-values-thriftiness': PageValuesThriftiness,
  // End of Values
  'page-practices': PagePractices,
  'page-language': PageLanguage,
  'page-arts': PageArts,
  'page-games': PageGames,
  'page-template-article': TemplateArticle,
  'page-visitor-information': PageVisitorInformation,
  'page-gallery-etiquette': PageGalleryEtiquette,
  'page-about': PageAbout,
  'page-exhibition-main': PageExhibitionMain,
  'page-liang-wern-fook': PageLiangWernFook,
  'page-khoo-seok-wan': PageKhooSeokWan,
  'page-chinese-new-year': PageChineseNewYear,
  'page-good-luck-guides': PageGoodLuckGuides,
  'page-origin-stories': PageOriginStories,
  'page-cny-about': pageCnyAbout,
  'page-cny-yusheng': PageCnyYusheng,
  'page-red-packet': PageRedPacket,
  'page-family-fun': pageFamilyFun,
  'page-whats-open': pageWhatsOpen,
  'page-term-of-use': pageTermOfUse,
  'page-festivals-overview': PageFestivalsOverview,
  'page-music': pageMusic,
  'page-greet-them-right': pageGreetThemRight,
  'page-cny-greetings': pageCnyGreetings,
  'page-snack-guides': pageSnackGuides,
  'page-cny-personality-quiz': pageCnyPersonalityQuiz,
  'page-cny-conversation-starters': pageConversationStarters,

  // Chinese New Year 2025
  'page-chinese-new-year-25': PageCny25Home,
  'page-family-fun-25': PageFamilyFun25,
  'page-good-luck-guides-25': PageGoodLuckGuides25,
  'page-origin-stories-25': PageOriginStories25,
  'page-cny-about-25': PageCnyAbout25,
  'page-cny-yusheng-25': PageCnyYusheng25,
  'page-red-packet-25': PageRedPacket25,
  'page-whats-on-25': PageWhatsOn25,
  'page-cny-conversation-starters-25': PageConversationStarters25,
  'page-greet-them-right-25': PageFamilyTree25,
  'page-cny-greetings-25': PageGreetings25,
  'page-snack-guides-25': PageSnackGuides25,
  'page-music-25': PageMusic25,
  'page-cny-personality-quiz-25': PagePersonalityQuiz25,

  //Roots
  'page-roots-chinese-names': PageRootsChineseNames,
  'page-roots-discover-ten-policies': PageRootsDiscoverTenPolicies,
  'page-roots-five-aspects-culture': PageRootsFiveAspectsCulture,

  //Qing Ming
  'page-qm-origin-stories': PageQmOriginStories,
  'page-qm-home': PageQmHome,
  'page-qm-about': PageQmAbout,
  'page-qm-explore-online': PageQmExploreOnline,
  'page-qm-qingming-101': PageQmQingming101,
  // Experience
  'page-ce-about': PageCeAbout,
  'page-ce-event': PageCeEvent,
  'page-event-template': CeEventTemplate,
  'page-ce-video': PageCeVideo,
  'page-video-template': CeVideoTemplate,

  //Duanwu
  'page-dw-home':PageDwHome,
  'page-dw-about':PageDwAbout,
  'page-dw-explore-online':PageDwExploreOnline,
  'page-dw-duanwu-101':PageDwDuanwu101,
  'page-dw-personality-quiz':PageDwPersonalityQuiz,
  'page-dw-origin-stories':PageDwOriginStories,
  'page-dw-whats-open':PageDwWhatsOpen,

  // Zhongyuan
  'page-zy-about': pageZyAbout,
  'page-zy-home': PageZyHome,
  'page-zy-explore-online': PageZyExploreOnline,
  'page-zy-zhongyuan-101': PageZyZy101,
  'page-zy-origin-stories': PageZyOriginStories,

  // Mid Autumn
  'page-ma-home': PageMaHome,
  'page-ma-family-fun': PageMaFamilyFun,
  'page-ma-about': PageMaAbout,
  'page-ma-origin-stories': PageMaOriginStories,
  'page-ma-explore-online': PageMaExploreOnline,
  'page-ma-mid-autumn-101': PageMaMidAutumn101,
  'page-ma-whats-open': PageMaWhatsOpen,
  'page-ma-personality-quiz': PageMaPersonalityQuiz,

  // REMIX
  'page-remix-about': PageRemixAbout,
  'page-remix-event': PageRemixEvent,
  'page-remix-event-template': RemixEventTemplate,
  'page-remix-personality-quiz': PageRemixPersonalityQuiz,

  // Winter Solstice
  'page-ws-home': PageWsHome,
  'page-ws-about': PageWsAbout,
  'page-ws-origin-stories': PageWsOriginStories,
  'page-ws-explore-online': PageWsExploreOnline,
  'page-ws-101': PageWsWinterSolstice101,
};
