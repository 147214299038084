<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :cny-25-navigation="true"
      :cny25-p2-navigation="wordpressData?.unlock_sidebar"
    />
    <secondary-navigation :menu-items-data="menuItems" />
    <!-- Header -->
    <cny-25-header-banner
      :title="'cny25_greet_them_right_title'"
      :description="'cny25_greet_them_right_subtitle'"
    />
    <!-- Questionnaire Section -->
    <section
      id="questionnare-section"
      class="tw-relative tw-bg-white tw-px-4 tw-py-5 sgTablet:tw-py-10"
    >
      <div
        class="tw-text-center"
      >
        <div class="tw-flex tw-flex-row tw-justify-center tw-gap-5">
          <img
            class="tw-h-[80px] tw-absolute tw-left-4 sgTablet:tw-left-10 sgDesktop:tw-left-20 sgDesktop2:tw-relative sgDesktop2:tw-left-0 sgTablet:tw-h-[120px] tw-top-5 sgTablet:tw-top-10"
            :src="$cdn('images/cny2024/greet-them-right/yi_fu.png')"
          >
          <div class="tw-flex tw-flex-col tw-gap-10 tw-max-w-[800px] tw-items-center tw-w-full">
            <h2
              class="tw-max-w-[600px] tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop tw-text-[#C52600]"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              v-html="$t('cny25_greet_them_right_section_1_title')"
            />
            <p
              class="tw-max-w-[600px] tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 tw-tracking-[0.8px]"
              v-html="$t('cny25_greet_them_right_section_1_desc')"
            />
            <!-- Let's Start Section -->
            <div class="tw-flex tw-justify-center tw-mt-5 sgTablet:tw-mt-10">
              <img
                class="tw-mt-[47px] tw-mr-4 tw-hidden sgTablet:tw-block tw-h-[80px]"
                :src="$cdn('images/cny2024/greet-them-right/jiu_gong.png')"
              >
              <div>
                <button
                  class="tw-h-[56px] tw-py-3 tw-px-7 tw-rounded-[56px] tw-relative tw-min-w-[200px] tw-bg-[#C52600] hover:tw-bg-[#E1907D] active:tw-bg-[#9E1E00] tw-text-white hover:tw-text-[#9E1E00] active:tw-text-white tw-text-sg-sh1-mobile-cny sgTablet:tw-text-sg-sh1-tablet-cny sgDesktop:tw-text-sg-sh1-cny tw-cursor-pointer !tw-font-[400] disabled:tw-bg-[#D1D1D1] disabled:tw-text-white disabled:tw-cursor-not-allowed before:tw-content-[''] before:tw-absolute before:tw-top-full before:tw-left-1/2 before:-tw-translate-x-1/2 before:tw-border-[10px] before:tw-border-transparent before:tw-border-t-[#C52600] hover:before:tw-border-t-[#E1907D] active:before:tw-border-t-[#9E1E00] before:disabled:tw-border-t-[#D1D1D1] before:tw-border-t-solid"
                  :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                  @click="openQuizModal = true"
                >
                  {{ $t('cny_greet_them_right_lets_start') }}
                </button>
                <img
                  class="tw-mt-2 tw-mx-auto"
                  :src="$cdn('images/cny2024/greet-them-right/lion.png')"
                >
              </div>
              <img
                class="tw-mt-[47px] tw-ml-4 tw-hidden sgTablet:tw-block tw-h-[80px]"
                :src="$cdn('images/cny2024/greet-them-right/shen_shen.png')"
              >
            </div>
          </div>
          <img
            class="tw-h-[80px] tw-absolute tw-right-4 sgTablet:tw-right-10 sgDesktop:tw-right-20 sgDesktop2:tw-relative sgDesktop2:tw-right-0 sgTablet:tw-h-[120px] tw-top-5 sgTablet:tw-top-10"
            :src="$cdn('images/cny2024/greet-them-right/jiu_mu.png')"
          >
        </div>

      </div>
      <!-- CNY Family Tree Quiz Modal -->
      <cny-25-family-tree-quiz-modal v-model="openQuizModal" />
    </section>
    <!-- Family Tree Section -->
    <CNY25FloatingDivider />
    <section
      id="family-tree-section"
      class="tw-relative tw-px-4 sgTablet:tw-px-10 sgDesktop:tw-px-20 tw-pt-10 tw-pb-5 sgTablet:tw-pb-10"
      :style="{
        background: 'linear-gradient(180deg, #FFD68C 0%, #FFF 100%)',
      }"
    >
      <div class="tw-flex tw-flex-col sgTablet:tw-gap-10 tw-gap-5">
        <h2
          class="tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop tw-text-[#C52600] tw-text-center"
          :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
        >
          {{ $t('cny_greet_them_right_section_2_title') }}
        </h2>

        <div class="tw-grid tw-grid-cols-2 tw-gap-5 sgTablet:tw-gap-8 sgDesktop:tw-gap-10 tw-max-w-[800px] tw-w-full tw-mx-auto">
          <div class="tw-col-span-2 sgTablet2:tw-col-span-1">
            <img :src="$cdn('/images/cny2024/greet-them-right/family-tree.jpeg')">
          </div>
          <div class="tw-col-span-2 sgTablet2:tw-col-span-1">
            <p
              class="tw-text-sg-cny25-body tw-text-center sgTablet:tw-text-left tw-text-[#1A1A1A] tw-tracking-[0.8px]"
              v-html="$t('cny25_greet_them_right_section_2_desc_1')"
            />
            <br>
            <p
              class="tw-text-sg-cny25-body tw-text-center sgTablet:tw-text-left tw-text-[#1A1A1A] tw-tracking-[0.8px]"
              v-html="$t('cny25_greet_them_right_section_2_desc_2')"
            />

            <div class="tw-mt-8 sgTablet:tw-mt-10 sgTablet:tw-flex tw-text-center sgTablet:tw-justify-center">
              <button
                class="tw-rounded-[26px] tw-h-[52px] tw-py-4 tw-px-6 sgTablet2:tw-px-4 sgDesktop:tw-px-6 tw-text-ap-white tw-bg-sg-cny24-red"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                @click="openFamilyTreeModal = true"
              >
                {{ $t('cny_greet_them_right_view_family_tree') }}
              </button>

              <div class="tw-h-[52px] tw-py-4 tw-px-6 sgTablet2:tw-px-4 sgDesktop:tw-px-6">
                <a
                  :href="$cdn('/images/cny2024/greet-them-right/family-tree-download.jpg')"
                  target="_blank"
                  rel="noopener"
                  class="tw-text-[#0366D8] tw-block tw-w-fit tw-mx-auto"
                >
                  <div
                    class="tw-flex tw-flex-row tw-items-center tw-justify-center"
                    :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                  >
                    {{ $t('cny_greet_them_right_save_image') }}
                    <inline-svg
                      alt="Download Icon"
                      :src="$cdn(`/icons/download_icon.svg`)"
                      class="tw-fill-[#0366D8] tw-w-6 tw-h-6 tw-ml-2"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- CNY Family Tree Modal -->
      <cny-25-family-tree-modal v-model="openFamilyTreeModal" />
    </section>
    <!-- Interested in your own family tree? -->
    <section class="tw-relative tw-pt-10 tw-pb-[60px] tw-px-4 sgTablet:tw-px-[calc(160px+20px)] sgDesktop:tw-px-[calc(160px+40px)]">
      <img
        class="tw-hidden sgTablet:tw-block tw-absolute tw-left-0 tw-bottom-0"
        :src="$cdn('images/cny2024/greet-them-right/left.png')"
      >

      <img
        class="tw-hidden sgTablet:tw-block tw-absolute tw-right-0 tw-bottom-0"
        :src="$cdn('images/cny2024/greet-them-right/right.png')"
      >
      <div class="tw-mx-auto tw-max-w-[400px] sgTablet2:tw-max-w-[744px] sgDesktop:tw-max-w-[744px] sgDesktop2:tw-max-w-[800px] tw-w-full">
        <h2
          class="tw-text-sg-cny25-h4-mobile sgTablet:tw-text-sg-cny25-h4-tablet sgDesktop:tw-text-sg-cny25-h4-desktop tw-text-[#C52600] tw-text-center"
          :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
        >
          {{ $t('cny_greet_them_right_section_3_title') }}
        </h2>
        <p
          class="tw-text-sg-cny25-body tw-mt-5 sgTablet:tw-mt-10 tw-text-[#1A1A1A] tw-tracking-[0.8px] tw-text-center"
          v-html="$t('cny25_greet_them_right_section_3_description')"
        />
        <hr class="tw-border-t-2 tw-border-t-[#C1BFB0] tw-my-10">
        <div class="tw-grid tw-grid-cols-2 tw-gap-5 sgTablet:tw-gap-8 sgDesktop:tw-gap-10">
          <div class="tw-col-span-2 sgTablet2:tw-col-span-1 tw-order-2 sgTablet2:tw-order-1">
            <p class="tw-text-sg-cny25-body tw-text-[#1A1A1A] tw-tracking-[0.8px]">
              {{ $t('cny25_greet_them_right_familysearch_desc') }}
            </p>

            <div class="tw-flex tw-justify-center tw-mt-8 sgTablet:tw-mt-10">
              <a
                target="_blank"
                rel="noopener"
                href="https://www.familysearch.org/"
                class="tw-rounded-[26px] tw-h-[52px] tw-py-4 tw-px-6 sgTablet2:tw-px-4 sgDesktop:tw-px-6 tw-flex tw-flex-row tw-items-center tw-text-ap-white tw-bg-sg-cny24-red"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                <div class="tw-flex tw-flex-row tw-items-center tw-ml-1 tw-uppercase">
                  {{ $t('cny_greet_them_right_familysearch') }}
                  <inline-svg
                    :src="$cdn(`/icons/arrow_outward.svg`)"
                    class="tw-w-6 tw-h-6 tw-ml-2"
                  />
                </div>
              </a>
            </div>
          </div>
          <div class="tw-col-span-2 sgTablet2:tw-col-span-1 tw-order-1 sgTablet2:tw-order-2">
            <img
              :src="$cdn('images/cny2024/greet-them-right/familysearch.png')"
              class="tw-max-h-full tw-h-full tw-object-cover tw-w-full"
            >
          </div>
        </div>
        <hr class="tw-border-t-2 tw-border-t-[#C1BFB0] tw-my-10">
        <div class="tw-grid tw-grid-cols-2 tw-gap-5 sgTablet:tw-gap-8 sgDesktop:tw-gap-10">
          <div class="tw-col-span-2 sgTablet2:tw-col-span-1 tw-order-2 sgTablet2:tw-order-1">
            <p class="tw-text-sg-cny25-body tw-text-[#1A1A1A] tw-tracking-[0.8px]">
              {{ $t('cny25_greet_them_right_mychinaroots_desc') }}
            </p>

            <div class="tw-flex tw-justify-center tw-mt-8 sgTablet:tw-mt-10">
              <a
                target="_blank"
                rel="noopener"
                href="https://www.mychinaroots.com/"
                class="tw-rounded-[26px] tw-h-[52px] tw-py-4 tw-px-6 sgTablet2:tw-px-4 sgDesktop:tw-px-6 tw-flex tw-flex-row tw-items-center tw-text-ap-white tw-bg-sg-cny24-red"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                <div class="tw-flex tw-flex-row tw-items-center tw-ml-1 tw-uppercase">
                  {{ $t('cny_greet_them_right_mychinaroots') }}
                  <inline-svg
                    :src="$cdn(`/icons/arrow_outward.svg`)"
                    class="tw-w-6 tw-h-6 tw-ml-2"
                  />
                </div>
              </a>
            </div>
          </div>
          <div class="tw-col-span-2 sgTablet2:tw-col-span-1 tw-order-1 sgTablet2:tw-order-2">
            <img
              :src="$cdn('images/cny2024/greet-them-right/mychinaroots.png')"
              class="tw-max-h-full tw-h-full tw-object-cover tw-w-full"
            >
          </div>
        </div>
      </div>
    </section>
    <ap-footer />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { checkLang } from '../../support/helper';
import CNY25FloatingDivider from '../../components/cny25/CNY25FloatingDivider.vue';

defineProps<{
  wordpressData: any;
}>();

const menuItems = [
  {
    label: 'cny_greet_them_right_menu_questionnare',
    id: 'questionnare-section',
  },
  {
    label: 'cny_greet_them_right_menu_family_tree',
    id: 'family-tree-section',
  },
];
const openFamilyTreeModal = ref(false);
const openQuizModal = ref(false);
</script>
