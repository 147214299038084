<template>
  <div>
    <img
      class="tw-w-full sgTablet:tw-h-[104px] sgTablet:tw-translate-y-0 tw-h-[28px]"
      :class="customClass"
      :src="$cdn(imageSrc)"
      alt="Floating Divider"
    >
    <slot />
  </div>
</template>

<script lang="ts">
export default {
  props: {
    type: {
      type: Number,
      required: false,
      default: 1,
    },
    customClass:{
      type: String,
      required: false,
      default: '',
    }
  },
  computed: {
    imageSrc(): string {
      return `images/cny2025/page_breaks/cny25-page-break-${this.type}.png`;
    },
  },
};
</script>
